import { HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK } from "./constants"
export const KITCHEN_REMODELING={
  initial_screen: "dp_screen", //mask_screen/dp_screen
  solution_name: "remodel-kitchen",
  solution_id: "KITCHEN_REMODELING",
  project_name: "New project",
  space_options: [{ label: "Kitchen", value: "kitchen" }],
  space_type: "kitchen",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: true,
  enable_mask_overlay: false,
  pre_fetch_mask: false,
  is_masking_enabled: false,
  results: [],
  side_panel_enabled: true,
  side_panel_schema: {
    design_theme: {
      type: "dropdown",
      label: "Design theme",
      name: "Design theme",
      path: "design_theme",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        {
          label: "Coastal",
          value: "Coastal",
        },
        {
          label: "Contemporary",
          value: "contemporary",
        },
        {
          label: "Farmhouse",
          value: "farmhouse",
        },
        {
          label: "French country",
          value: "french country",
        },
        {
          label: "Industrial",
          value: "industrial",
        },
        {
          label: "Mid-century modern",
          value: "mid-century modern",
        },
        {
          label: "Modern",
          value: "modern",
        },
        {
          label: "Rustic",
          value: "rustic",
        },
        {
          label: "Scandinavian",
          value: "scandinavian",
        },
        {
          label: "Traditional",
          value: "traditional",
        },
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select theme",
    },
    user_instructions: {
      type: "string",
      label: "Instructions to redesign",
      name: "Instructions to redesigns",
      path: "user_instructions",
      placeholder: "Please write your instructions in English.",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      dependent_on: "",
      required: false,
      error_msg: "Please enter instructions to redesign",
    },
  },
  upload_screen: {
    main_text: "Upload an image of kitchen",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    component: "withpanel",
    helper_text: "This uploaded image will be reimagined with new designs.",
    cta_text: "Generate design",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    mask_mode: false,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  mask_screen: {
    component: "",
  },
  results_screen: {
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Generate new design",
    cta_helper_text: "",
    mask_mode: false,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  view_results_screen: {
    generate_variations: true,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: ["ceiling"],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "fan",
      "light",
      "windowpane",
    ],
  },
  further_design_solutions: {
    "generic": {
      "generic": [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES"
      ]
    },
    "empty": {
      "generic": [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES"
      ]
    },
    "furnished": {
      "generic": [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES"
      ]
    }
  },
}