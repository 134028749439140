import { HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK } from "./constants";
export const LAWN_ENHANCEMENT = {
  initial_screen: "mask_screen", //mask_screen/dp_screen
  solution_name: "lawn-enhancement",
  solution_id: "LAWN_ENHANCEMENT",
  project_name: "New project",
  space_options: [
    { label: "Back of the house", value: "back of the house" },
    { label: "Front of the house", value: "front of the house" },
  ],
  space_type: "",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: false,
  enable_mask_overlay: true,
  pre_fetch_mask: true,
  is_masking_enabled: true,
  results: [],
  side_panel_enabled: true,
  side_panel_schema: {
    lawn_type: {
      type: "picker",
      label: "Lawn type",
      name: "theme",
      path: "theme_type",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        {
          label: "Grass Gleam",
          value: "grass_gleam",
          img: "/assets/images/panelOptions/lawn-enhacement/GrassGleam.jpeg",
        },
        {
          label: "Green Velvet",
          value: "green_velvet",
          img: "/assets/images/panelOptions/lawn-enhacement/GreenVelvet.jpeg",
        },
        {
          label: "Meadow Mist",
          value: "meadow_mist",
          img: "/assets/images/panelOptions/lawn-enhacement/MeadowMist.jpeg",
        },
        {
          label: "Rustic Rough",
          value: "rustic_rough",
          img: "/assets/images/panelOptions/lawn-enhacement/RusticRough.jpeg",
        },
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select lawn type",
    },
  },
  upload_screen: {
    main_text: "Upload an image of home exterior that has lawn",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    component: "withpanel",
    helper_text:
      "Masked area in the image will be reimagined with new designs.",
    cta_text: "Generate design",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  mask_screen: {
    component: "sam",
    helper_text:
      "Mask only the lawn area that you wish to reimagine & avoid masking other areas.",
  },
  results_screen: {
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Generate new design",
    cta_helper_text: "",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  view_results_screen: {
    generate_variations: true,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: [
      "wall",
      "floor",
      "ceiling",
      "fireplace",
      "windowpane",
      "stairway",
      "step",
      "stairs",
      "door",
      "railing;rail",
      "wardrobe",
      "refrigerator",
      "hood",
      "microwave",
      "countertop",
      "kitchen",
      "stove",
      "dishwasher",
      "sink",
      "oven",
      "curtain",
      "blind",
      "fan",
      "light",
      "chandelier",
    ],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "windowpane",
    ],
  },
  further_design_solutions: {
    generic: {
      generic: [
        "REMOVE_OBJECT",
        "LAWN_ENHANCEMENT",
        "SKY_ENHANCEMENT",
        "POOL_WATER_ENHANCEMENT",
        "CHANGE_PATHWAYS_WALKWAYS",
        "LANDSCAPING",
      ],
    },
  },
};
