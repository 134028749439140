import {
    HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
    INTERIOR_VS_THEMES,
    COLOR_OPTIONS,
  } from "./constants";
  
  export const REDESIGN_FURNISHED_ROOMS = {
    initial_screen: "dp_screen", //mask_screen/dp_screen
    solution_name: "redesign-furnished-rooms",
    solution_id: "REDESIGN_FURNISHED_ROOMS",
    project_name: "New project",
    popTitle:"Revamping decor",
    space_options: [
      { label: "Bedroom", value: "bedroom" },
      {
        label: "Living room/family room/lounge",
        value: "living room/family room/lounge",
      },
      { label: "Kids room", value: "kids room" },
      { label: "Nursery", value: "nursery" },
      { label: "Kitchen", value: "kitchen" },
      { label: "Bathroom/ensuite", value: "bathroom/ensuite" },
      { label: "Dining", value: "dining" },
      { label: "Foyer", value: "foyer" },
      { label: "Games area/rumpus room", value: "games area/rumpus room" },
      { label: "Hobby/craft room", value: "hobby/craft room" },
      { label: "Bar", value: "bar" },
      { label: "Laundry", value: "laundry" },
      { label: "Media room", value: "media room" },
      { label: "Pantry", value: "pantry" },
      { label: "Single room studio/unit", value: "single room studio/unit" },
      { label: "Study", value: "study" },
      { label: "Sunroom", value: "sunroom" },
    ],
    upload_space_type_options: {
      is_space: true,
      options: ['Empty', 'Furnished'],
      allowed_types: [true],
      error_msg: 'Not allowed',
      is_redirect: true,
      redirect_solution: 'VS_EMPTY_ROOM',
    },
    space_type: "",
    img: "",
    masked_url: "",
    mask_arr: [],
    npy_url: "",
    get_static_mask: false,
    enable_mask_overlay: false,
    solutionIcon:'designPanelIconInteriorDesigning',
    pre_fetch_mask: true,
    is_masking_enabled: true,
    results: [],
    side_panel_enabled: true,
    side_panel_schema: {
      designing_for: {
        type: "dropdown",
        label: "Space type",
        name: "Space type",
        path: "designing_for",
        value: "",
        temp_value: "",
        info_enabled: false,
        info_txt: "",
        options: [
      { label: "Bedroom", value: "bedroom" },
      {
        label: "Living room/family room/lounge",
        value: "living room",
      }, 
      { label: "Dining", value: "dining" },
      { label: "Study", value: "study" },
      { label: "Kitchen", value: "kitchen" },
      { label: "Bathroom/ensuite", value: "bathroom" },
      { label: "Kids room", value: "children room" },
      { label: "Nursery", value: "nursery" },
      { label: "Foyer", value: "foyer" },
      { label: "Media room", value: "media room" },
      { label: "Single room studio/unit", value: "single room studio" },
      { label: "Sunroom", value: "sunroom" },
        ],
        dependent_on: "",
        required: true,
        error_msg: "Please select designing for",
      },
      design_theme: {
        type: "picker",
        label: "Design theme",
        name: "theme",
        path: "design_theme",
        value: "",
        temp_value: "",
        info_enabled: false,
        info_txt: "",
        options: INTERIOR_VS_THEMES,
        dependent_on: "",
        required: true,
        error_msg: "Please select theme",
      },
      multiple_color_and_pattern_preference: {
        type: "multiple_picker_with_string",
        min: 2,
        max: 4,
        label: "",
        name: "Color preferences",
        path: "color_and_pattern_preference",
        value: "",
        temp_value: "",
        info_enabled: true,
        info_txt:
          "Click on the button given below or type your preferred color combination in the text box.",
        options: COLOR_OPTIONS,
        dependent_on: "",
        required: true,
        error_msg: "Please select color preference",
      },
      // user_instructions: {
      //   type: "string",
      //   label: "Any other specifics to include?",
      //   name: "Instructions to redesigns",
      //   path: "user_instructions",
      //   placeholder: "Add Sofa with Brown Leather, Console with TV, Lamp and Ottoman. Use Brown, White and Biege Colors.",
      //   value: "",
      //   temp_value: "",
      //   info_enabled: false,
      //   info_txt: "",
      //   dependent_on: "",
      //   required: false,
      //   error_msg: "Please enter instructions to redesign",
      // },
    },
    upload_screen: {
      main_text: "Upload an image of furnished room",
      helper_text:
        "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
      cta_text: "Upload image",
      edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
    },
    dp_screen: {
      solution_panel_name: "Redesign Furnished Rooms",
      component: "withpanel",
      helper_text:
        "Masked area in the image will be reimagined with new designs.",
      cta_text: "Generate design",
      edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
      mask_mode: false,
      heading_text: "Transform outdated furnishings into eye-catching interiors"
    },
    mask_screen: {
      component: "manual_mask",
    },
    results_screen: {
      solution_panel_name: "Redesign Furnished Rooms",
      component: "withpanel",
      helper_text:
        "Click on the generated images to view, share, download or modify.",
      cta_text: "Generate new design",
      cta_helper_text: "",
      mask_mode: false,
      heading_text: "Transform outdated furnishings into eye-catching interiors"
    },
    view_results_screen: {
      generate_variations: true,
      redesign_further: true,
    },
    generations_data: [],
    generation_id: "",
    project_id: "",
    media_id: "",
    auto_mask_exclusion_filter: {
      interior: [
        "wall",
        "floor",
        "ceiling",
        "fireplace",
        "windowpane",
        "stairway",
        "step",
        "stairs",
        "door",
        "railing;rail",
        "closet",
        "refrigerator",
        "hood",
        "microwave",
        "countertop",
        "kitchen",
        "stove",
        "dishwasher",
        "sink",
        "oven",
        "curtain",
        "blind",
        "fan",
        "light",
        "chandelier",
      ],
      exterior: [
        "building",
        "canopy",
        "column",
        "door",
        "escalator",
        "fence",
        "house",
        "hovel",
        "mountain",
        "palm",
        "pole",
        "railing",
        "road",
        "sconce",
        "sidewalk",
        "sky",
        "stairs",
        "stairway",
        "step",
        "streetlight",
        "swimming",
        "tree",
        "wall",
        "water",
        "windowpane",
      ],
    },
    further_design_solutions: {
      generic: {
      generic: [
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "CHANGE_MATERIALS_ELEMENT",
        "AI_INTERIOR_DESIGN",
      ],
      "bathroom/ensuite": [
        "SURPRISE_ME",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "CHANGE_MATERIALS_ELEMENT",
        "AI_INTERIOR_DESIGN",
      ],
      kitchen: [
        "SURPRISE_ME",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
        "CHANGE_MATERIALS_ELEMENT",
        "AI_INTERIOR_DESIGN",
      ],
      },
      empty: {
        generic: [
          "SURFACE_CHANGE_WALL",
          "SURFACE_CHANGE_CEILING",
          "SURFACE_CHANGE_FLOOR",
          "REMOVE_OBJECT",
          "CHANGE_MATERIALS_ELEMENT",
          "AI_INTERIOR_DESIGN",
        ],
        "bathroom/ensuite": [
          "SURPRISE_ME",
          "SURFACE_CHANGE_WALL",
          "SURFACE_CHANGE_CEILING",
          "SURFACE_CHANGE_FLOOR",
          "OCCUPIED_TO_VACANT",
          "REMOVE_OBJECT",
          "BATHROOM_REMODELING",
          "SURFACE_CHANGE_BATHROOM_CABINETS",
          "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
          "SURFACE_CHANGE_BATHROOM_WALL_TILE",
          "CHANGE_MATERIALS_ELEMENT",
          "AI_INTERIOR_DESIGN",
        ],
        kitchen: [
          "SURPRISE_ME",
          "SURFACE_CHANGE_WALL",
          "SURFACE_CHANGE_CEILING",
          "SURFACE_CHANGE_FLOOR",
          "OCCUPIED_TO_VACANT",
          "REMOVE_OBJECT",
          "KITCHEN_REMODELING",
          "SURFACE_CHANGE_KITCHEN_CABINETS",
          "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
          "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
          "SURFACE_CHANGE_KITCHEN_APPLIANCES",
          "CHANGE_MATERIALS_ELEMENT",
          "AI_INTERIOR_DESIGN",
        ],
      },
      furnished: {
        generic: [
          "SURFACE_CHANGE_WALL",
          "SURFACE_CHANGE_CEILING",
          "SURFACE_CHANGE_FLOOR",
          "REMOVE_OBJECT",
          "CHANGE_MATERIALS_ELEMENT",
          "AI_INTERIOR_DESIGN",
        ],
        "bathroom/ensuite": [
          "SURPRISE_ME",
          "SURFACE_CHANGE_WALL",
          "SURFACE_CHANGE_CEILING",
          "SURFACE_CHANGE_FLOOR",
          "OCCUPIED_TO_VACANT",
          "REMOVE_OBJECT",
          "BATHROOM_REMODELING",
          "SURFACE_CHANGE_BATHROOM_CABINETS",
          "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
          "SURFACE_CHANGE_BATHROOM_WALL_TILE",
          "CHANGE_MATERIALS_ELEMENT",
          "AI_INTERIOR_DESIGN",
        ],
        kitchen: [
          "SURPRISE_ME",
          "SURFACE_CHANGE_WALL",
          "SURFACE_CHANGE_CEILING",
          "SURFACE_CHANGE_FLOOR",
          "OCCUPIED_TO_VACANT",
          "REMOVE_OBJECT",
          "KITCHEN_REMODELING",
          "SURFACE_CHANGE_KITCHEN_CABINETS",
          "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
          "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
          "SURFACE_CHANGE_KITCHEN_APPLIANCES",
          "CHANGE_MATERIALS_ELEMENT",
          "AI_INTERIOR_DESIGN",
        ],
      },
    },
  };
  