export const HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK =
  "https://www.youtube.com/embed/CbA5X5CyQr0?autoplay=0&mute=0";

export const INTERIOR_VS_THEMES = [
    {
      label: "Let our AI choose",
      value: "standard",
      img: "/assets/images/panelOptions/interior-virtualstaging/Standard.svg",
    },
    {
      label: "Bohemian",
      value: "bohemian",
      img: "/assets/images/panelOptions/interior-virtualstaging/Bohemian.png",
    },
    {
      label: "Coastal",
      value: "coastal",
      img: "/assets/images/panelOptions/interior-virtualstaging/Coastal.png",
    },
    {
      label: "Contemporary",
      value: "contemporary",
      img: "/assets/images/panelOptions/interior-virtualstaging/Contemporary.png",
    },
    {
      label: "Farmhouse",
      value: "farmhouse",
      img: "/assets/images/panelOptions/interior-virtualstaging/Farmhouse.png",
    },
    {
      label: "French country",
      value: "french country",
      img: "/assets/images/panelOptions/interior-virtualstaging/FrenchCountry.png",
    },
    {
      label: "Glam",
      value: "glam",
      img: "/assets/images/panelOptions/interior-virtualstaging/Glam.png",
    },
    {
      label: "Hampton",
      value: "hampton",
      img: "/assets/images/panelOptions/interior-virtualstaging/Hampton.png",
    },
    {
      label: "Industrial",
      value: "industrial",
      img: "/assets/images/panelOptions/interior-virtualstaging/Industrial.png",
    },
    {
      label: "Japandi",
      value: "japandi",
      img: "/assets/images/panelOptions/interior-virtualstaging/Japandi.png",
    },
    {
      label: "Mid-century modern",
      value: "mid-century modern",
      img: "/assets/images/panelOptions/interior-virtualstaging/Mid-CenturyModern.png",
    },
    {
      label: "Minimal",
      value: "minimal",
      img: "/assets/images/panelOptions/interior-virtualstaging/Minimal.png",
    },
    {
      label: "Modern",
      value: "modern",
      img: "/assets/images/panelOptions/interior-virtualstaging/Modern.png",
    },
    {
      label: "Rustic",
      value: "rustic",
      img: "/assets/images/panelOptions/interior-virtualstaging/Rustic.png",
    },
    {
      label: "Scandinavian",
      value: "scandinavian",
      img: "/assets/images/panelOptions/interior-virtualstaging/Scandinavian.png",
    },
    {
      label: "Traditional",
      value: "traditional",
      img: "/assets/images/panelOptions/interior-virtualstaging/Traditional.png",
    },
    {
      label: "Transitional",
      value: "transitional",
      img: "/assets/images/panelOptions/interior-virtualstaging/Transitional.png",
    }
  ];

export const COLOR_OPTIONS = [
    {
      value: "White",
      label: "Pristine Snow",
      colorArr: ["#FDF9F9", "#FDFBFB", "#FDFBFB", "#FBFDFB", "#FDFDFF"],
    },
    {
      value: "Beige",
      label: "Nude Neutral",
      colorArr: ["#E2CEB3", "#E8D8C2", "#EEE2D1", "#F3EBE1", "#F9F5F0"],
    },
    {
      value: "Grey",
      label: "Misty Loft",
      colorArr: ["#B4BDC7", "#C3CAD2", "#D2D7DD", "#E1E5E9", "#F0F2F4"],
    },
    {
      value: "Pale Purple",
      label: "Wisteria Whiff",
      colorArr: ["#9F7BA9", "#B295BA", "#C5B0CB", "#D9CADD", "#ECE5EE"],
    },
    {
      value: "Cyan",
      label: "Azure Oasis",
      colorArr: ["#6ECCC3", "#8BD6CF", "#A8E0DB", "#C5EBE7", "#E2F5F3"],
    },
    {
      value: "Blue",
      label: "Sky blue",
      colorArr: ["#0EAADD", "#3EBBE4", "#6ECCEB", "#9FDDF1", "#CFEEF8"],
    },
    {
      value: "Dull Blue",
      label: "Twilight Drift",
      colorArr: ["#1666BA", "#368CE7", "#7AB3EF", "#BEDAF7", "#DEECFB"],
    },
    {
      value: "Royal Blue",
      label: "Starry Night",
      colorArr: ["#05588C", "#3779A3", "#699BBA", "#9BBCD1", "#CDDEE8"],
    },
    {
      value: "Emerald",
      label: "Enchanted Evergreen",
      colorArr: ["#00751F", "#33914C", "#66AC79", "#99C8A5", "#CCE3D2"],
    },
    {
      value: "Sage Green",
      label: "Meadow Mist",
      colorArr: ["#8CA468", "#A3B686", "#BAC8A4", "#D1DBC3", "#E8EDE1"],
    },
    {
      value: "Lime Green",
      label: "Lively Lime",
      colorArr: ["#9DD227", "#B1EE46", "#CBFF58", "#DBFF74", "#E5FF9A"],
    },
    {
      value: "Bright Yellow",
      label: "Daisy Delight",
      colorArr: ["#E2DD24", "#E8E450", "#EEEB7C", "#F3F1A7", "#F9F8D3"],
    },
    {
      value: "Dull Red",
      label: "Vintage Crimson",
      colorArr: ["#CC1448", "#D6436D", "#E07291", "#EBA1B6", "#F5D0DA"],
    },
    {
      value: "Orange",
      label: "Sunset Burst",
      colorArr: ["#FF9429", "#FFA954", "#FFBF7F", "#FFD4A9", "#FFEAD4"],
    },
    {
      value: "Pink",
      label: "Rose Whisper",
      colorArr: ["#FFA4C2", "#FFBDD3", "#FFD7E4", "#FFF0F5", "#FFFFFF"],
    },
    {
      value: "Mustard Yellow",
      label: "Harvest Glow",
      colorArr: ["#FEC000", "#FECD33", "#FED966", "#FFE699", "#FFF2CC"],
    },
    {
      value: "Brown",
      label: "Earthy Ember",
      colorArr: ["#83502E", "#96613D", "#BD7E4A", "#CE8B54", "#D2A56D"],
    },
    {
      value: "Dark Grey",
      label: "Charcoal Whispers",
      colorArr: ["#484A46", "#6D6E6B", "#919290", "#B6B7B5", "#DADBDA"],
    },
    {
      value: "Golden",
      label: "Golden",
      colorArr: ["#A67C00", "#BF9B30", "#FFBF00", "#FFCF40", "#FFDC73"],
    },
    {
      value: "Silver",
      label: "Silver",
      colorArr: ["#B8BCC8", "#CDCBD1", "#D0D1D5", "#DFDFE6", "#E1E6E8"],
    },
  ];

export const SOLUTIONS_UPDATED = [
  {
    solutionName: "Virtual Staging",
    solutionIcon: "sidebarAiVirtualStaging",
    solutionId:'VIRTUAL_STAGING',
    solutionURLName:'virtual-staging',
    caption:"Furnish empty rooms with realistic furniture",
  },
  {
    solutionName: "Redesign Furnished Rooms",
    solutionIcon: "sidebarAiInteriorDesign",
    solutionId:'REDESIGN_FURNISHED_ROOMS',
    solutionURLName:'redesign-furnished-rooms',
    caption:"Transform outdated furnishings into eye-catching interiors",
  },
  {
    solutionName: "Empty Your Space",
    solutionIcon: "sidebarAiSpaceDecluttering",
    solutionId:'EMPTY_YOUR_SPACE',
    solutionURLName:'empty-your-space',
    caption:"Remove all furniture and clutter in your room photos",
  },
  {
    solutionName: "Landscaping",
    solutionIcon: "sidebarAiLandscape",
    solutionId:'AI_LANDSCAPING',
    solutionURLName:'ai-landscaping',
    caption:"Photo-realistic landscape makeovers for your exterior photos",
  },
  {
    solutionName: "Render Exterior Structures",
    solutionIcon: "sidebarAiUnderConstruction",
    solutionId:'RENDER_EXTERIOR_STRUCTURES',
    solutionURLName:'render-exterior-structures',
    caption:"Create designs for 3D sketches, construction phases & existing homes",
  },
];

export const EXTERIOR_THEMES = [
  { label: 'Beautiful garden', value: 'beautiful garden' },
  { label: 'Charming playhouse garden landscaping', value: 'charming playhouse garden landscaping' },
  { label: "Cottage garden's colorful planting palette", value: "cottage garden's colorful planting palette" },
  { label: 'Cozy corner with fire pit and seating', value: 'cozy corner with fire pit and seating' },
  { label: 'Garden landscaping with gravel landscaping', value: 'garden landscaping with gravel landscaping' },
  { label: 'Hip california garden landscaping', value: 'hip california garden landscaping' },
  { label: 'Lush green lawn', value: 'lush green lawn' },
  { label: 'Mediterranean garden landscaping', value: 'mediterranean garden landscaping' },
  { label: 'Moss garden', value: 'moss garden' },
  { label: 'Outdoor dining and sitting area', value: 'outdoor dining and sitting area' },
  { label: 'Party-ready outdoor space with pool, spa, and fire feature', value: 'party-ready outdoor space with pool, spa, and fire feature' },
  { label: 'Resort-style landscaping and pool', value: 'resort-style landscaping and pool' },
  { label: 'Round swimming pool with lawn and pool house', value: 'round swimming pool with lawn and pool house' }
]
