import { HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK } from "./constants"
export const CHANGE_MATERIALS_ELEMENT={
  initial_screen: "mask_screen", //mask_screen/dp_screen
  solution_name: "change-material-color-of-furnishing-elements",
  solution_id: "CHANGE_MATERIALS_ELEMENT",
  project_name: "New project",
  space_options: [
    { label: "Bar", value: "bar" },
    { label: "Bedroom", value: "bedroom" },
    { label: "Dining", value: "dining" },
    { label: "Foyer", value: "foyer" },
    { label: "Games area/rumpus room", value: "games area/rumpus room" },
    { label: "Hobby/craft room", value: "hobby/craft room" },
    { label: "Kids room", value: "kids room" },
    { label: "Laundry", value: "laundry" },
    {
      label: "Living room/family room/lounge",
      value: "living room/family room/lounge",
    },
    { label: "Media room", value: "media room" },
    { label: "Nursery", value: "nursery" },
    { label: "Pantry", value: "pantry" },
    { label: "Single room studio/unit", value: "single room studio/unit" },
    { label: "Study", value: "study" },
    { label: "Sunroom", value: "sunroom" },
  ],
  upload_space_type_options: {
    is_space: true,
    options: ['Empty', 'Furnished'],
    allowed_types: [true],
    error_msg: 'Not allowed',
    is_redirect: true,
    redirect_solution: 'VS_EMPTY_ROOM',
  },
  space_type: "",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: false,
  enable_mask_overlay: true,
  pre_fetch_mask: true,
  is_masking_enabled: true,
  results: [],
  side_panel_enabled: true,
  side_panel_schema: {
    text_input_from_user: {
      type: "string",
      label: "Instructions to redesign element(s)",
      name: "Instructions to redesign*s",
      path: "text_input_from_user",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      dependent_on: "",
      required: true,
      error_msg: "Please enter instructions to redesign",
    },
  },
  upload_screen: {
    main_text: "Upload an image of furnished room",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    component: "withpanel",
    helper_text:
      "Masked area in the image will be reimagined with new designs.",
    cta_text: "Generate design",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  mask_screen: {
    component: "sam",
    helper_text:
      "Mask only the furnishing element(s) that you wish to reimagine with new color & material.",
  },
  results_screen: {
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Generate new design",
    cta_helper_text: "",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  view_results_screen: {
    generate_variations: true,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: [
      "wall",
      "floor",
      "ceiling",
      "fireplace",
      "windowpane",
      "stairway",
      "step",
      "stairs",
      "door",
      "railing;rail",
      "wardrobe",
      "refrigerator",
      "hood",
      "microwave",
      "countertop",
      "kitchen",
      "stove",
      "dishwasher",
      "sink",
      "oven",
      "curtain",
      "blind",
      "fan",
      "light",
      "chandelier",
    ],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "windowpane",
    ],
  },
    further_design_solutions : {
    "generic": {
      "generic": [
        "AI_INTERIOR_DESIGN",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "CHANGE_MATERIALS_ELEMENT"
      ],
      "bathroom/ensuite": [
        "AI_INTERIOR_DESIGN",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "CHANGE_MATERIALS_ELEMENT"
      ],
      "kitchen": [
        "AI_INTERIOR_DESIGN",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
        "CHANGE_MATERIALS_ELEMENT"
      ]
    },
    "empty": {
      "generic": [
        "AI_INTERIOR_DESIGN",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "CHANGE_MATERIALS_ELEMENT"
      ],
      "bathroom/ensuite": [
        "AI_INTERIOR_DESIGN",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "CHANGE_MATERIALS_ELEMENT"
      ],
      "kitchen": [
        "AI_INTERIOR_DESIGN",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
        "CHANGE_MATERIALS_ELEMENT"
      ]
    },
    "furnished": {
      "generic": [
        "AI_INTERIOR_DESIGN",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "CHANGE_MATERIALS_ELEMENT"
      ],
      "bathroom/ensuite": [
        "AI_INTERIOR_DESIGN",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "CHANGE_MATERIALS_ELEMENT"
      ],
      "kitchen": [
        "AI_INTERIOR_DESIGN",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
        "CHANGE_MATERIALS_ELEMENT"
      ]
    },
  },    
  
}