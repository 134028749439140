import { HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK } from "./constants";
export const SURFACE_CHANGE_BATHROOM_WALL_TILE = {
  initial_screen: "mask_screen", //mask_screen/dp_screen
  solution_name: "upgrade-bathroom-wall-tiles",
  solution_id: "SURFACE_CHANGE_BATHROOM_WALL_TILE",
  project_name: "New project",
  space_options: [{ label: "Bathroom/ensuite", value: "bathroom/ensuite" }],
  space_type: "bathroom/ensuite",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: false,
  enable_mask_overlay: true,
  pre_fetch_mask: true,
  is_masking_enabled: true,
  results: [],
  side_panel_enabled: true,
  side_panel_schema: {
    wall_tile_type: {
      type: "dropdown",
      label: "Select wall tile type",
      name: "wall tile type",
      path: "wall_tile_type",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        { label: "Marble", value: "Marble" },
        { label: "Mosaic Tile", value: "Mosaic Tile" },
        { label: "Brick Tile", value: "Brick Tile" },
        { label: "Ceramic Tile", value: "Ceramic Tile" },
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select wall tile type",
    },
    wall_tile_sub_type: {
      type: "picker",
      labels: {
        wall_tile_type: {
          Marble: "Select marble type",
          "Mosaic Tile": "Select mosaic tile type",
          "Brick Tile": "Select brick tile type",
          "Ceramic Tile": "Select ceramic tile type",
        },
      },
      name: "theme",
      path: "wall_tile_sub_type",
      value: "",
      temp_value: "",
      info_enabled: true,
      info_txt:
        "Click on the button given below or type your preferred color combination in the text box.",
      options: {
        wall_tile_type: {
          Marble: [
            {
              label: "White Marble",
              value: "White Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/marble/White Marble.jpeg",
            },
            {
              label: "Black Marble",
              value: "Black Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/marble/Black Marble.jpeg",
            },
            {
              label: "Brown Marble",
              value: "Brown Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/marble/Brown Marble.jpeg",
            },
            {
              label: "Cream Marble",
              value: "Cream Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/marble/Creame Marble-2.jpeg",
            },
            {
              label: "Blue Marble",
              value: "Blue Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/marble/Blue marble.jpeg",
            },
            {
              label: "Green Marble",
              value: "Green Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/marble/Green Marble.jpeg",
            },
            {
              label: "Grey Marble",
              value: "Grey Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/marble/Grey Marble.jpeg",
            },
            {
              label: "Pink Marble",
              value: "Pink Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/marble/Pink Marble.jpeg",
            },
            {
              label: "Yellow marble",
              value: "Yellow Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/marble/Yellow Marble.jpeg",
            },
          ],
          "Mosaic Tile": [
            {
              label: "Beige Mosaic tile",
              value: "Beige Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Mosaic/Beige Mosaic tile.jpeg",
            },
            {
              label: "Black Mosaic tile",
              value: "Black Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Mosaic/Black Mosaic tile.jpeg",
            },
            {
              label: "Black-White Mosaic tile",
              value: "Black-White Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Mosaic/Black-White Mosaic tile.jpeg",
            },
            {
              label: "Blue Mosaic tile",
              value: "Blue Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Mosaic/Blue Mosaic tile.jpeg",
            },
            {
              label: "Brown Mosaic tile",
              value: "Brown Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Mosaic/Brown Mosaic tile.jpeg",
            },
            {
              label: "Green Mosaic tile",
              value: "Green Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Mosaic/Green Mosaic tile.jpeg",
            },
            {
              label: "Grey Mosaic tile",
              value: "Grey Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Mosaic/Grey Mosaic tile.jpeg",
            },
            {
              label: "Pink Mosaic tile",
              value: "Pink Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Mosaic/Pink Mosaic tile.jpeg",
            },
            {
              label: "Yellow Mosaic tile",
              value: "Yellow Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Mosaic/Yellow Mosaic tile.jpeg",
            },
          ],
          "Brick Tile": [
            {
              label: "Grey Brick tile",
              value: "Grey Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Brick-Tile/Grey Brick tile.jpeg",
            },
            {
              label: "White Brick tile",
              value: "White Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Brick-Tile/White Brick tile.jpeg",
            },
            {
              label: "Black brick tile",
              value: "Black Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Brick-Tile/Black Brick tile.jpeg",
            },
            {
              label: "Blue Brick tile",
              value: "Blue Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Brick-Tile/Blue Brick tile.jpeg",
            },
            {
              label: "Beige Brick tile",
              value: "Beige Color",
              img: "/assets/images/panelOptions/upgrade-kitchen-backsplash/Brick-Tile/Beige Brick tile.jpeg",
            },
          ],
          "Ceramic Tile": [
            {
              label: "Snow White",
              value: "White Color",
              img: "/assets/images/panelOptions/change-floor/ceramic-tile/SnowWhite.jpeg",
            },
            {
              label: "Midnight Black",
              value: "Black Color",
              img: "/assets/images/panelOptions/change-floor/ceramic-tile/MidnightBlack.jpeg",
            },
            {
              label: "Ocean Blue",
              value: "Blue Color",
              img: "/assets/images/panelOptions/change-floor/ceramic-tile/OceanBlue.jpeg",
            },
            {
              label: "Meadow Green",
              value: "Green Color",
              img: "/assets/images/panelOptions/change-floor/ceramic-tile/MeadowGreen.jpeg",
            },
            {
              label: "Sand Beige",
              value: "Beige Color",
              img: "/assets/images/panelOptions/change-floor/ceramic-tile/SandBeige.jpeg",
            },
            {
              label: "Ruby Radiance",
              value: "Red Color",
              img: "/assets/images/panelOptions/change-floor/ceramic-tile/RubyRadiance.jpeg",
            },
          ],
        },
      },
      dependent_on: ["wall_tile_type"],
      required: true,
      error_msg: {
        wall_tile_type: {
          Marble: "Please select marble type",
          "Mosaic Tile": "Please select mosaic tile type",
          "Brick Tile": "Please select brick tile type",
          "Ceramic Tile": "Please select ceramic tile type",
        },
      },
    },
    user_instructions: {
      type: "string",
      label: "Instructions to redesign",
      name: "Instructions to redesigns",
      path: "user_instructions",
      placeholder: "Please write your instructions in English.",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      dependent_on: "",
      required: false,
      error_msg: "Please enter instructions to redesign",
    },
  },
  upload_screen: {
    main_text: "Upload an image of bathroom",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    component: "withpanel",
    helper_text:
      "Masked area in the image will be reimagined with new designs.",
    cta_text: "Generate design",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  mask_screen: {
    component: "sam",
    helper_text:
      "Mask only the wall tile area that you wish to reimagine and avoid masking other surfaces & furniture.",
  },
  results_screen: {
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Generate new design",
    cta_helper_text: "",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  view_results_screen: {
    generate_variations: true,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: [
      "wall",
      "floor",
      "ceiling",
      "fireplace",
      "windowpane",
      "stairway",
      "step",
      "stairs",
      "door",
      "railing;rail",
      "wardrobe",
      "refrigerator",
      "hood",
      "microwave",
      "countertop",
      "kitchen",
      "stove",
      "dishwasher",
      "sink",
      "oven",
      "curtain",
      "blind",
      "fan",
      "light",
      "chandelier",
    ],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "windowpane",
    ],
  },
  further_design_solutions: {
    generic: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "REMOVE_OBJECT",
      ],
    },
    empty: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "REMOVE_OBJECT",
      ],
    },
    furnished: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "REMOVE_OBJECT",
      ],
    },
  },
};
