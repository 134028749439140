import { HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK } from "./constants"
export const CHANGE_PATHWAYS_WALKWAYS={
  initial_screen: "mask_screen", //mask_screen/dp_screen
  solution_name: "change-pathways",
  solution_id: "CHANGE_PATHWAYS_WALKWAYS",
  project_name: "New project",
  space_options: [
    { label: "Back of the house", value: "back of the house" },
    { label: "Front of the house", value: "front of the house" },
  ],
  space_type: "",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: false,
  enable_mask_overlay: true,
  pre_fetch_mask: true,
  is_masking_enabled: true,
  results: [],
  side_panel_enabled: true,
  side_panel_schema: {
    pathway_type: {
      type: "picker",
      label: "Select pathway type",
      name: "theme",
      path: "theme_type",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        {
          label: "Beige Walk",
          value: "beige_walk",
          img: "/assets/images/panelOptions/change-pathway/BeigeWalk.jpeg",
        },
        {
          label: "Block Path",
          value: "block_path",
          img: "/assets/images/panelOptions/change-pathway/BlockPath.jpeg",
        },
        {
          label: "Blockway",
          value: "blockway",
          img: "/assets/images/panelOptions/change-pathway/Blockway.jpeg",
        },
        {
          label: "Brickway",
          value: "brickway",
          img: "/assets/images/panelOptions/change-pathway/Brickway.jpeg",
        },
        {
          label: "Concrete Path",
          value: "concrete_path",
          img: "/assets/images/panelOptions/change-pathway/ConcretePath.jpeg",
        },
        {
          label: "Midnight Alley",
          value: "midnight_alley",
          img: "/assets/images/panelOptions/change-pathway/MidnightAlley.jpeg",
        },
        {
          label: "Mono Lane",
          value: "mono_lane",
          img: "/assets/images/panelOptions/change-pathway/MonoLane.jpeg",
        },
        {
          label: "Resin Route",
          value: "resin_route",
          img: "/assets/images/panelOptions/change-pathway/ResinRoute.jpeg",
        },
        {
          label: "Sandy Trail",
          value: "sandy_trail",
          img: "/assets/images/panelOptions/change-pathway/SandyTrail.jpeg",
        },
        {
          label: "Stone Trail",
          value: "stone_trail",
          img: "/assets/images/panelOptions/change-pathway/StoneTrail.jpeg",
        },
        {
          label: "Stone Walk",
          value: "stone_walk",
          img: "/assets/images/panelOptions/change-pathway/StoneWalk.jpeg",
        },
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select pathway type",
    },
  },
  upload_screen: {
    main_text: "Upload an image of home exterior that has pathway",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    component: "withpanel",
    helper_text:
      "Masked area in the image will be reimagined with new designs.",
    cta_text: "Generate design",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  mask_screen: {
    component: "sam",
    helper_text:
      "Mask only the pathways/walkways that you wish to reimagine & avoid masking other elements.",
  },
  results_screen: {
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Generate new design",
    cta_helper_text: "",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  view_results_screen: {
    generate_variations: true,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: [
      "wall",
      "floor",
      "ceiling",
      "fireplace",
      "windowpane",
      "stairway",
      "step",
      "stairs",
      "door",
      "railing;rail",
      "wardrobe",
      "refrigerator",
      "hood",
      "microwave",
      "countertop",
      "kitchen",
      "stove",
      "dishwasher",
      "sink",
      "oven",
      "curtain",
      "blind",
      "fan",
      "light",
      "chandelier",
    ],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "windowpane",
    ],
  },
  further_design_solutions: {
    "generic": {
      "generic": [
        "REMOVE_OBJECT",
        "LAWN_ENHANCEMENT",
        "SKY_ENHANCEMENT",
        "POOL_WATER_ENHANCEMENT",
        "CHANGE_PATHWAYS_WALKWAYS",
        "LANDSCAPING"
      ]
    }
  }
  ,
}