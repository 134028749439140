import { HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK } from "./constants";
export const UNDER_CONSTRUCTION_EXTERIOR = {
  initial_screen: "dp_screen", //mask_screen/dp_screen
  solution_name: "redesign-under-construction-home-exterior",
  solution_id: "UNDER_CONSTRUCTION_EXTERIOR",
  project_name: "New project",
  popTitle:"Rendering",
  space_options: [
    { label: "Back of the house", value: "back of the house" },
    { label: "Front of the house", value: "front of the house" },
  ],
  space_type: "",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: true,
  enable_mask_overlay: false,
  pre_fetch_mask: false,
  is_masking_enabled: false,
  results: [],
  side_panel_enabled: true,
  solutionIcon:'designPanelIconExteriorUnderConstruction',
  side_panel_schema: {
    designing_for: {
      type: "dropdown",
      label: "Space type",
      name: "Space type",
      path: "designing_for",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        { label: "Back of the house", value: "back of the house" },
        { label: "Front of the house", value: "front of the house" },
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select designing for",
    },
    design_theme: {
      type: "picker",
      label: "Design theme",
      name: "theme",
      path: "design_theme",
      value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        {
          label: "Colonial",
          value: "colonial",
          img: "/assets/images/panelOptions/under-construction-exterior/Colonial.png",
        },
        {
          label: "Contemporary",
          value: "contemporary",
          img: "/assets/images/panelOptions/under-construction-exterior/Contemporary.png",
        },
        {
          label: "Cottage",
          value: "cottage",
          img: "/assets/images/panelOptions/under-construction-exterior/Cottage.png",
        },
        {
          label: "Farmhouse",
          value: "farmhouse",
          img: "/assets/images/panelOptions/under-construction-exterior/Farmhouse.png",
        },
        {
          label: "French Provincial",
          value: "french provincial",
          img: "/assets/images/panelOptions/under-construction-exterior/FrenchProvincial.png",
        },
        {
          label: "Greek Revival",
          value: "greek revival",
          img: "/assets/images/panelOptions/under-construction-exterior/GreekRevival.png",
        },
        {
          label: "Italianate",
          value: "italianate",
          img: "/assets/images/panelOptions/under-construction-exterior/Italianate.png",
        },
        {
          label: "Mediterranean",
          value: "mediterranean",
          img: "/assets/images/panelOptions/under-construction-exterior/Mediterranean.png",
        },
        {
          label: "Midcentury Modern",
          value: "midcentury modern",
          img: "/assets/images/panelOptions/under-construction-exterior/MidcenturyModern.png",
        },
        {
          label: "Modern",
          value: "modern",
          img: "/assets/images/panelOptions/under-construction-exterior/Modern.png",
        },
        {
          label: "Neoclassical",
          value: "neoclassical",
          img: "/assets/images/panelOptions/under-construction-exterior/Neoclassical.png",
        },
        {
          label: "Asian",
          value: "asian",
          img: "/assets/images/panelOptions/under-construction-exterior/Asian.png",
        },
        {
          label: "Ranch",
          value: "ranch",
          img: "/assets/images/panelOptions/under-construction-exterior/Ranch.png",
        },
        {
          label: "Spanish",
          value: "spanish",
          img: "/assets/images/panelOptions/under-construction-exterior/Spanish.png",
        },
        {
          label: "Victorian",
          value: "victorian",
          img: "/assets/images/panelOptions/under-construction-exterior/Victorian.png",
        },
        {
          label: "Beachhouse",
          value: "beachhouse",
          img: "/assets/images/panelOptions/under-construction-exterior/Beachhouse.png",
        },
        {
          label: "Rustic",
          value: "rustic",
          img: "/assets/images/panelOptions/under-construction-exterior/Rustic.png",
        },
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select theme",
    },
  },
  upload_screen: {
    main_text: "Upload an image of under construction home exterior",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    solution_panel_name: "Under Construction",
    component: "withpanel",
    helper_text: "This uploaded image will be reimagined with new designs.",
    cta_text: "Render",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    heading_text: "Visualize your property's exterior before it's built",
    mask_mode: false,
  },
  mask_screen: {
    component: "",
  },
  results_screen: {
    solution_panel_name: "Under Construction",
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Render again",
    heading_text: "Visualize your property's exterior before it's built",
    cta_helper_text: "",
  },
  view_results_screen: {
    generate_variations: true,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: [
      "wall",
      "floor",
      "ceiling",
      "fireplace",
      "windowpane",
      "stairway",
      "step",
      "stairs",
      "door",
      "railing;rail",
      "wardrobe",
      "refrigerator",
      "hood",
      "microwave",
      "countertop",
      "kitchen",
      "stove",
      "dishwasher",
      "sink",
      "oven",
      "curtain",
      "blind",
      "fan",
      "light",
      "chandelier",
    ],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "windowpane",
    ],
  },
  further_design_solutions: {
    generic: {
      generic: [
        "REMOVE_OBJECT",
        "LAWN_ENHANCEMENT",
        "SKY_ENHANCEMENT",
        "POOL_WATER_ENHANCEMENT",
        "CHANGE_PATHWAYS_WALKWAYS",
        "UNDER_CONSTRUCTION_EXTERIOR",
        "LANDSCAPING",
      ],
    },
  },
};
