import { HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK, COLOR_OPTIONS } from "./constants";
export const SURFACE_CHANGE_WALL = {
  initial_screen: "mask_screen", //mask_screen/dp_screen
  solution_name: "change-wall-color",
  solution_id: "SURFACE_CHANGE_WALL",
  project_name: "New project",
  space_options: [
    { label: "Bedroom", value: "bedroom" },
    {
      label: "Living room/family room/lounge",
      value: "living room/family room/lounge",
    },
    { label: "Kids room", value: "kids room" },
    { label: "Nursery", value: "nursery" },
    { label: "Kitchen", value: "kitchen" },
    { label: "Bathroom/ensuite", value: "bathroom/ensuite" },
    { label: "Dining", value: "dining" },
    { label: "Foyer", value: "foyer" },
    { label: "Games area/rumpus room", value: "games area/rumpus room" },
    { label: "Hobby/craft room", value: "hobby/craft room" },
    { label: "Bar", value: "bar" },
    { label: "Laundry", value: "laundry" },
    { label: "Media room", value: "media room" },
    { label: "Pantry", value: "pantry" },
    { label: "Single room studio/unit", value: "single room studio/unit" },
    { label: "Study", value: "study" },
    { label: "Sunroom", value: "sunroom" },
  ],
  upload_space_type_options: {
    is_space: true,
    options: ['Empty', 'Furnished'],
    allowed_types: [true, false],
    error_msg: 'Not allowed',
    is_redirect: true,
    redirect_solution: 'VS_EMPTY_ROOM',
  },
  space_type: "",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: false,
  enable_mask_overlay: true,
  pre_fetch_mask: true,
  is_masking_enabled: true,
  results: [],
  side_panel_enabled: true,
  side_panel_schema: {
    change_type: {
      type: "dropdown",
      label: "Select change type",
      name: "change type",
      path: "change_type",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        { label: "Wall color", value: "Wall color" },
        { label: "Wall paper", value: "Wall paper" },
        { label: "Wainscoting", value: "Wainscoting" },
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select change type",
    },
    multiple_color_and_pattern_preference: {
      type: "multiple_picker_with_string",
      min: 2,
      max: 3,
      labels: {
        change_type: {
          "Wall paper": "Color preferences",
        },
      },
      name: "Color preferences",
      path: "color_and_pattern_preference",
      value: "",
      temp_value: "",
      info_enabled: true,
      info_txt:
        "Click on the button given below or type your preferred color combination in the text box.",
      options: {
        change_type: {
          "Wall paper": COLOR_OPTIONS,
        },
      },
      dependent_on: ["change_type"],
      required: true,
      error_msg: {
        change_type: {
          "Wall paper": "Please select color preference",
        },
      },
    },
    color_and_pattern_preference: {
      type: "picker_with_string",
      labels: {
        change_type: {
          "Wall color": "Color preferences",
          Wainscoting: "Color preferences",
        },
      },
      name: "Color preferences",
      path: "color_and_pattern_preference",
      value: "",
      temp_value: "",
      info_enabled: true,
      info_txt:
        "Click on the button given below or type your preferred color combination in the text box.",
      options: {
        change_type: {
          "Wall color": COLOR_OPTIONS,
          Wainscoting: COLOR_OPTIONS,
        },
      },
      dependent_on: ["change_type"],
      required: true,
      error_msg: {
        change_type: {
          "Wall color": "Please select color preference",
          Wainscoting: "Please select color preference",
        },
      },
    },
    pattern: {
      type: "dropdown",
      labels: {
        change_type: {
          "Wall paper": "Select pattern",
        },
      },
      name: "pattern",
      path: "pattern",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: {
        change_type: {
          "Wall paper": [
            { label: "Floral", value: "Floral" },
            { label: "Geometric", value: "Geometric" },
            { label: "Striped", value: "Striped" },
            { label: "Damask", value: "Damask" },
            { label: "Abstract", value: "Abstract" },
            { label: "Polka dot", value: "Polka dot" },
            { label: "Plaid", value: "Plaid" },
            { label: "Toile", value: "Toile" },
            { label: "Paisley", value: "Paisley" },
            { label: "Brick", value: "Brick" },
            { label: "Stone", value: "Stone" },
          ],
        },
      },
      dependent_on: ["change_type"],
      required: true,
      error_msg: {
        change_type: {
          "Wall paper": "Please select pattern",
        },
      },
    },
    user_instructions: {
      type: "string",
      label: "Instructions to redesign",
      name: "Instructions to redesigns",
      path: "user_instructions",
      placeholder: "Please write your instructions in English.",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      dependent_on: "",
      required: false,
      error_msg: "Please enter instructions to redesign",
    },
  },
  upload_screen: {
    main_text: "Upload an image of home interior",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    component: "withpanel",
    helper_text:
      "Masked area in the image will be reimagined with new designs.",
    cta_text: "Generate design",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  mask_screen: {
    component: "sam",
    helper_text:
      "Mask only the wall(s) that you wish to reimagine and avoid masking other surfaces & furniture.",
  },
  results_screen: {
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Generate new design",
    cta_helper_text: "",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  view_results_screen: {
    generate_variations: true,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: [
      "wall",
      "floor",
      "ceiling",
      "fireplace",
      "windowpane",
      "stairway",
      "step",
      "stairs",
      "door",
      "railing;rail",
      "wardrobe",
      "refrigerator",
      "hood",
      "microwave",
      "countertop",
      "kitchen",
      "stove",
      "dishwasher",
      "sink",
      "oven",
      "curtain",
      "blind",
      "fan",
      "light",
      "chandelier",
    ],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "windowpane",
    ],
  },
  further_design_solutions: {
    generic: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      "bathroom/ensuite": [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "OCCUPIED_TO_VACANT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      kitchen: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
        "CHANGE_MATERIALS_ELEMENT",
      ],
    },
    empty: {
      generic: [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
      ],
      "bathroom/ensuite": [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
      ],
      kitchen: [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
      ],
    },
    furnished: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      "bathroom/ensuite": [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "OCCUPIED_TO_VACANT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      kitchen: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
        "CHANGE_MATERIALS_ELEMENT",
      ],
    },
  },
};
