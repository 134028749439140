import { HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK, COLOR_OPTIONS } from "./constants";
export const SURFACE_CHANGE_KITCHEN_CABINETS = {
  initial_screen: "mask_screen", //mask_screen/dp_screen
  solution_name: "upgrade-kitchen-cabinets",
  solution_id: "SURFACE_CHANGE_KITCHEN_CABINETS",
  project_name: "New project",
  space_options: [{ label: "Kitchen", value: "kitchen" }],
  space_type: "kitchen",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: false,
  enable_mask_overlay: true,
  pre_fetch_mask: true,
  is_masking_enabled: true,
  results: [],
  side_panel_enabled: true,
  side_panel_schema: {
    change_type: {
      type: "dropdown",
      label: "Select change type",
      name: "change type",
      path: "change_type",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        { label: "Material", value: "Material" },
        { label: "Color", value: "Color" },
        { label: "Style", value: "Style" },
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select change type",
    },
    design_theme: {
      type: "dropdown",
      labels: {
        change_type: {
          Style: "Select style",
        },
      },
      name: "style type",
      path: "design_theme",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: {
        change_type: {
          Style: [
            {
              label: "Contemporary",
              value: "contemporary",
            },
            {
              label: "Farmhouse",
              value: "farmhouse",
            },
            {
              label: "Modern",
              value: "modern",
            },
            {
              label: "Traditional",
              value: "traditional",
            },
            {
              label: "Transitional",
              value: "transitional",
            },
          ],
        },
      },
      dependent_on: ["change_type"],
      required: true,
      error_msg: {
        change_type: {
          Style: "Please select style type",
        },
      },
    },
    select_customization: {
      type: "dropdown",
      labels: {
        change_type: {
          Style: "Select customization",
        },
      },
      name: "style type",
      path: "select_customization",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: {
        change_type: {
          Style: [
            { label: "Material", value: "Material" },
            { label: "Color", value: "Color" },
          ],
        },
      },
      dependent_on: ["change_type"],
      required: true,
      error_msg: {
        change_type: {
          Style: "Please select customization",
        },
      },
    },
    multiple_color_and_pattern_preference: {
      type: "multiple_picker_with_string",
      min: 1,
      max: 2,
      labels: {
        change_type: {
          Color: "Color preferences",
        },
        select_customization: {
          Color: "Color preferences",
        },
      },
      name: "Color preferences",
      path: "color_and_pattern_preference",
      value: "",
      temp_value: "",
      info_enabled: true,
      info_txt:
        "Click on the button given below or type your preferred color combination in the text box.",
      options: {
        change_type: {
          Color: COLOR_OPTIONS,
        },
        select_customization: {
          Color: COLOR_OPTIONS,
        },
      },
      dependent_on: ["change_type", "select_customization"],
      required: true,
      error_msg: {
        change_type: {
          Color: "Please select color preference",
        },
        select_customization: {
          Color: "Please select color preference",
        },
      },
    },
    material_type: {
      type: "picker",
      labels: {
        change_type: {
          Material: "Select material",
        },
        select_customization: {
          Material: "Select material",
        },
      },
      name: "material",
      path: "material_type",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: {
        change_type: {
          Material: [
            {
              label: "Oak wood",
              value: "Oak wood",
              img: "/assets/images/panelOptions/upgrade-kitchen-cabinets/OakWood.jpeg",
            },
            {
              label: "Walnut wood",
              value: "Walnut wood",
              img: "/assets/images/panelOptions/upgrade-kitchen-cabinets/WalnutWood.jpeg",
            },
            {
              label: "Birch wood",
              value: "Birch wood",
              img: "/assets/images/panelOptions/upgrade-kitchen-cabinets/BirchWood.jpeg",
            },
            {
              label: "Mahogany wood",
              value: "Mahogany wood",
              img: "/assets/images/panelOptions/upgrade-kitchen-cabinets/MahoganyWood.jpeg",
            },
            {
              label: "Rose wood",
              value: "Rose wood",
              img: "/assets/images/panelOptions/upgrade-kitchen-cabinets/RoseWood.jpeg",
            },
          ],
        },
        select_customization: {
          Material: [
            {
              label: "Oak wood",
              value: "Oak wood",
              img: "/assets/images/panelOptions/upgrade-kitchen-cabinets/OakWood.jpeg",
            },
            {
              label: "Walnut wood",
              value: "Walnut wood",
              img: "/assets/images/panelOptions/upgrade-kitchen-cabinets/WalnutWood.jpeg",
            },
            {
              label: "Birch wood",
              value: "Birch wood",
              img: "/assets/images/panelOptions/upgrade-kitchen-cabinets/BirchWood.jpeg",
            },
            {
              label: "Mahogany wood",
              value: "Mahogany wood",
              img: "/assets/images/panelOptions/upgrade-kitchen-cabinets/MahoganyWood.jpeg",
            },
            {
              label: "Rose wood",
              value: "Rose wood",
              img: "/assets/images/panelOptions/upgrade-kitchen-cabinets/RoseWood.jpeg",
            },
          ],
        },
      },
      dependent_on: ["change_type", "select_customization"],
      required: true,
      error_msg: {
        change_type: {
          Material: "Please select material",
        },
        select_customization: {
          Material: "Please select material",
        },
      },
    },
    user_instructions: {
      type: "string",
      label: "Instructions to redesign",
      name: "Instructions to redesigns",
      path: "user_instructions",
      placeholder: "Please write your instructions in English.",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      dependent_on: "",
      required: false,
      error_msg: "Please enter instructions to redesign",
    },
  },
  upload_screen: {
    main_text: "Upload an image of kitchen",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    component: "withpanel",
    helper_text:
      "Masked area in the image will be reimagined with new designs.",
    cta_text: "Generate design",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  mask_screen: {
    component: "sam",
    helper_text:
      "Mask only the cabinets that you wish to reimagine and avoid masking other surfaces & furniture.",
  },
  results_screen: {
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Generate new design",
    cta_helper_text: "",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  view_results_screen: {
    generate_variations: true,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: [
      "wall",
      "floor",
      "ceiling",
      "fireplace",
      "windowpane",
      "stairway",
      "step",
      "stairs",
      "door",
      "railing;rail",
      "wardrobe",
      "refrigerator",
      "hood",
      "microwave",
      "countertop",
      "kitchen",
      "stove",
      "dishwasher",
      "sink",
      "oven",
      "curtain",
      "blind",
      "fan",
      "light",
      "chandelier",
    ],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "windowpane",
    ],
  },
  further_design_solutions: {
    generic: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
      ],
    },
    empty: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
      ],
    },
    furnished: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
      ],
    },
  },
};
