import { HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK } from "./constants";
export const REMOVE_OBJECT = {
  initial_screen: "mask_screen", //mask_screen/dp_screen
  solution_name: "remove-unwanted-objects",
  solution_id: "REMOVE_OBJECT",
  project_name: "New project",
  space_options: [
    { label: "Back of the house", value: "back of the house" },
    { label: "Front of the house", value: "front of the house" },
    { label: "Bedroom", value: "bedroom" },
    {
      label: "Living room/family room/lounge",
      value: "living room/family room/lounge",
    },
    { label: "Kids room", value: "kids room" },
    { label: "Nursery", value: "nursery" },
    { label: "Kitchen", value: "kitchen" },
    { label: "Bathroom/ensuite", value: "bathroom/ensuite" },
    { label: "Dining", value: "dining" },
    { label: "Foyer", value: "foyer" },
    { label: "Games area/rumpus room", value: "games area/rumpus room" },
    { label: "Hobby/craft room", value: "hobby/craft room" },
    { label: "Bar", value: "bar" },
    { label: "Laundry", value: "laundry" },
    { label: "Media room", value: "media room" },
    { label: "Pantry", value: "pantry" },
    { label: "Single room studio/unit", value: "single room studio/unit" },
    { label: "Study", value: "study" },
    { label: "Sunroom", value: "sunroom" },
  ],
  upload_space_type_options: {
    is_space: true,
    options: ['Empty', 'Furnished'],
    allowed_types: [true, false],
    error_msg: 'Not allowed',
    is_redirect: true,
    redirect_solution: 'VS_EMPTY_ROOM',
  },
  space_type: "",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: false,
  enable_mask_overlay: true,
  pre_fetch_mask: true,
  is_masking_enabled: true,
  results: [],
  side_panel_enabled: true,
  side_panel_schema: {},
  upload_screen: {
    main_text: "Upload an image of home interior/exterior",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    component: "withpanel",
    helper_text: "Masked objects in the image will be removed.",
    cta_text: "Remove objects",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    mask_mode: true,
    mask_helper_text: "Mask the object you want to remove*",
  },
  mask_screen: {
    component: "sam",
    helper_text:
      "Mask only the objects that you wish to remove & avoid masking other objects.",
  },
  results_screen: {
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Try again",
    cta_helper_text: "Didn't like the outputs?",
    mask_mode: true,
    mask_helper_text: "Mask the object you want to remove*",
  },
  view_results_screen: {
    generate_variations: false,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: [
      "wall",
      "floor",
      "ceiling",
      "fireplace",
      "windowpane",
      "stairway",
      "step",
      "stairs",
      "door",
      "railing;rail",
      "wardrobe",
      "refrigerator",
      "hood",
      "microwave",
      "countertop",
      "kitchen",
      "stove",
      "dishwasher",
      "sink",
      "oven",
      "curtain",
      "blind",
      "fan",
      "light",
      "chandelier",
    ],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "windowpane",
    ],
  },
  // further_design_solutions: {
  //   bedroom:[
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   "living room/family room/lounge": [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   "kids room": [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   nursery: [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   dining: [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   foyer: [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   "games area/rumpus room": [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   "hobby/craft room": [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   bar: [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   laundry: [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   "media room": [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   pantry: [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   "single room studio/unit": [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   study: [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   sunroom: [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //   ],
  //   "front of the house": [
  //     "Pool renovation",
  //     "Sky replacement",
  //     "Lawn enhancement",
  //     "Landscaping",
  //     "Change pathways",
  //   ],
  //   "back of the house": [
  //     "Pool renovation",
  //     "Sky replacement",
  //     "Lawn enhancement",
  //     "Landscaping",
  //     "Change pathways",
  //   ],
  //   "bathroom/ensuite": [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //     "Remodel bathroom",
  //     "Upgrade bathroom cabinets",
  //     "Upgrade bathroom countertop",
  //     "Upgrade bathroom wall tiles",
  //   ],
  //   kitchen: [
  //     "Change wall color",
  //     "Change floor",
  //     "Change ceiling",
  //     "Virtually stage empty room",
  //     "Virtually stage furnished room",
  //     "AI interior design",
  //     "Change material & color of furnishing elements",
  //     "Upgrade kitchen backsplash",
  //     "Upgrade kitchen countertop",
  //     "Upgrade kitchen cabinets",
  //     "Upgrade kitchen appliances",
  //   ],
  // },
  further_design_solutions: {
    generic: {
      generic: [
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      "bathroom/ensuite": [
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      kitchen: [
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
        "CHANGE_MATERIALS_ELEMENT",
      ],

      "front of the house":[
        "REMOVE_OBJECT",
        "LAWN_ENHANCEMENT",
        "SKY_ENHANCEMENT",
        "POOL_WATER_ENHANCEMENT",
        "CHANGE_PATHWAYS_WALKWAYS",
        "LANDSCAPING",
        "UNDER_CONSTRUCTION_EXTERIOR",
      ],

      "back of the house":[
        "REMOVE_OBJECT",
        "LAWN_ENHANCEMENT",
        "SKY_ENHANCEMENT",
        "POOL_WATER_ENHANCEMENT",
        "CHANGE_PATHWAYS_WALKWAYS",
        "LANDSCAPING",
        "UNDER_CONSTRUCTION_EXTERIOR",
      ],


    },
    empty: {
      generic: [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      "bathroom/ensuite": [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      kitchen: [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      "front of the house":[
        "REMOVE_OBJECT",
        "LAWN_ENHANCEMENT",
        "SKY_ENHANCEMENT",
        "POOL_WATER_ENHANCEMENT",
        "CHANGE_PATHWAYS_WALKWAYS",
        "LANDSCAPING",
        "UNDER_CONSTRUCTION_EXTERIOR",
      ],

      "back of the house":[
        "REMOVE_OBJECT",
        "LAWN_ENHANCEMENT",
        "SKY_ENHANCEMENT",
        "POOL_WATER_ENHANCEMENT",
        "CHANGE_PATHWAYS_WALKWAYS",
        "LANDSCAPING",
        "UNDER_CONSTRUCTION_EXTERIOR",
      ],
    },
    furnished: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      "bathroom/ensuite": [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      kitchen: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      "front of the house":[
        "REMOVE_OBJECT",
        "LAWN_ENHANCEMENT",
        "SKY_ENHANCEMENT",
        "POOL_WATER_ENHANCEMENT",
        "CHANGE_PATHWAYS_WALKWAYS",
        "LANDSCAPING",
        "UNDER_CONSTRUCTION_EXTERIOR",
      ],

      "back of the house":[
        "REMOVE_OBJECT",
        "LAWN_ENHANCEMENT",
        "SKY_ENHANCEMENT",
        "POOL_WATER_ENHANCEMENT",
        "CHANGE_PATHWAYS_WALKWAYS",
        "LANDSCAPING",
        "UNDER_CONSTRUCTION_EXTERIOR",
      ],
    },
  },
};
