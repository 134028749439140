import { HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK } from "./constants";

export const SURFACE_CHANGE_BATHROOM_COUNTERTOP = {
  initial_screen: "mask_screen", //mask_screen/dp_screen
  solution_name: "upgrade-bathroom-countertop",
  solution_id: "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
  project_name: "New project",
  space_options: [{ label: "Bathroom/ensuite", value: "bathroom/ensuite" }],
  space_type: "bathroom/ensuite",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: false,
  enable_mask_overlay: true,
  pre_fetch_mask: true,
  is_masking_enabled: true,
  results: [],
  side_panel_enabled: true,
  side_panel_schema: {
    countertop_type: {
      type: "dropdown",
      label: "Material type",
      name: "countertop type",
      path: "countertop_type",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        { label: "Marble/Granite", value: "Marble" },
        { label: "Quartz", value: "Quartz" },
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select material type",
    },
    countertop_sub_type: {
      type: "picker",
      labels: {
        countertop_type: {
          Marble: "Select marble/granite type",
          Quartz: "Select quartz type",
        },
      },
      name: "theme",
      path: "countertop_sub_type",
      value: "",
      temp_value: "",
      info_enabled: true,
      info_txt:
        "Click on the button given below or type your preferred color combination in the text box.",
      options: {
        countertop_type: {
          Marble: [
            {
              label: "White Marble",
              value: "White Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/marble/WhiteMarble.jpeg",
            },
            {
              label: "Black Marble",
              value: "Black Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/marble/BlackMarble.jpeg",
            },
            {
              label: "Brown Marble",
              value: "Brown Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/marble/BrownMarble.jpeg",
            },
            {
              label: "Cream Marble",
              value: "Cream Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/marble/CreameMarble.jpeg",
            },
            {
              label: "Blue Marble",
              value: "Blue Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/marble/Bluemarble.jpeg",
            },
            {
              label: "Green Marble",
              value: "Green Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/marble/Green Marble.jpeg",
            },
            {
              label: "Grey Marble",
              value: "Grey Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/marble/Grey Marble.jpeg",
            },
            {
              label: "Pink Marble",
              value: "Pink Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/marble/Pink Marble.jpeg",
            },
            {
              label: "Yellow marble",
              value: "Yellow Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/marble/Yellow Marble.jpeg",
            },
          ],
          Quartz: [
            {
              label: "Beige quartz",
              value: "Beige Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/Beige quartz .jpeg",
            },
            {
              label: "Black quartz",
              value: "Black Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/Black quartz .jpeg",
            },
            {
              label: "Blue quartz",
              value: "Blue Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/Blue quartz .jpeg",
            },
            {
              label: "Brown quartz",
              value: "Brown Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/Brown quartz .jpeg",
            },
            {
              label: "Dull Blue quartz",
              value: "Dull Blue Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/Dull Blue quartz.jpeg",
            },
            {
              label: "Green quartz",
              value: "Green Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/Green quartz .jpeg",
            },
            {
              label: "Grey quartz",
              value: "Grey Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/Grey quartz .jpeg",
            },
            {
              label: "Light brown quartz",
              value: "Light brown Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/Light Brown quartz.jpeg",
            },
            {
              label: "Orange quartz",
              value: "Orange Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/Orange quartz.jpeg",
            },
            {
              label: "Pink quartz",
              value: "Pink Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/Pink quartz .jpeg",
            },
            {
              label: "Red quartz",
              value: "Red Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/Red quartz.jpeg",
            },
            {
              label: "White quartz",
              value: "White Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/White quartz .jpeg",
            },
            {
              label: "Yellow quartz",
              value: "Yellow Color",
              img: "/assets/images/panelOptions/kitchen-counter-top/quartz/Yellow quartz .webp",
            },
          ],
        },
      },
      dependent_on: ["countertop_type"],
      required: true,
      error_msg: {
        countertop_type: {
          Marble: "Please select marble/granite type",
          "butcher block": "Please select wooden butcher block type",
          Quartz: "Please select quartz type",
        },
      },
    },
    user_instructions: {
      type: "string",
      label: "Instructions to redesign",
      name: "Instructions to redesigns",
      path: "user_instructions",
      placeholder: "Please write your instructions in English.",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      dependent_on: "",
      required: false,
      error_msg: "Please enter instructions to redesign",
    },
  },
  upload_screen: {
    main_text: "Upload an image of bathroom",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    component: "withpanel",
    helper_text:
      "Masked area in the image will be reimagined with new designs.",
    cta_text: "Generate design",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  mask_screen: {
    component: "sam",
    helper_text:
      "Mask only the countertop that you wish to reimagine and avoid masking other surfaces & furniture.",
  },
  results_screen: {
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Generate new design",
    cta_helper_text: "",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  view_results_screen: {
    generate_variations: true,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: [
      "wall",
      "floor",
      "ceiling",
      "fireplace",
      "windowpane",
      "stairway",
      "step",
      "stairs",
      "door",
      "railing;rail",
      "wardrobe",
      "refrigerator",
      "hood",
      "microwave",
      "countertop",
      "kitchen",
      "stove",
      "dishwasher",
      "sink",
      "oven",
      "curtain",
      "blind",
      "fan",
      "light",
      "chandelier",
    ],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "windowpane",
    ],
  },
  further_design_solutions: {
    generic: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "REMOVE_OBJECT",
      ],
    },
    empty: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "REMOVE_OBJECT",
      ],
    },
    furnished: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "REMOVE_OBJECT",
      ],
    },
  },
};
