import { HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK } from "./constants";
export const SURFACE_CHANGE_FLOOR = {
  initial_screen: "mask_screen", //mask_screen/dp_screen
  solution_name: "change-floor",
  solution_id: "SURFACE_CHANGE_FLOOR",
  project_name: "New project",
  space_options: [
    { label: "Bedroom", value: "bedroom" },
    {
      label: "Living room/family room/lounge",
      value: "living room/family room/lounge",
    },
    { label: "Kids room", value: "kids room" },
    { label: "Nursery", value: "nursery" },
    { label: "Kitchen", value: "kitchen" },
    { label: "Bathroom/ensuite", value: "bathroom/ensuite" },
    { label: "Dining", value: "dining" },
    { label: "Foyer", value: "foyer" },
    { label: "Games area/rumpus room", value: "games area/rumpus room" },
    { label: "Hobby/craft room", value: "hobby/craft room" },
    { label: "Bar", value: "bar" },
    { label: "Laundry", value: "laundry" },
    { label: "Media room", value: "media room" },
    { label: "Pantry", value: "pantry" },
    { label: "Single room studio/unit", value: "single room studio/unit" },
    { label: "Study", value: "study" },
    { label: "Sunroom", value: "sunroom" },
  ],
  upload_space_type_options: {
    is_space: true,
    options: ['Empty', 'Furnished'],
    allowed_types: [true, false],
    error_msg: 'Not allowed',
    is_redirect: true,
    redirect_solution: 'VS_EMPTY_ROOM',
  },
  space_type: "",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: false,
  enable_mask_overlay: true,
  pre_fetch_mask: true,
  is_masking_enabled: true,
  results: [],
  side_panel_enabled: true,
  side_panel_schema: {
    material_type: {
      type: "dropdown",
      label: "Select material",
      name: "material type",
      path: "material_type",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        { label: "Marble Tile", value: "Marble Tile" },
        { label: "Hardwood Flooring", value: "Hardwood Flooring" },
        { label: "Ceramic Tile", value: "Ceramic Tile" },
        { label: "Carpet Tile", value: "Carpet Tile" },
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select change type",
    },
    material_sub_type: {
      type: "picker",
      labels: {
        material_type: {
          "Ceramic Tile": "Select ceramic tile type",
          "Carpet Tile": "Select carpet tile type",
          "Hardwood Flooring": "Select hardwood flooring type",
          "Marble Tile": "Select marble tile type",
        },
      },
      name: "theme",
      path: "material_sub_type",
      value: "",
      temp_value: "",
      info_enabled: true,
      info_txt:
        "Click on the button given below or type your preferred color combination in the text box.",
      options: {
        material_type: {
          "Ceramic Tile": [
            {
              label: "Snow White",
              value: "White Color",
              img: "/assets/images/panelOptions/change-floor/ceramic-tile/SnowWhite.jpeg",
            },
            {
              label: "Midnight Black",
              value: "Black Color",
              img: "/assets/images/panelOptions/change-floor/ceramic-tile/MidnightBlack.jpeg",
            },
            {
              label: "Ocean Blue",
              value: "Blue Color",
              img: "/assets/images/panelOptions/change-floor/ceramic-tile/OceanBlue.jpeg",
            },
            {
              label: "Meadow Green",
              value: "Green Color",
              img: "/assets/images/panelOptions/change-floor/ceramic-tile/MeadowGreen.jpeg",
            },
            {
              label: "Sand Beige",
              value: "Beige Color",
              img: "/assets/images/panelOptions/change-floor/ceramic-tile/SandBeige.jpeg",
            },
            {
              label: "Ruby Radiance",
              value: "Red Color",
              img: "/assets/images/panelOptions/change-floor/ceramic-tile/RubyRadiance.jpeg",
            },
          ],
          "Carpet Tile": [
            {
              label: "Grey",
              value: "Grey Color",
              img: "/assets/images/panelOptions/change-floor/carpet-tile/Grey.jpeg",
            },
            {
              label: "Charcoal",
              value: "Charcoal Color",
              img: "/assets/images/panelOptions/change-floor/carpet-tile/Charcoal.jpeg",
            },
            {
              label: "Beige",
              value: "Beige Color",
              img: "/assets/images/panelOptions/change-floor/carpet-tile/Beige.jpeg",
            },
            {
              label: "Blue",
              value: "Blue Color",
              img: "/assets/images/panelOptions/change-floor/carpet-tile/Blue.jpeg",
            },
            {
              label: "Brown",
              value: "Brown Color",
              img: "/assets/images/panelOptions/change-floor/carpet-tile/Brown.jpeg",
            },
          ],
          "Hardwood Flooring": [
            {
              label: "Black wood",
              value: "Black color wood",
              img: "/assets/images/panelOptions/change-floor/hardwood-tile/BlackWood.jpeg",
            },
            {
              label: "Dark brown wood",
              value: "Dark brown color wood",
              img: "/assets/images/panelOptions/change-floor/hardwood-tile/DarkBrownWood.jpeg",
            },
            {
              label: "Brown wood",
              value: "Brown color wood",
              img: "/assets/images/panelOptions/change-floor/hardwood-tile/BrownWood.jpeg",
            },
            {
              label: "Grey wood",
              value: "Grey color wood",
              img: "/assets/images/panelOptions/change-floor/hardwood-tile/GreyWood.jpeg",
            },
            {
              label: "Light Brown Wood",
              value: "Light Brown color Wood",
              img: "/assets/images/panelOptions/change-floor/hardwood-tile/Lightwood.jpeg",
            },
            {
              label: "White wood",
              value: "White color wood",
              img: "/assets/images/panelOptions/change-floor/hardwood-tile/WhiteWood.jpeg",
            },
            {
              label: "Red wood",
              value: "Red color wood",
              img: "/assets/images/panelOptions/change-floor/hardwood-tile/Redwood.jpeg",
            },
          ],
          "Marble Tile": [
            {
              label: "White Marble",
              value: "White Color Marble",
              img: "/assets/images/panelOptions/change-floor/marble-tile/WhiteMarble.jpeg",
            },
            {
              label: "Black Marble",
              value: "Black Color Marble",
              img: "/assets/images/panelOptions/change-floor/marble-tile/BlackMarble.jpeg",
            },
            {
              label: "Brown Marble",
              value: "Brown Color Marble",
              img: "/assets/images/panelOptions/change-floor/marble-tile/BrownMarble.jpeg",
            },
            {
              label: "Cream Marble",
              value: "Cream Color Marble",
              img: "/assets/images/panelOptions/change-floor/marble-tile/CreameMarble.jpeg",
            },
            {
              label: "Blue Marble",
              value: "Blue Color Marble",
              img: "/assets/images/panelOptions/change-floor/marble-tile/Bluemarble.jpeg",
            },
            {
              label: "Green Marble",
              value: "Green Color Marble",
              img: "/assets/images/panelOptions/change-floor/marble-tile/GreenMarble.jpeg",
            },
            {
              label: "Grey Marble",
              value: "Grey Color Marble",
              img: "/assets/images/panelOptions/change-floor/marble-tile/GreyMarble.jpeg",
            },
            {
              label: "Pink Marble",
              value: "Pink Color Marble",
              img: "/assets/images/panelOptions/change-floor/marble-tile/PinkMarble.jpeg",
            },
            {
              label: "Yellow marble",
              value: "Yellow Color marble",
              img: "/assets/images/panelOptions/change-floor/marble-tile/YellowMarble.jpeg",
            },
          ],
        },
      },
      dependent_on: ["material_type"],
      required: true,
      error_msg: {
        material_type: {
          "Ceramic Tile": "Please select ceramic tile type",
          "Carpet Tile": "Please select carpet tile type",
          "Hardwood Flooring": "Please select hardwood flooring type",
          "Marble Tile": "Please select marble tile type",
        },
      },
    },
    user_instructions: {
      type: "string",
      label: "Instructions to redesign",
      name: "Instructions to redesigns",
      path: "user_instructions",
      placeholder: "Please write your instructions in English.",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      dependent_on: "",
      required: false,
      error_msg: "Please enter instructions to redesign",
    },
  },
  upload_screen: {
    main_text: "Upload an image of home interior",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    component: "withpanel",
    helper_text:
      "Masked area in the image will be reimagined with new designs.",
    cta_text: "Generate design",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  mask_screen: {
    component: "sam",
    helper_text:
      "Mask only the floor area that you wish to reimagine and avoid masking other surfaces & furniture.",
  },
  results_screen: {
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Generate new design",
    cta_helper_text: "",
    mask_mode: true,
    mask_helper_text: "Mask the area you want to reimagine",
  },
  view_results_screen: {
    generate_variations: true,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: [
      "wall",
      "floor",
      "ceiling",
      "fireplace",
      "windowpane",
      "stairway",
      "step",
      "stairs",
      "door",
      "railing;rail",
      "wardrobe",
      "refrigerator",
      "hood",
      "microwave",
      "countertop",
      "kitchen",
      "stove",
      "dishwasher",
      "sink",
      "oven",
      "curtain",
      "blind",
      "fan",
      "light",
      "chandelier",
    ],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "windowpane",
    ],
  },
  further_design_solutions: {
    generic: {
      generic: [
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
      ],
      "bathroom/ensuite": [
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
      ],
      kitchen: [
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
      ],
    },
    empty: {
      generic: [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
      ],
      "bathroom/ensuite": [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
      ],
      kitchen: [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
      ],
    },
    furnished: {
      generic: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      "bathroom/ensuite": [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "OCCUPIED_TO_VACANT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
        "CHANGE_MATERIALS_ELEMENT",
      ],
      kitchen: [
        "VIRTUAL_STAGING_FURNISHED_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "OCCUPIED_TO_VACANT",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
        "CHANGE_MATERIALS_ELEMENT",
      ],
    },
  },
};
