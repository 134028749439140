import * as SOLUTIONS_MASTER_SCHEMA from "./solutions-schema";
export const NAVBAR_CONST = {
  top: [
    {
      Text: "Earn 30% commission",
      HandlerType: "affiliate",
      Icon: "affiliate",
      Slug: "affiliate",
    },
    // {
    //   Text: "Home",
    //   Link: "/",
    //   Icon: "homeIcon",
    //   Slug: "/",
    // },
    // {
    //   Text: "Solutions",
    //   Link: "/solutions",
    //   Icon: "solutions",
    //   Slug: "solutions",
    // },
    {
      Text: "AI tools",
      Icon: "aiTools",
      List: [
        {
          Text: "AI Virtual Staging",
          Link: "/solutions#AI Virtual Staging",
          Icon: "sidebarAiVirtualStaging",
          Slug: "solutions",
        },
        {
          Text: "AI Virtual Renovation",
          Link: "/solutions#AI Virtual Renovation",
          Icon: "sidebarAiVirtualRennovation",
          Slug: "solutions",
        },
        {
          Text: "AI Space Decluttering",
          Link: "/solutions#AI Space Decluttering",
          Icon: "sidebarAiSpaceDecluttering",
          Slug: "solutions",
        },
        {
          Text: "AI Exterior Enhancement",
          Link: "/solutions#AI Exterior Enhancement",
          Icon: "sidebarAiExteriorEnhacement",
          Slug: "solutions",
        },
        {
          Text: "AI Under Construction Property Visualizer",
          Link: "/solutions#AI Under Construction Property Visualizer",
          Icon: "sidebarAiUnderConstruction",
          Slug: "solutions",
        },
        {
          Text: "AI Kitchen Designer",
          Link: "/solutions#AI Kitchen Designer",
          Icon: "sidebarAiKitchenDesigner",
          Slug: "solutions",
        },
        {
          Text: "AI Bathroom Designer",
          Link: "/solutions#AI Bathroom Designer",
          Icon: "sidebarAiBathroomDesigner",
          Slug: "solutions",
        },
        {
          Text: "AI Color & Material Editor",
          Link: "/solutions#AI Color & Material Editor",
          Icon: "sidebarAiColorAndMaterial",
          Slug: "solutions",
        },
        {
          Text: "AI Interior Designer",
          Link: "/solutions#AI Interior Designer",
          Icon: "sidebarAiInteriorDesign",
          Slug: "solutions",
        },
        {
          Text: "AI Landscape Designer",
          Link: "/solutions#AI Landscape Designer",
          Icon: "sidebarAiLandscape",
          Slug: "solutions",
        },
        {
          Text: "Surprise me",
          Link: "/surprise-me/upload",
          Icon: "surpriseMeIcon",
          Slug: "surprise-me",
        },
      ],
    },
    // {
    //   Text: "Surprise me",
    //   Link: "/surprise-me/upload",
    //   Icon: "surpriseMeIcon",
    //   Slug: "surprise-me",
    // },
  ],
  manage: [
    {
      Text: "My projects",
      Link: "/projects",
      Icon: "myProject",
      Slug: "projects",
    },
    {
      Text: "My plan",
      Link: "/pricing",
      Icon: "generations",
      Slug: "pricing",
      badgeText: 1000,
    },
    
  ],
  helpCenter: [
    // {
    //   Text: "FAQs",
    //   Link: "/faq",
    //   Icon: "faq",
    //   Slug: "faq",
    // },
    // {
    //   Text: "Chat with us",
    //   HandlerType: "openChat",
    //   Icon: "chatWithUsIcon",
    // },
    {
      Text: "Tutorials",
      Link: "/tutorials",
      Icon: "tutorials",
      Slug: "tutorials",
    },
  ],
  bottom: [
    {
      Text: "Profile",
      Icon: "profile",
      List: [
        {
          Text: "My profile",
          Link: "/my-profile",
          Slug: "my-profile",
        },
        // {
        //   Text: "Referrals",
        //   Link: "/referrals",
        //   Slug: "Referrals",
        // },
        {
          Text: "Sign out",
          HandlerType: "signOut",
        },
      ],
    },
  ],
};

export const NAVBAR_LINKS_CONST = {
  "surprise-me": "Surprise me",
  solutions: "Solutions",
  projects: "My projects",
  tutorials: "Tutorials",
  pricing: "My plan",
  faq: "FAQs",
  "my-profile": "My profile",
};

export const SOLUTION_DISPLAY_MAPPING = {
  VIRTUAL_STAGING: "Virtual Staging",

  SURPRISE_ME: "Surprise me",

  VIRTUAL_STAGING_EMPTY_ROOM: "AI Virtual Staging of an Empty Room",
  FURNISH_EMPTY_ROOM: "Furnish Empty Room",
  VIRTUAL_STAGING_FURNISHED_ROOM: "AI Virtual Staging of a Cluttered Room",

  AI_INTERIOR_DESIGN: "AI Interior Designer",
  REDESIGN_FURNISHED_ROOM:'Redesign Furnished Room',
  REDESIGN_FURNISHED_ROOMS:'Redesign Furnished Rooms',
  SURFACE_CHANGE_WALL: "AI Wall Color & Wallpaper Transformation",
  SURFACE_CHANGE_CEILING: "AI Ceiling Transformation",
  SURFACE_CHANGE_FLOOR: "AI Flooring Transformation",

  LAWN_ENHANCEMENT: "AI Lawn Enhancement",
  SKY_ENHANCEMENT: "AI Sky Replacement",
  POOL_WATER_ENHANCEMENT: "AI Pool Water Enhancement",
  CHANGE_PATHWAYS_WALKWAYS: "AI Change Pathways",

  CHANGE_MATERIALS_ELEMENT: "AI Color & Material Editor",

  LANDSCAPING: "AI Landscape Designer",
  AI_LANDSCAPING: "Landscaping",
  REMOVE_OBJECT: "AI Object Removal",
  OCCUPIED_TO_VACANT: "AI Occupied to Vacant",
  EMPTY_THE_SPACE:"Empty the Space",
  EMPTY_YOUR_SPACE:"Empty Your Space",
  BATHROOM_REMODELING: "AI Bathroom Remodeling",
  SURFACE_CHANGE_BATHROOM_WALL_TILE: "AI Bathroom Wall Tiles",
  SURFACE_CHANGE_BATHROOM_CABINETS: "AI Bathroom Cabinets",
  SURFACE_CHANGE_BATHROOM_COUNTERTOP: "AI Bathroom Countertop",

  KITCHEN_REMODELING: "AI Kitchen Remodeling",
  SURFACE_CHANGE_KITCHEN_COUNTERTOP: "AI Kitchen Countertop",
  SURFACE_CHANGE_KITCHEN_CABINETS: "AI Kitchen Cabinets",
  SURFACE_CHANGE_KITCHEN_APPLIANCES: "AI Kitchen Appliances",
  SURFACE_CHANGE_KITCHEN_BACKSPLASH: "AI Kitchen Backsplash",

  UNDER_CONSTRUCTION_INTERIOR: "AI Interior Visualizer",
  UNDER_CONSTRUCTION_EXTERIOR: "AI Exterior Visualizer",
  RENDER_EXTERIOR_STRUCTURES:"Render Exterior Structure",
  
};

export const SOLUTION_SCHEMA_MAPPING = {
  SURPRISE_ME: "surprise-me",

  VIRTUAL_STAGING_EMPTY_ROOM: "virtually-stage-empty-room",
  VIRTUAL_STAGING_FURNISHED_ROOM: "virtually-stage-furnished-room",

  AI_INTERIOR_DESIGN: "ai-interior-design",
  REDESIGN_FURNISHED_ROOM:'redesign-furnished-room',
  SURFACE_CHANGE_WALL: "change-wall-color",
  SURFACE_CHANGE_CEILING: "change-ceiling",
  SURFACE_CHANGE_FLOOR: "change-floor",

  LAWN_ENHANCEMENT: "lawn-enhancement",
  SKY_ENHANCEMENT: "sky-replacement",
  POOL_WATER_ENHANCEMENT: "pool-renovation",
  CHANGE_PATHWAYS_WALKWAYS: "change-pathways",

  CHANGE_MATERIALS_ELEMENT: "change-material-color-of-furnishing-elements",

  LANDSCAPING: "landscaping",
  
  REMOVE_OBJECT: "remove-unwanted-objects",
  OCCUPIED_TO_VACANT: "occupied-to-vacant",
  EMPTY_THE_SPACE:"empty-the-space",
  EMPTY_YOUR_SPACE:"empty-your-space",
  BATHROOM_REMODELING: "remodel-bathroom",
  SURFACE_CHANGE_BATHROOM_WALL_TILE: "upgrade-bathroom-wall-tiles",
  SURFACE_CHANGE_BATHROOM_CABINETS: "upgrade-bathroom-cabinets",
  SURFACE_CHANGE_BATHROOM_COUNTERTOP: "upgrade-bathroom-countertop",

  KITCHEN_REMODELING: "remodel-kitchen",
  SURFACE_CHANGE_KITCHEN_COUNTERTOP: "upgrade-kitchen-countertop",
  SURFACE_CHANGE_KITCHEN_CABINETS: "upgrade-kitchen-cabinets",
  SURFACE_CHANGE_KITCHEN_APPLIANCES: "upgrade-kitchen-appliances",
  SURFACE_CHANGE_KITCHEN_BACKSPLASH: "upgrade-kitchen-backsplash",

  UNDER_CONSTRUCTION_INTERIOR: "redesign-under-construction-home-interior",
  UNDER_CONSTRUCTION_EXTERIOR: "redesign-under-construction-home-exterior",

  FURNISH_EMPTY_ROOM: "furnish-empty-room",

  VIRTUAL_STAGING : "virtual-staging",

  REDESIGN_FURNISHED_ROOMS : "redesign-furnished-rooms",
  AI_LANDSCAPING: "ai-landscaping",
  RENDER_EXTERIOR_STRUCTURES:"render-exterior-structures",
};

export const CROSS_SELL_MAPPING = {
  SURPRISE_ME: "https://www.styldod.com/virtual-staging",
  VIRTUAL_STAGING_EMPTY_ROOM: "https://www.styldod.com/virtual-staging",
  VIRTUAL_STAGING_FURNISHED_ROOM: "https://www.styldod.com/virtual-staging",
  AI_INTERIOR_DESIGN: "https://www.styldod.com/virtual-staging",
  SURFACE_CHANGE_WALL: "https://www.styldod.com/virtual-renovation",
  SURFACE_CHANGE_CEILING: "https://www.styldod.com/virtual-renovation",
  SURFACE_CHANGE_FLOOR: "https://www.styldod.com/virtual-renovation",
  LAWN_ENHANCEMENT: "https://www.styldod.com/image-enhancement",
  SKY_ENHANCEMENT: "https://www.styldod.com/image-enhancement",
  POOL_WATER_ENHANCEMENT: "https://www.styldod.com/image-enhancement",
  CHANGE_PATHWAYS_WALKWAYS: "https://www.styldod.com/virtual-renovation",
  CHANGE_MATERIALS_ELEMENT: null, // The link for this entry is missing in the provided information
  LANDSCAPING: "https://www.styldod.com/virtual-renovation",
  REMOVE_OBJECT: "https://www.styldod.com/object-removal",
  OCCUPIED_TO_VACANT: "https://www.styldod.com/occupied-to-vacant",
  BATHROOM_REMODELING: "https://www.styldod.com/virtual-renovation",
  SURFACE_CHANGE_BATHROOM_WALL_TILE:
    "https://www.styldod.com/virtual-renovation",
  SURFACE_CHANGE_BATHROOM_CABINETS:
    "https://www.styldod.com/virtual-renovation",
  SURFACE_CHANGE_BATHROOM_COUNTERTOP:
    "https://www.styldod.com/virtual-renovation",
  KITCHEN_REMODELING: "https://www.styldod.com/virtual-renovation",
  SURFACE_CHANGE_KITCHEN_COUNTERTOP:
    "https://www.styldod.com/virtual-renovation",
  SURFACE_CHANGE_KITCHEN_CABINETS: "https://www.styldod.com/virtual-renovation",
  SURFACE_CHANGE_KITCHEN_APPLIANCES:
    "https://www.styldod.com/virtual-renovation",
  SURFACE_CHANGE_KITCHEN_BACKSPLASH:
    "https://www.styldod.com/virtual-renovation",
  UNDER_CONSTRUCTION_INTERIOR: "https://www.styldod.com/3d-rendering",
  UNDER_CONSTRUCTION_EXTERIOR: "https://www.styldod.com/3d-rendering",
};

export const SOLUTIONS_DETAIL_POPUP_CONST = {
  VIRTUAL_STAGING_EMPTY_ROOM: {
    title: SOLUTION_DISPLAY_MAPPING["VIRTUAL_STAGING_EMPTY_ROOM"],
    description:
      "Help buyers fall in love with your listings by turning vacant rooms into stylish spaces.",
    beforeImg: "/assets/images/solutions/VSEmpty_Before.webp",
    afterImg: "/assets/images/solutions/VSEmpty_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["VIRTUAL_STAGING_EMPTY_ROOM"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/VS_Empty_B1.webp",
        afterImg: "/assets/examples/VS_Empty_B2.webp",
      },
      {
        beforeImg: "/assets/examples/VS_Empty_A1.webp",
        afterImg: "/assets/examples/VS_Empty_A2.webp",
      },
    ],
  },
  VIRTUAL_STAGING_FURNISHED_ROOM: {
    title: SOLUTION_DISPLAY_MAPPING["VIRTUAL_STAGING_FURNISHED_ROOM"],
    description:
      "Make property more appealing and interesting to your buyers by converting cluttered rooms to inviting spaces.",
    beforeImg: "/assets/images/solutions/VSFurnished_Before.webp",
    afterImg: "/assets/images/solutions/VSFurnished_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["VIRTUAL_STAGING_FURNISHED_ROOM"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/VS_Furnished_B1.webp",
        afterImg: "/assets/examples/VS_Furnished_B2.webp",
      },
      {
        beforeImg: "/assets/examples/VS_Furnished_A1.webp",
        afterImg: "/assets/examples/VS_Furnished_A2.webp",
      },
    ],
  },
  "Generate design inspiration": {
    title: "Generate design inspiration",
    description:
      "Preview & customize interiors for under-construction properties, ensuring satisfaction and confidence in your investment.",
    img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["Generate"]}`,
    example_imgs: [
      {
        img: "/assets/examples/",
        video: "/assets/examples/",
      },
      {
        img: "/assets/examples/",
        video: "/assets/examples/",
      },
    ],
  },
  SURFACE_CHANGE_WALL: {
    title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_WALL"],
    description: "Attract more potential buyers with virtual room makeovers.",
    beforeImg: "/assets/images/solutions/ChangeWall_Before.webp",
    afterImg: "/assets/images/solutions/ChangeWall_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_WALL"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/ChangeWall_B1.webp",
        afterImg: "/assets/examples/ChangeWall_B2.webp",
      },
      {
        beforeImg: "/assets/examples/ChangeWall_A1.webp",
        afterImg: "/assets/examples/ChangeWall_A2.webp",
      },
    ],
  },
  LANDSCAPING: {
    title: SOLUTION_DISPLAY_MAPPING["LANDSCAPING"],
    description:
      "Generate diverse designs to impress and guide clients, streamlining project execution for landscapers.",
    beforeImg: "/assets/images/solutions/Landscaping_Before.webp",
    afterImg: "/assets/images/solutions/Landscaping_Aftre.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["LANDSCAPING"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/Landscaping_B1.webp",
        afterImg: "/assets/examples/Landscaping_B2.webp",
      },
      {
        beforeImg: "/assets/examples/Landscaping_A1.webp",
        afterImg: "/assets/examples/Landscaping_A2.webp",
      },
    ],
  },
  LAWN_ENHANCEMENT: {
    title: SOLUTION_DISPLAY_MAPPING["LAWN_ENHANCEMENT"],
    description:
      "Effortlessly enhance your property's outdoor spaces, boosting their visual appeal and overall value.",
      beforeImg: "/assets/examples/LawnEnhancement_B1.webp",
      afterImg: "/assets/examples/LawnEnhancement_B2.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["LAWN_ENHANCEMENT"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/images/solutions/LawnEnhancement_Before.webp",
        afterImg: "/assets/images/solutions/LawnEnhancement_After.webp",
      },
      {
        beforeImg: "/assets/examples/LawnEnhancement_A1.webp",
        afterImg: "/assets/examples/LawnEnhancement_A2.webp",
      },
    ],
  },
  SKY_ENHANCEMENT: {
    title: SOLUTION_DISPLAY_MAPPING["SKY_ENHANCEMENT"],
    description:
      "Transform your photos with stunning skies, enhancing their beauty and impact.",
    beforeImg: "/assets/images/solutions/ChangeSky_Before.webp",
    afterImg: "/assets/images/solutions/ChangeSky_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SKY_ENHANCEMENT"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/ChangeSky_B1.webp",
        afterImg: "/assets/examples/ChangeSky_B2.webp",
      },
      {
        beforeImg: "/assets/examples/ChangeSky_A1.webp",
        afterImg: "/assets/examples/ChangeSky_A2.webp",
      },
    ],
  },
  POOL_WATER_ENHANCEMENT: {
    title: SOLUTION_DISPLAY_MAPPING["POOL_WATER_ENHANCEMENT"],
    description:
      "Enhance pool photos with AI for crystal-clear water and greater appeal.",
      beforeImg: "/assets/examples/PoolWater_B1.webp",
      afterImg: "/assets/examples/PoolWater_B2.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["POOL_WATER_ENHANCEMENT"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/images/solutions/PoolWater_Before.webp",
        afterImg: "/assets/images/solutions/PoolWater_After.webp",
      },
      {
        beforeImg: "/assets/examples/PoolWater_A1.webp",
        afterImg: "/assets/examples/PoolWater_A2.webp",
      },
    ],
  },
  CHANGE_PATHWAYS_WALKWAYS: {
    title: SOLUTION_DISPLAY_MAPPING["CHANGE_PATHWAYS_WALKWAYS"],
    description:
      "Give outdoor spaces a fresh look by redesigning the pathways & walkways.",
    beforeImg: "/assets/images/solutions/ChangePath_Before.webp",
    afterImg: "/assets/images/solutions/ChangePath_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["CHANGE_PATHWAYS_WALKWAYS"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/ChangePathway_B1.webp",
        afterImg: "/assets/examples/ChangePathway_B2.webp",
      },
      {
        beforeImg: "/assets/examples/ChangePathway_A1.webp",
        afterImg: "/assets/examples/ChangePathway_A2.webp",
      },
    ],
  },
  OCCUPIED_TO_VACANT: {
    title: SOLUTION_DISPLAY_MAPPING["OCCUPIED_TO_VACANT"],
    description:
      "Remove cluttered furnishings from your photos and allow prospects to envision how the home could look.",
    beforeImg: "/assets/images/solutions/O2V_Before.webp",
    afterImg: "/assets/images/solutions/O2V_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["OCCUPIED_TO_VACANT"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/O2V_B1.webp",
        afterImg: "/assets/examples/O2V_B2.webp",
      },
      {
        beforeImg: "/assets/examples/O2V_A1.webp",
        afterImg: "/assets/examples/O2V_A2.webp",
      },
    ],
  },
  REMOVE_OBJECT: {
    title: SOLUTION_DISPLAY_MAPPING["REMOVE_OBJECT"],
    description:
      "Instantly remove unwanted or distracting items from your property photos.",
    beforeImg: "/assets/images/solutions/RemoveObject_Before.webp",
    afterImg: "/assets/images/solutions/RemoveObject_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["REMOVE_OBJECT"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/RemoveObject_B1.webp",
        afterImg: "/assets/examples/RemoveObject_B2.webp",
      },
      {
        beforeImg: "/assets/examples/RemoveObject_A1.webp",
        afterImg: "/assets/examples/RemoveObject_A2.webp",
      },
    ],
  },
  KITCHEN_REMODELING: {
    title: SOLUTION_DISPLAY_MAPPING["KITCHEN_REMODELING"],
    description:
      "Virtually achieve dream kitchen design with ease, maximizing functionality and visual appeal.",
    beforeImg: "/assets/images/solutions/RemodelKitchen_Before.webp",
    afterImg: "/assets/images/solutions/RemodelKitchen_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["KITCHEN_REMODELING"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/RemodelKitchen_B1.webp",
        afterImg: "/assets/examples/RemodelKitchen_B2.webp",
      },
      {
        beforeImg: "/assets/examples/RemodelKitchen_A1.webp",
        afterImg: "/assets/examples/RemodelKitchen_A2.webp",
      },
    ],
  },
  SURFACE_CHANGE_KITCHEN_COUNTERTOP: {
    title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_KITCHEN_COUNTERTOP"],
    description:
      "Visualize ideal kitchen countertop in various materials like wood, marble, and granite.",
    beforeImg: "/assets/images/solutions/KitchenCountertop_Before.webp",
    afterImg: "/assets/images/solutions/KitchenCountertop_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_KITCHEN_COUNTERTOP"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/KitchenCountertop_B1.webp",
        afterImg: "/assets/examples/KitchenCountertop_B2.webp",
      },
      {
        beforeImg: "/assets/examples/KitchenCountertop_A1.webp",
        afterImg: "/assets/examples/KitchenCountertop_A2.webp",
      },
    ],
  },
  SURFACE_CHANGE_KITCHEN_CABINETS: {
    title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_KITCHEN_CABINETS"],
    description:
      "Enhance kitchen with virtually customized cabinets for increased storage and visual appeal.",
    beforeImg: "/assets/images/solutions/KitchenCabinets_Before.webp",
    afterImg: "/assets/images/solutions/KitchenCabinets_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_KITCHEN_CABINETS"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/KitchenCabinets_B1.webp",
        afterImg: "/assets/examples/KitchenCabinets_B2.webp",
      },
      {
        beforeImg: "/assets/examples/KitchenCabinets_A1.webp",
        afterImg: "/assets/examples/KitchenCabinets_A2.webp",
      },
    ],
  },
  SURFACE_CHANGE_KITCHEN_APPLIANCES: {
    title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_KITCHEN_APPLIANCES"],
    description:
      "Transform kitchen with smart appliance choices for convenience, efficiency, and modern living.",
    beforeImg: "/assets/images/solutions/KitchenAppliance_Before.webp",
    afterImg: "/assets/images/solutions/KitchenAppliance_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_KITCHEN_APPLIANCES"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/KitchenAppliance_B1.webp",
        afterImg: "/assets/examples/KitchenAppliance_B2.webp",
      },
      {
        beforeImg: "/assets/examples/KitchenAppliance_A1.webp",
        afterImg: "/assets/examples/KitchenAppliance_A2.webp",
      },
    ],
  },
  SURFACE_CHANGE_KITCHEN_BACKSPLASH: {
    title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_KITCHEN_BACKSPLASH"],
    description:
      "Transform kitchen with the perfect backsplash design, enhancing style and functionality.",
    beforeImg: "/assets/images/solutions/KitchenBacksplash_Before.webp",
    afterImg: "/assets/images/solutions/KitchenBacksplash_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_KITCHEN_BACKSPLASH"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/KitchenBacksplash_B1.webp",
        afterImg: "/assets/examples/KitchenBacksplash_B2.webp",
      },
      {
        beforeImg: "/assets/examples/KitchenBacksplash_A1.webp",
        afterImg: "/assets/examples/KitchenBacksplash_A2.webp",
      },
    ],
  },
  UNDER_CONSTRUCTION_INTERIOR: {
    title: SOLUTION_DISPLAY_MAPPING["UNDER_CONSTRUCTION_INTERIOR"],
    description:
      "Preview & customize interiors for under-construction properties, ensuring satisfaction and confidence in your investment.",
    beforeImg: "/assets/images/solutions/underconstructionINT_Before.webp",
    afterImg: "/assets/images/solutions/underconstructionINT_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["UNDER_CONSTRUCTION_INTERIOR"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/UnderConstructionINT_B1.webp",
        afterImg: "/assets/examples/UnderConstructionINT_B2.webp",
      },
      {
        beforeImg: "/assets/examples/UnderConstructionINT_A1.webp",
        afterImg: "/assets/examples/UnderConstructionINT_A2.webp",
      },
    ],
  },
  UNDER_CONSTRUCTION_EXTERIOR: {
    title: SOLUTION_DISPLAY_MAPPING["UNDER_CONSTRUCTION_EXTERIOR"],
    description: "Remove any element from your space within a few clicks.",
    beforeImg: "/assets/images/solutions/underconstructionEXT_Before.webp",
    afterImg: "/assets/images/solutions/underconstructionEXT_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["UNDER_CONSTRUCTION_EXTERIOR"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/UnderConstructionEXT_B1.webp",
        afterImg: "/assets/examples/UnderConstructionEXT_B2.webp",
      },
      {
        beforeImg: "/assets/examples/UnderConstructionEXT_A1.webp",
        afterImg: "/assets/examples/UnderConstructionEXT_A2.webp",
      },
    ],
  },
  AI_INTERIOR_DESIGN: {
    title: SOLUTION_DISPLAY_MAPPING["AI_INTERIOR_DESIGN"],
    description:
      "Instantly access customized design ideas, eliminating the need for time-consuming searches and streamlining creative process.",
    beforeImg: "/assets/images/solutions/AI_InteriorDesign_Before.webp",
    afterImg: "/assets/images/solutions/AI_InteriorDesign_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["AI_INTERIOR_DESIGN"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/AI_InteriorDesign_B1.webp",
        afterImg: "/assets/examples/AI_InteriorDesign_B2.webp",
      },
      {
        beforeImg: "/assets/examples/AI_InteriorDesign_A1.webp",
        afterImg: "/assets/examples/AI_InteriorDesign_A2.webp",
      },
    ],
  },
  SURFACE_CHANGE_CEILING: {
    title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_CEILING"],
    description:
      "Unveil hidden potential of the space with custom ceiling makeover.",
    beforeImg: "/assets/images/solutions/ChangeCeiling_Before.webp",
    afterImg: "/assets/images/solutions/ChangeCeiling_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_CEILING"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/ChangeCeiling_B1.webp",
        afterImg: "/assets/examples/ChangeCeiling_B2.webp",
      },
      {
        beforeImg: "/assets/examples/ChangeCeiling_A1.webp",
        afterImg: "/assets/examples/ChangeCeiling_A2.webp",
      },
    ],
  },
  SURFACE_CHANGE_FLOOR: {
    title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_FLOOR"],
    description:
      "Discover diverse flooring options, from hardwood to carpet, and achieve your desired look virtually.",
    beforeImg: "/assets/images/solutions/ChangeFloor_Before.webp",
    afterImg: "/assets/images/solutions/ChangeFloor_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_FLOOR"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/ChangeFloor_B1.webp",
        afterImg: "/assets/examples/ChangeFloor_B2.webp",
      },
      {
        beforeImg: "/assets/examples/ChangeFloor_A1.webp",
        afterImg: "/assets/examples/ChangeFloor_A2.webp",
      },
    ],
  },
  CHANGE_MATERIALS_ELEMENT: {
    title: SOLUTION_DISPLAY_MAPPING["CHANGE_MATERIALS_ELEMENT"],
    description:
      "Effortlessly customize furnishings like cushions, sofas, curtains, rugs, and more to achieve the desired look.",
    beforeImg: "/assets/images/solutions/ChangeColorMaterial_Before.webp",
    afterImg: "/assets/images/solutions/ChangeColorMaterial_After2.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["CHANGE_MATERIALS_ELEMENT"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/ChangeColorMaterial_B1.webp",
        afterImg: "/assets/examples/ChangeColorMaterial_B2.webp",
      },
      {
        beforeImg: "/assets/examples/ChangeColorMaterial_A1.webp",
        afterImg: "/assets/examples/ChangeColorMaterial_A2.webp",
      },
    ],
  },
  BATHROOM_REMODELING: {
    title: SOLUTION_DISPLAY_MAPPING["BATHROOM_REMODELING"],
    description:
      "Redesign entire bathroom with the power of AI, instantly & realistically.",
    beforeImg: "/assets/images/solutions/UpgradeBath_Before.webp",
    afterImg: "/assets/images/solutions/UpgradeBath_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["BATHROOM_REMODELING"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/RemodelBath_B1.webp",
        afterImg: "/assets/examples/RemodelBath_B2.webp",
      },
      {
        beforeImg: "/assets/examples/RemodelBath_A1.webp",
        afterImg: "/assets/examples/RemodelBath_A2.webp",
      },
    ],
  },
  SURFACE_CHANGE_BATHROOM_WALL_TILE: {
    title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_BATHROOM_WALL_TILE"],
    description:
      "Efficient tile design solution for stunning bathroom makeovers",
    beforeImg: "/assets/images/solutions/BathTiles_Before.webp",
    afterImg: "/assets/images/solutions/BathTiles_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_BATHROOM_WALL_TILE"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/BathTiles_B1.webp",
        afterImg: "/assets/examples/BathTiles_B2.webp",
      },
      {
        beforeImg: "/assets/examples/BathTiles_A1.webp",
        afterImg: "/assets/examples/BathTiles_A2.webp",
      },
    ],
  },
  SURFACE_CHANGE_BATHROOM_CABINETS: {
    title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_BATHROOM_CABINETS"],
    description:
      "Tailor bathroom cabinets for maximum storage and style enhancement.",
    beforeImg: "/assets/images/solutions/BathCabinets_Before.webp",
    afterImg: "/assets/images/solutions/BathCabinets_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_BATHROOM_CABINETS"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/BathroomCabinets_B1.webp",
        afterImg: "/assets/examples/BathroomCabinets_B2.webp",
      },
      {
        beforeImg: "/assets/examples/BathroomCabinets_A1.webp",
        afterImg: "/assets/examples/BathroomCabinets_A2.webp",
      },
    ],
  },
  SURFACE_CHANGE_BATHROOM_COUNTERTOP: {
    title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_BATHROOM_COUNTERTOP"],
    description:
      "Visualize the perfect bathroom countertop in various materials such as wood, marble, and granite.",
    beforeImg: "/assets/images/solutions/BathCountertop_Before.webp",
    afterImg: "/assets/images/solutions/BathCountertop_After.webp",
    link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_BATHROOM_COUNTERTOP"]}`,
    example_imgs: [
      {
        beforeImg: "/assets/examples/BathroomCountertop_B1.webp",
        afterImg: "/assets/examples/BathroomCountertop_B2.webp",
      },
      {
        beforeImg: "/assets/examples/BathroomCountertop_A1.webp",
        afterImg: "/assets/examples/BathroomCountertop_A2.webp",
      },
    ],
  },
};

export const SOLUTION_GROUP_TITLE_MAPPING = {
  SURPRISE_ME: "Surprise me",

  VIRTUAL_STAGING_EMPTY_ROOM: "AI Virtual Staging",
  VIRTUAL_STAGING_FURNISHED_ROOM: "AI Virtual Staging",

  AI_INTERIOR_DESIGN: "AI Interior Designer",

  SURFACE_CHANGE_WALL: "AI Virtual Renovation",
  SURFACE_CHANGE_CEILING: "AI Virtual Renovation",
  SURFACE_CHANGE_FLOOR: "AI Virtual Renovation",

  LAWN_ENHANCEMENT: "AI Exterior Enhancement",
  SKY_ENHANCEMENT: "AI Exterior Enhancement",
  POOL_WATER_ENHANCEMENT: "AI Exterior Enhancement",
  CHANGE_PATHWAYS_WALKWAYS: "AI Exterior Enhancement",

  CHANGE_MATERIALS_ELEMENT: "AI Color & Material Editor",

  LANDSCAPING: "AI Landscape Designer",

  REMOVE_OBJECT: "AI Space Decluttering",
  OCCUPIED_TO_VACANT: "AI Space Decluttering",

  BATHROOM_REMODELING: "AI Bathroom Designer",
  SURFACE_CHANGE_BATHROOM_WALL_TILE: "AI Bathroom Designer",
  SURFACE_CHANGE_BATHROOM_CABINETS: "AI Bathroom Designer",
  SURFACE_CHANGE_BATHROOM_COUNTERTOP: "AI Bathroom Designer",

  KITCHEN_REMODELING: "AI Kitchen Designer",
  SURFACE_CHANGE_KITCHEN_COUNTERTOP: "AI Kitchen Designer",
  SURFACE_CHANGE_KITCHEN_CABINETS: "AI Kitchen Designer",
  SURFACE_CHANGE_KITCHEN_APPLIANCES: "AI Kitchen Designer",
  SURFACE_CHANGE_KITCHEN_BACKSPLASH: "AI Kitchen Designer",

  UNDER_CONSTRUCTION_INTERIOR: "AI Under Construction Property Visualizer",
  UNDER_CONSTRUCTION_EXTERIOR: "AI Under Construction Property Visualizer",
};

export const SOLUTIONS_CARDS_CONST = [
  {
    title: "AI Virtual Staging",
    children: [
      {
        title: SOLUTION_DISPLAY_MAPPING["VIRTUAL_STAGING_FURNISHED_ROOM"],
        description:
          "Make property more appealing and interesting to your buyers by converting cluttered rooms to inviting spaces.",
        beforeImg: "/assets/images/solutions/VSFurnished_Before.webp",
        afterImg: "/assets/images/solutions/VSFurnished_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["VIRTUAL_STAGING_FURNISHED_ROOM"]}`,
        popupDetails:
          SOLUTIONS_DETAIL_POPUP_CONST["VIRTUAL_STAGING_FURNISHED_ROOM"],
        iconName: "vsFurnishedPricing",
        tutorialLink: "https://www.youtube.com/embed/VGinz3j38jw?si=8dATGpQEeWtXnPCe&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["VIRTUAL_STAGING_EMPTY_ROOM"],
        description:
          "Help buyers fall in love with your listings by turning vacant rooms into stylish spaces.",
        beforeImg: "/assets/images/solutions/VSEmpty_Before.webp",
        afterImg: "/assets/images/solutions/VSEmpty_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["VIRTUAL_STAGING_EMPTY_ROOM"]}`,
        popupDetails:
          SOLUTIONS_DETAIL_POPUP_CONST["VIRTUAL_STAGING_EMPTY_ROOM"],
        iconName: "vsEmptyRoomPricing",
        tutorialLink: "https://www.youtube.com/embed/BgmwMNbonzE?si=ds5VGTHzBjYRCPfl&autoplay=1&rel=0",
      },
    ],
  },
  {
    title: "AI Virtual Renovation",
    children: [
      {
        title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_WALL"],
        description:
          "Attract more potential buyers with virtual room makeovers.",
        beforeImg: "/assets/images/solutions/ChangeWall_Before.webp",
        afterImg: "/assets/images/solutions/ChangeWall_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_WALL"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["SURFACE_CHANGE_WALL"],
        iconName: "changeWallPricing",
        tutorialLink: "https://www.youtube.com/embed/XKe3sm9b9Ug?si=Hg6xJEZi7qxDMEwX&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_CEILING"],
        description:
          "Unveil hidden potential of the space with custom ceiling makeover.",
        beforeImg: "/assets/images/solutions/ChangeCeiling_Before.webp",
        afterImg: "/assets/images/solutions/ChangeCeiling_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_CEILING"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["SURFACE_CHANGE_CEILING"],
        iconName: "changeCeilingPricing",
        tutorialLink: "https://www.youtube.com/embed/t79dPejIH2E?si=rkiEG_TUy2uSPDdw&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_FLOOR"],
        description:
          "Discover diverse flooring options, from hardwood to carpet, and achieve your desired look virtually.",
        beforeImg: "/assets/images/solutions/ChangeFloor_Before.webp",
        afterImg: "/assets/images/solutions/ChangeFloor_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_FLOOR"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["SURFACE_CHANGE_FLOOR"],
        iconName: "changeFloorPricing",
        tutorialLink: "https://www.youtube.com/embed/9ps-l_HTKHE?si=m0-VHmmIfqc43eBl&autoplay=1&rel=0",
      },
    ],
  },
  {
    title: "AI Space Decluttering",
    children: [
      {
        title: SOLUTION_DISPLAY_MAPPING["OCCUPIED_TO_VACANT"],
        description:
          "Remove cluttered furnishings from your photos and allow prospects to envision how the home could look.",
        beforeImg: "/assets/images/solutions/O2V_Before.webp",
        afterImg: "/assets/images/solutions/O2V_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["OCCUPIED_TO_VACANT"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["OCCUPIED_TO_VACANT"],
        iconName: "o2vPricing",
        tutorialLink: "https://www.youtube.com/embed/M5Beg8kzSgU?si=wjrkb5DQ-_IpUXDL&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["REMOVE_OBJECT"],
        description:
          "Instantly remove unwanted or distracting items from your property photos.",
        beforeImg: "/assets/images/solutions/RemoveObject_Before.webp",
        afterImg: "/assets/images/solutions/RemoveObject_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["REMOVE_OBJECT"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["REMOVE_OBJECT"],
        iconName: "removeObjPricing",
        tutorialLink: "https://www.youtube.com/embed/qxPUFfcw3yE?si=bLLKXt7zvvhA7wau&autoplay=1&rel=0",
      },
    ],
  },
  {
    title: "AI Exterior Enhancement",
    children: [
      {
        title: SOLUTION_DISPLAY_MAPPING["LAWN_ENHANCEMENT"],
        description:
          "Effortlessly enhance your property's outdoor spaces, boosting their visual appeal and overall value.",
        beforeImg: "/assets/examples/LawnEnhancement_B1.webp",
        afterImg: "/assets/examples/LawnEnhancement_B2.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["LAWN_ENHANCEMENT"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["LAWN_ENHANCEMENT"],
        iconName: "lawnEnhancePricing",
        tutorialLink: "https://www.youtube.com/embed/00nYJIbRqns?si=PN14eZEvRBXV4-LL&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["SKY_ENHANCEMENT"],
        description:
          "Transform your photos with stunning skies, enhancing their beauty and impact.",
        beforeImg: "/assets/images/solutions/ChangeSky_Before.webp",
        afterImg: "/assets/images/solutions/ChangeSky_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SKY_ENHANCEMENT"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["SKY_ENHANCEMENT"],
        iconName: "skyReplacePricing",
        tutorialLink: "https://www.youtube.com/embed/OaysPvuGy_E?si=_OVA4Y-MqaeOkPhw&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["POOL_WATER_ENHANCEMENT"],
        description:
          "Enhance pool photos with AI for crystal-clear water and greater appeal.",
        beforeImg: "/assets/examples/PoolWater_B1.webp",
        afterImg: "/assets/examples/PoolWater_B2.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["POOL_WATER_ENHANCEMENT"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["POOL_WATER_ENHANCEMENT"],
        iconName: "poolWaterEnhancePricing",
        tutorialLink: "https://www.youtube.com/embed/oVUZCS-51AE?si=zLhIpRc6TLtWgREX&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["CHANGE_PATHWAYS_WALKWAYS"],
        description:
          "Give outdoor spaces a fresh look by redesigning the pathways & walkways.",
        beforeImg: "/assets/images/solutions/ChangePath_Before.webp",
        afterImg: "/assets/images/solutions/ChangePath_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["CHANGE_PATHWAYS_WALKWAYS"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["CHANGE_PATHWAYS_WALKWAYS"],
        iconName: "redesignPathwayPricing",
        tutorialLink: "https://www.youtube.com/embed/zTsDRDdr00U?si=68frx-aZvBBVzOBy&autoplay=1&rel=0",
      },
    ],
  },
  {
    title: "AI Under Construction Property Visualizer",
    children: [
      {
        title: SOLUTION_DISPLAY_MAPPING["UNDER_CONSTRUCTION_INTERIOR"],
        description:
          "Preview & customize interiors for under-construction properties, ensuring satisfaction and confidence in your investment.",
        beforeImg: "/assets/images/solutions/underconstructionINT_Before.webp",
        afterImg: "/assets/images/solutions/underconstructionINT_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["UNDER_CONSTRUCTION_INTERIOR"]}`,
        popupDetails:
          SOLUTIONS_DETAIL_POPUP_CONST["UNDER_CONSTRUCTION_INTERIOR"],
        iconName: "underConstructIntPricing",
        tutorialLink: "https://www.youtube.com/embed/k-P-7F5r7G0?si=ZHmp0DgYcYXWiBdt&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["UNDER_CONSTRUCTION_EXTERIOR"],
        description:
          "Transform the vision of your under-construction property's exterior and enhance its future curb appeal.",
        beforeImg: "/assets/images/solutions/underconstructionEXT_Before.webp",
        afterImg: "/assets/images/solutions/underconstructionEXT_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["UNDER_CONSTRUCTION_EXTERIOR"]}`,
        popupDetails:
          SOLUTIONS_DETAIL_POPUP_CONST["UNDER_CONSTRUCTION_EXTERIOR"],
        iconName: "underConstructExtPricing",
        tutorialLink: "https://www.youtube.com/embed/Vw2aUO2VGDM?si=DMB9Zz_ec68eJ3G4&autoplay=1&rel=0",
      },
    ],
  },
  {
    title: "AI Kitchen Designer",
    children: [
      {
        title: SOLUTION_DISPLAY_MAPPING["KITCHEN_REMODELING"],
        description:
          "Virtually achieve dream kitchen design with ease, maximizing functionality and visual appeal.",
        beforeImg: "/assets/images/solutions/RemodelKitchen_Before.webp",
        afterImg: "/assets/images/solutions/RemodelKitchen_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["KITCHEN_REMODELING"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["KITCHEN_REMODELING"],
        iconName: "remodelKitcchenPricing",
        tutorialLink: "https://www.youtube.com/embed/7t2XtsWxmqg?si=DVgkJF0P5--4j_7i&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_KITCHEN_COUNTERTOP"],
        description:
          "Visualize ideal kitchen countertop in various materials like wood, marble, and granite.",
        beforeImg: "/assets/images/solutions/KitchenCountertop_Before.webp",
        afterImg: "/assets/images/solutions/KitchenCountertop_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_KITCHEN_COUNTERTOP"]}`,
        popupDetails:
          SOLUTIONS_DETAIL_POPUP_CONST["SURFACE_CHANGE_KITCHEN_COUNTERTOP"],
        iconName: "kitchenCounterTopPricing",
        tutorialLink: "https://www.youtube.com/embed/OsW-09DZUA8?si=vlFxm5DHnnY-RVWr&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_KITCHEN_CABINETS"],
        description:
          "Enhance kitchen with virtually customized cabinets for increased storage and visual appeal.",
        beforeImg: "/assets/images/solutions/KitchenCabinets_Before.webp",
        afterImg: "/assets/images/solutions/KitchenCabinets_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_KITCHEN_CABINETS"]}`,
        popupDetails:
          SOLUTIONS_DETAIL_POPUP_CONST["SURFACE_CHANGE_KITCHEN_CABINETS"],
        iconName: "upgradeKitchenCabinetsPricing",
        tutorialLink: "https://www.youtube.com/embed/s07vY0I6A4A?si=Qrk65NHq5qcU_R_9&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_KITCHEN_APPLIANCES"],
        description:
          "Transform kitchen with smart appliance choices for convenience, efficiency, and modern living.",
        beforeImg: "/assets/images/solutions/KitchenAppliance_Before.webp",
        afterImg: "/assets/images/solutions/KitchenAppliance_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_KITCHEN_APPLIANCES"]}`,
        popupDetails:
          SOLUTIONS_DETAIL_POPUP_CONST["SURFACE_CHANGE_KITCHEN_APPLIANCES"],
        iconName: "kitchenAppliancePricing",
        tutorialLink: "https://www.youtube.com/embed/eKaACM8keJ4?si=hho4C5oyiHSIrsih&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_KITCHEN_BACKSPLASH"],
        description:
          "Transform kitchen with the perfect backsplash design, enhancing style and functionality.",
        beforeImg: "/assets/images/solutions/KitchenBacksplash_Before.webp",
        afterImg: "/assets/images/solutions/KitchenBacksplash_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_KITCHEN_BACKSPLASH"]}`,
        popupDetails:
          SOLUTIONS_DETAIL_POPUP_CONST["SURFACE_CHANGE_KITCHEN_BACKSPLASH"],
        iconName: "kitchenBacksplashPricing",
        tutorialLink: "https://www.youtube.com/embed/l9yKVu5-n2E?si=jf6iqijmgVKTxF2y&autoplay=1&rel=0",
      },
    ],
  },
  {
    title: "AI Bathroom Designer",
    children: [
      {
        title: SOLUTION_DISPLAY_MAPPING["BATHROOM_REMODELING"],
        description:
          "Redesign entire bathroom with the power of AI, instantly & realistically.",
        beforeImg: "/assets/images/solutions/UpgradeBath_Before.webp",
        afterImg: "/assets/images/solutions/UpgradeBath_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["BATHROOM_REMODELING"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["BATHROOM_REMODELING"],
        iconName: "bathRemodelPricing",
        tutorialLink: "https://www.youtube.com/embed/sEcn8nqURMU?si=Ye3XFpuJlGgnYNUX&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_BATHROOM_WALL_TILE"],
        description:
          "Efficient tile design solution for stunning bathroom makeovers",
        beforeImg: "/assets/images/solutions/BathTiles_Before.webp",
        afterImg: "/assets/images/solutions/BathTiles_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_BATHROOM_WALL_TILE"]}`,
        popupDetails:
          SOLUTIONS_DETAIL_POPUP_CONST["SURFACE_CHANGE_BATHROOM_WALL_TILE"],
        iconName: "upgradeWallTilesPricing",
        tutorialLink: "https://www.youtube.com/embed/F4_RcfJZh3Y?si=PDmlM_AGrO4my5R7&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_BATHROOM_CABINETS"],
        description:
          "Tailor bathroom cabinets for maximum storage and style enhancement.",
        beforeImg: "/assets/images/solutions/BathCabinets_Before.webp",
        afterImg: "/assets/images/solutions/BathCabinets_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_BATHROOM_CABINETS"]}`,
        popupDetails:
          SOLUTIONS_DETAIL_POPUP_CONST["SURFACE_CHANGE_BATHROOM_CABINETS"],
        iconName: "bathCabinetsPricing",
        tutorialLink: "https://www.youtube.com/embed/nMLFpZZASnk?si=I-GjwO7DilmvKdBm&autoplay=1&rel=0",
      },
      {
        title: SOLUTION_DISPLAY_MAPPING["SURFACE_CHANGE_BATHROOM_COUNTERTOP"],
        description:
          "Visualize the perfect bathroom countertop in various materials such as wood, marble, and granite.",
        beforeImg: "/assets/images/solutions/BathCountertop_Before.webp",
        afterImg: "/assets/images/solutions/BathCountertop_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["SURFACE_CHANGE_BATHROOM_COUNTERTOP"]}`,
        popupDetails:
          SOLUTIONS_DETAIL_POPUP_CONST["SURFACE_CHANGE_BATHROOM_COUNTERTOP"],
        iconName: "bathCounterTopPricing",
        tutorialLink: "https://www.youtube.com/embed/Y3QDQq3pVr8?si=hOfi8ijyrya-R8Y4&autoplay=1&rel=0",
      },
    ],
  },
  {
    title: "AI Color & Material Editor",
    children: [
      {
        title: SOLUTION_DISPLAY_MAPPING["CHANGE_MATERIALS_ELEMENT"],
        description:
          "Effortlessly customize furnishings like cushions, sofas, curtains, rugs, and more to achieve the desired look.",
        beforeImg: "/assets/images/solutions/ChangeColorMaterial_Before.webp",
        afterImg: "/assets/images/solutions/ChangeColorMaterial_After2.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["CHANGE_MATERIALS_ELEMENT"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["CHANGE_MATERIALS_ELEMENT"],
        iconName: "changeMaterialsPricing",
        tutorialLink: "https://www.youtube.com/embed/u1RFCWQ3PvM?si=3ZlTHlPdtkQcKm-M&autoplay=1&rel=0",
      },
    ],
  },
  {
    title: "AI Interior Designer",
    children: [
      {
        title: SOLUTION_DISPLAY_MAPPING["AI_INTERIOR_DESIGN"],
        description:
          "Instantly access customized design ideas, eliminating the need for time-consuming searches and streamlining creative process.",
        beforeImg: "/assets/images/solutions/AI_InteriorDesign_Before.webp",
        afterImg: "/assets/images/solutions/AI_InteriorDesign_After.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["AI_INTERIOR_DESIGN"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["AI_INTERIOR_DESIGN"],
        iconName: "aiIntDesignPricing",
        tutorialLink: "https://www.youtube.com/embed/RQvsRj70SXU?si=W8lBFizJlPAPeDpB&autoplay=1&rel=0",
      },
    ],
  },
  {
    title: "AI Landscape Designer",
    children: [
      {
        title: SOLUTION_DISPLAY_MAPPING["LANDSCAPING"],
        description:
          "Generate diverse designs to impress and guide clients, streamlining project execution for landscapers.",
        beforeImg: "/assets/images/solutions/Landscaping_Before.webp",
        afterImg: "/assets/images/solutions/Landscaping_Aftre.webp",
        link: `/solutions/upload/${SOLUTION_SCHEMA_MAPPING["LANDSCAPING"]}`,
        popupDetails: SOLUTIONS_DETAIL_POPUP_CONST["LANDSCAPING"],
        iconName: "landscapingPricing",
        tutorialLink: "https://www.youtube.com/embed/gSR2B1sfPqI?si=4z6pCLLx-0PXz5sY&autoplay=1&rel=0",
      },
    ],
  },
];

export const HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK =
  "https://www.youtube.com/embed/CbA5X5CyQr0?autoplay=0&mute=0";
export const HOW_TO_REDESIGN_IMAGE_YOUTUBE_LINK =
  "https://www.youtube.com/embed/mtgsuZY1DMw?si=2qbZTmMWDXXILepY";
export const HOW_TO_MODIFY_MASK_YOUTUBE_LINK =
  "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq";

export const BACKEND_URL = process.env.NEXT_PUBLIC_ENV !== "prod" ? "https://dev-api.reimaginehome.ai/v2" : "https://api.reimaginehome.ai/v2";

export const RE1_BACKEND_URL = "https://api.reimaginehome.ai";

export const errorCodeToMessage = {
  200: "Your request was successful.",
  400: "Your request was not successful due to missing or invalid parameters.",
  401: "Your token is invalid, expired, or not provided.",
  402: "Your Balance has been exhausted.",
  403: "Your request parameters were valid but don't have adequate permission for the request you are trying to make.",
  404: "Something went wrong. E-code(ANF)",
  405: "The requested HTTP not allowed for the given resource.",
  409: "User/organization already exists.",
  422: "Invalid data type.",
  500: "The request was not successful due to server errors.",
};

export const CDN_URL = "https://cdn-2.reimaginehome.ai/";

export const MAIN_DOMAIN = "https://www.reimaginehome.ai";

export const IMAGE_PREVIEW_SPACE_TYPE = [
  // exterior
  { label: "Backyard", value: "backyard" },
  { label: "Garden/Landscaping", value: "garden or landscaping" },
  { label: "Outdoor Living", value: "outdoor living" },
  { label: "Poolside", value: "swimming pool" },
  { label: "Deck/Patio", value: "deck or patio" },
  // interior
  { label: "Bedroom", value: "bedroom" },
  {
    label: "Living Room/Family Room/Lounge",
    value: "living room or family room or lounge",
  },
  { label: "Kids Room", value: "kids room" },
  { label: "Nursery", value: "nursery" },
  { label: "Kitchen", value: "kitchen" },
  { label: "Bathroom/Ensuite", value: "bathroom or ensuite" },
  { label: "Dining", value: "dining" },
  { label: "Foyer", value: "foyer" },
  { label: "Game Room/Rumpus Room", value: "game room or rumpus room" },
  { label: "Hobby Room/Craft Room", value: "hobby room or craft room" },
  { label: "Bar", value: "bar" },
  { label: "Laundry", value: "laundry" },
  { label: "Media Room", value: "media room" },
  { label: "Pantry", value: "pantry" },
  { label: "Single Room Studio/Unit", value: "single room studio" },
  { label: "Study", value: "study" },
  { label: "Sunroom", value: "sunroom" },
];

export const ONDROPERRORCODES = {
  "too-many-files":
    "Multiple images are not allowed. Please upload 1-image at a time.",
  "file-invalid-type":
    "The file format is invalid. Please upload a file with allowed formats, which are jpg, jpeg, heic, heif and png.",
  "file-too-large":
    "File size is exceeding. Please upload an image with size less than 25MB.",
};

// Example of solution schema
export const SOLUTIONS_initialState = {
  page_transition: {
    dp: { generation: false },
    mask: { save: false },
    results: { generation: false },
    view_results: { variations: false, redesign: false },
  },
  isLoading: false,
  projectName: "my test project",
  loading: false,
  data: {
    initial_screen: "mask_screen", //mask_screen/dp_screen
    solution_name: "VIRTUAL STAGE EMPTY ROOM",
    solution_id: "VIRTUAL_STAGE_EMPTY_ROOM",
    projectName: "my test project",
    space_type: "",
    img: "https://magicstore.styldod.com/REimagine-assets/SampleImages/sam-interior.png",
    masked_url:
      "https://magicstore.styldod.com/REimagine-images/sd/a11ae9b9-bab5-4246-a450-0031b489f315.png",
    mask_arr: [],
    npy_url:
      "https://magicstore.styldod.com/REimagine-dev/SAM/6b7096e6-0fa3-493b-b7bc-696524ff4741.npy",
    get_static_mask: false,
    enable_mask_overlay: false,
    pre_fetch_mask: true,
    is_masking_enabled: true,
    results: [
      "https://magicstore.styldod.com/REimagine-assets/SampleImages/sam-interior.png",
      "https://magicstore.styldod.com/REimagine-assets/SampleImages/sam-interior.png",
      "https://magicstore.styldod.com/REimagine-assets/SampleImages/sam-interior.png",
    ],
    side_panel_enabled: true,
    side_panel_schema: {
      room_type: {
        type: "dropdown",
        label: "Uploaded image is of",
        name: "room type",
        path: "room_type",
        value: "",
        temp_value: "",
        info_enabled: true,
        info_txt: "you need to select the following room type",
        options: [
          { value: "bed", label: "bed" },
          { value: "bath", label: "bath" },
          { value: "living", label: "living" },
          { value: "dine", label: "dine" },
        ],
        dependent_on: "",
        required: true,
        error_msg: "Please select space type",
      },
      material_type: {
        type: "dropdown",
        label: "Select material",
        name: "material",
        path: "material_type",
        value: "",
        temp_value: "",
        info_enabled: false,
        info_txt: "you need to select the following material type",
        options: [
          { value: "wood", label: "Wood" },
          { value: "marbale", label: "marbale" },
          { value: "tile", label: "tile" },
          { value: "carpet", label: "carpet" },
        ],
        dependent_on: "",
        required: true,
        error_msg: "Please select material",
      },
      material_subtype: {
        type: "dependentdropdown",
        labels: {
          wood: "please select wood",
          tile: "please select tile",
          marbale: "please select marbale",
          carpet: "please select carpet",
        },
        name: "material",
        path: "material_subtype",
        value: "",
        temp_value: "",
        info_enabled: true,
        info_txt: "you need to select the following material type",
        options: {
          wood: [
            { value: "wood 1", label: "wood 1" },
            { value: "wood 2", label: "wood 2" },
            { value: "wood 3", label: "wood 3" },
          ],
          marbale: [
            { value: "marbale 1", label: "marbale 1" },
            { value: "marbale 2", label: "marbale 2" },
            { value: "marbale 3", label: "marbale 3" },
          ],
          tile: [
            { value: "tile 1", label: "tile 1" },
            { value: "tile 2", label: "tile 2" },
            { value: "tile 3", label: "tile 3" },
          ],
          carpet: [
            { value: "carpet 1", label: "carpet 1" },
            { value: "carpet 2", label: "carpet 2" },
            { value: "carpet 3", label: "carpet 3" },
          ],
        },
        dependent_on: "material_type",
        required: true,
        error_msg: {
          wood: "please select wood",
          tile: "please select tile",
          marbale: "please select marbale",
        },
      },
      countertop_type: {
        type: "dropdown",
        label: "Select countertop material",
        name: "countertop",
        path: "countertop_type",
        value: "",
        temp_value: "",
        info_enabled: true,
        info_txt: "you need to select the following countertop type",
        options: [
          {
            value: "quartz",
            label: "quartz",
          },
          {
            value: "marbale",
            label: "marbale",
          },
          {
            value: "Butcher",
            label: "Butcher",
          },
          {
            value: "Granite",
            label: "Granite",
          },
        ],
        dependent_on: "",
        required: true,
        error_msg: "Please select countertop material",
      },
      countertop_subtype: {
        type: "dependentdropdown",
        labels: {
          Butcher: "please select Butcher",
          quartz: "please select quartz",
          marbale: "please select marbale",
          Granite: "please select Granite",
        },
        name: "countertop subtype",
        path: "countertop_subtype",
        value: "",
        temp_value: "",
        info_enabled: true,
        info_txt: "you need to select the following counter top subtype",
        options: {
          quartz: [
            {
              value: "quartz 1",
              label: "quartz 1",
            },
            {
              value: "quartz 2",
              label: "quartz 2",
            },
            {
              value: "quartz 3",
              label: "quartz 3",
            },
          ],
          marbale: [
            {
              value: "marbale 1",
              label: "marbale 1",
            },
            {
              value: "marbale 2",
              label: "marbale 2",
            },
            {
              value: "marbale 3",
              label: "marbale 3",
            },
          ],
          Butcher: [
            {
              value: "Butcher 1",
              label: "Butcher 1",
            },
            {
              value: "Butcher 2",
              label: "Butcher 2",
            },
            {
              value: "Butcher 3",
              label: "Butcher 3",
            },
          ],
          Granite: [
            {
              value: "Granite 1",
              label: "Granite 1",
            },
            {
              value: "Granite 2",
              label: "Granite 2",
            },
            {
              value: "Granite 3",
              label: "Granite 3",
            },
          ],
        },
        dependent_on: "countertop_type",
        required: true,
        error_msg: {
          Butcher: "please select Butcher",
          quartz: "please select quartz",
          marbale: "please select marbale",
          Granite: "please select Granite",
        },
      },
      wall_change_type: {
        type: "dropdown",
        label: "Select wall type",
        name: "wall change type",
        path: "wall_change_type",
        value: "",
        temp_value: "",
        info_enabled: true,
        info_txt: "you need to select the following wall type",
        options: [
          {
            value: "wall",
            label: "wall",
          },
          {
            value: "wall paper",
            label: "wall paper",
          },
        ],
        dependent_on: "",
        required: true,
        error_msg: "Please select wall type",
      },
      wall_change_subtype: {
        type: "dependentpicker",
        labels: {
          wall: "please select color",
          "wall paper": "please select pattern",
        },
        name: "wall change subtype",
        path: "wall_change_subtype",
        value: "",
        temp_value: "",
        info_enabled: true,
        info_txt: "you need to select the following counter top picker",
        options: {
          wall: [
            {
              label: "Modern",
              value: "Modern",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Contemporary",
              value: "Contemporary",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Glam",
              value: "Glam",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Scandinavian",
              value: "Scandinavian",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Farmhouse",
              value: "Farmhouse",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
          ],
          "wall paper": [
            {
              label: "Modern",
              value: "Modern",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Contemporary",
              value: "Contemporary",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Glam",
              value: "Glam",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Scandinavian",
              value: "Scandinavian",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Farmhouse",
              value: "Farmhouse",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Modern",
              value: "Modern",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Contemporary",
              value: "Contemporary",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Glam",
              value: "Glam",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Scandinavian",
              value: "Scandinavian",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
            {
              label: "Farmhouse",
              value: "Farmhouse",
              img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
            },
          ],
        },
        dependent_on: "wall_change_type",
        required: true,
        error_msg: {
          wall: "please select color",
          "wall paper": "please select pattern",
        },
      },
      theme_type: {
        type: "picker",
        label: "Select theme",
        name: "theme",
        path: "theme_type",
        value: "",
        temp_value: "",
        info_enabled: true,
        info_txt: "you need to select the following theme type",
        options: [
          {
            label: "Modern",
            value: "Modern",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Contemporary",
            value: "Contemporary",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Glam",
            value: "Glam",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Scandinavian",
            value: "Scandinavian",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Farmhouse",
            value: "Farmhouse",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Modern",
            value: "Modern",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Contemporary",
            value: "Contemporary",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Glam",
            value: "Glam",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Scandinavian",
            value: "Scandinavian",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Farmhouse",
            value: "Farmhouse",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Modern",
            value: "Modern",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Contemporary",
            value: "Contemporary",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Glam",
            value: "Glam",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Scandinavian",
            value: "Scandinavian",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
          {
            label: "Farmhouse",
            value: "Farmhouse",
            img: "https://cdn.styldod.com/feassets/webflow/imagenew/Covernew/Farmhouse.jpg",
          },
        ],
        dependent_on: "",
        required: true,
        error_msg: "Please select theme",
      },
      color_type: {
        type: "picker_with_string",
        label: "Select color",
        name: "color",
        path: "color_type",
        value: "",
        temp_value: "",
        info_enabled: true,
        info_txt: "you need to select the following color type",
        options: [
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
          {
            label: "Blue, green, purple, burgundy, cyan",
            value: "Blue, green, purple, burgundy, cyan",
            colorArr: ["#FEA7A7", "#2E6B75", "#92C3BE", "#EFF6F9", "#F9DED4"],
          },
        ],
        dependent_on: "",
        required: true,
        error_msg: "Please select color",
      },
      other_instructions: {
        type: "string",
        label: "Other instructions",
        name: "other instructions",
        path: "other_instructions",
        value: "",
        temp_value: "",
        info_enabled: false,
        info_txt: "",
        dependent_on: "",
        required: false,
        error_msg: "Please enter Other instructions",
      },
    },
    dp_screen: {
      component: "withpanel",
      helper_text: "Masked objects in the image will be removed.",
      cta_text: "Vacant the room",
      edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
      mask_mode: true,
    },
    mask_screen: {
      component: "sam",
    },
    results_screen: {
      component: "withpanel",
      helper_text:
        "Click on the generated images to view, share, download or modify.",
      cta_text: "Retry",
      cta_helper_text: "Didn't like the outputs?",
    },
  },
  error: null,
};

export const SOLUTIONS_SCHEMA = {
  // SAM
  "remove-unwanted-objects": SOLUTIONS_MASTER_SCHEMA.REMOVE_OBJECT,
  landscaping: SOLUTIONS_MASTER_SCHEMA.LANDSCAPING,
  "lawn-enhancement": SOLUTIONS_MASTER_SCHEMA.LAWN_ENHANCEMENT,
  "sky-replacement": SOLUTIONS_MASTER_SCHEMA.SKY_ENHANCEMENT,
  "pool-renovation": SOLUTIONS_MASTER_SCHEMA.POOL_WATER_ENHANCEMENT,
  "change-pathways": SOLUTIONS_MASTER_SCHEMA.CHANGE_PATHWAYS_WALKWAYS,
  "change-wall-color": SOLUTIONS_MASTER_SCHEMA.SURFACE_CHANGE_WALL,
  "upgrade-kitchen-cabinets":
    SOLUTIONS_MASTER_SCHEMA.SURFACE_CHANGE_KITCHEN_CABINETS,
  "change-ceiling": SOLUTIONS_MASTER_SCHEMA.SURFACE_CHANGE_CEILING,
  "change-floor": SOLUTIONS_MASTER_SCHEMA.SURFACE_CHANGE_FLOOR,
  "change-material-color-of-furnishing-elements":
    SOLUTIONS_MASTER_SCHEMA.CHANGE_MATERIALS_ELEMENT,
  "upgrade-kitchen-countertop":
    SOLUTIONS_MASTER_SCHEMA.SURFACE_CHANGE_KITCHEN_COUNTERTOP,
  "upgrade-kitchen-appliances":
    SOLUTIONS_MASTER_SCHEMA.SURFACE_CHANGE_KITCHEN_APPLIANCES,
  "upgrade-kitchen-backsplash":
    SOLUTIONS_MASTER_SCHEMA.SURFACE_CHANGE_KITCHEN_BACKSPLASH,
  "upgrade-bathroom-wall-tiles":
    SOLUTIONS_MASTER_SCHEMA.SURFACE_CHANGE_BATHROOM_WALL_TILE,
  "upgrade-bathroom-cabinets":
    SOLUTIONS_MASTER_SCHEMA.SURFACE_CHANGE_BATHROOM_CABINETS,
  "upgrade-bathroom-countertop":
    SOLUTIONS_MASTER_SCHEMA.SURFACE_CHANGE_BATHROOM_COUNTERTOP,

  // ADE
  "virtually-stage-empty-room":
    SOLUTIONS_MASTER_SCHEMA.VIRTUAL_STAGING_EMPTY_ROOM,
  "occupied-to-vacant": SOLUTIONS_MASTER_SCHEMA.OCCUPIED_TO_VACANT,
  "virtually-stage-furnished-room":
    SOLUTIONS_MASTER_SCHEMA.VIRTUAL_STAGING_FURNISHED_ROOM,
  "redesign-under-construction-home-interior":
    SOLUTIONS_MASTER_SCHEMA.UNDER_CONSTRUCTION_INTERIOR,
  "redesign-under-construction-home-exterior":
    SOLUTIONS_MASTER_SCHEMA.UNDER_CONSTRUCTION_EXTERIOR,
  "surprise-me": SOLUTIONS_MASTER_SCHEMA.SUPRISE_ME,
  "ai-interior-design": SOLUTIONS_MASTER_SCHEMA.AI_INTERIOR_DESIGN,
  "remodel-kitchen": SOLUTIONS_MASTER_SCHEMA.KITCHEN_REMODELING,
  "remodel-bathroom": SOLUTIONS_MASTER_SCHEMA.BATHROOM_REMODELING,
  "empty-the-space": SOLUTIONS_MASTER_SCHEMA.EMPTY_THE_SPACE,
  "furnish-empty-room": SOLUTIONS_MASTER_SCHEMA.FURNISH_EMPTY_ROOM,
  "redesign-furnished-room": SOLUTIONS_MASTER_SCHEMA.REDESIGN_FURNISHED_ROOM,

  "virtual-staging": SOLUTIONS_MASTER_SCHEMA.VIRTUAL_STAGING,
  "redesign-furnished-rooms": SOLUTIONS_MASTER_SCHEMA.REDESIGN_FURNISHED_ROOMS,
  "empty-your-space": SOLUTIONS_MASTER_SCHEMA.EMPTY_YOUR_SPACE,
  "ai-landscaping": SOLUTIONS_MASTER_SCHEMA.AI_LANDSCAPING,
  "render-exterior-structures": SOLUTIONS_MASTER_SCHEMA.RENDER_EXTERIOR_STRUCTURES,
};

export const NO_MASK_SEG_LABELS_INTERIOR = [
  "wall",
  "floor",
  "ceiling",
  "fireplace",
  "windowpane",
  "stairway",
  "step",
  "stairs",
  "door",
  "railing;rail",
  "wardrobe",
  "refrigerator",
  "hood",
  "microwave",
  "countertop",
  "kitchen",
  "stove",
  "dishwasher",
  "sink",
  "oven",
  "curtain",
  "blind",
  "fan", // for o2v
  "light", // for o2v
  "chandelier", // for o2v
];

export const NO_MASK_SEG_LABELS_EXTERIOR = [
  "building",
  "canopy",
  "column",
  "door",
  "escalator",
  "fence",
  "house",
  "hovel",
  "mountain",
  "palm",
  "pole",
  "railing",
  "road",
  "sconce",
  "sidewalk",
  "sky",
  "stairs",
  "stairway",
  "step",
  "streetlight",
  "swimming",
  "tree",
  "wall",
  "water",
  "windowpane",
];

export const INTERIOR_SPACE_TYPES = [
  "bar",
  "bathroom",
  "bedroom",
  "dining",
  "foyer",
  "games area/rumpus room",
  "hobby/craft room",
  "kids room",
  "kitchen",
  "laundry",
  "living room",
  "media room",
  "nursery",
  "pantry",
  "single room studio",
  "study",
  "sunroom",
  "youthful bedroom",
  "children room"
];

export const EXTERIOR_SPACE_TYPES = [
  "backyard",
  "garden",
  "outdoor_living",
  "poolside",
  "deck_patio",
];

export const GENERATION_FETCH_TIMEOUT = 300000;
export const MASK_FETCH_TIMEOUT = 300000;

export const INTERIOR_DESIGN_STYLES = [
  "bohemian",
  "coastal",
  "contemporary",
  "farmhouse",
  "french Country",
  "glam",
  "industrial",
  "japandi",
  "mid-Century Modern",
  "minimal",
  "modern",
  "rustic",
  "scandinavian",
  "traditional",
  "transitional",
];
export const COLOR_PREFERENCE_INTERIOR = [
  "turquoise, cobalt, white",
  "beige, green, gray",
  "blue, green, gray",
  "creamy white, brown, green",
  "indigo, gray, white",
  "orange, blue, white",
  "beige, gray, white",
  "silver, royal blue, white",
  "golden, royal blue, white",
  "earthy greens, yellow, white",
  "earthy greens, yellow, gray",
  "yellow, blue, white",
  "camel brown, beige, white",
  "pink, white, gray",
  "orange, gray, white",
  "purple, yellow, gray",
  "coral, green, white",
  "teal, yellow, gray",
  "dark teal, yellow, gray",
  "earth tone, olive green, gray",
  "olive green, gray, white",
  "red, brown, gray",
  "black, white, gray",
  "black, orange, white",
  "moss green, tan color, white",
  "blue, sand color, gray",
  "hunter green color, red, dark gray",
  "blue, grass green, gay",
  "blue, taupe, gray",
  "forest green, light gray, white",
  "pink, green, golden",
  "yellow, gray, white",
  "turquoise, sand color, white",
  "royal purple, gray, white",
  "pale green, purple sapphire, white",
  "cantaloupe, blush, gray",
  "lime green, orange, white",
  "inca gold, scarlet sage, gray",
  "pale lilac, lime green, gray",
  "living coral, forest biome, gray",
  "blazing orange, yellow cream, buff orange",
  "sagebrush green, marsala, gray",
  "light purple, light blue, light green",
  "radiant yellow, living coral, purple",
  "crimson, raspberry, gray",
  "living coral, spiced apple, peach",
  "lemon verbena, orange pop, aurora pink",
  "tanager turquoise, teal blue, kelly green",
  "windsor wine, scarlet sage, bright red",
  "puffin's bill, green, brown",
  "vanilla custard, goldfinch, scarlet sage",
  "northern sky, baby blue, coffee",
  "delphinium blue, atmosphere, fiery coral",
  "soft pink, peach amber, yucca",
  "soft pink, peach amber, arbor green",
  "purple, lilac, aubergine gleam",
  "blue sky, nugget, celestial",
  "desert sand, burnished brown, burgundy",
  "desert sand, burnished brown, mystic",
  "celadon, medium champagne, honey yellow",
  "sapphire, light slate gray, american silver",
];

export const FABRIC_PREFERENCE_INTERIOR = [
  "floral",
  "stripes",
  "brocade",
  "checkered",
  "chevron",
  "chinoiserie",
  "chintz",
  "damask",
  "ditsy",
  "geometric",
  "gingham",
  "greek key",
  "harlequin",
  "herringbone",
  "ikat",
  "jacquard",
  "medallion",
  "paisley",
  "polka dot",
  "tartan plaid",
  "toile",
  "trellis",
  "fleur-de-lis",
  "ogee",
];

export const EXTERIOR_DESIGN_STYLES = [
  "resort-style landscaping and pool",
  "handy greenhouse and vegetable garden",
  "hip california garden landscaping",
  "mediterranean garden landscaping",
  "party-ready outdoor space with pool, spa, and fire feature",
  "cozy corner with fire pit and seating",
  "charming playhouse garden landscaping",
  "elevated deck with garden",
  "cottage garden's colorful planting palette",
  "succulent garden",
  "moss garden",
  "round swimming pool with lawn and pool house",
  "garden landscaping with gravel landscaping",
  "lagoon pool features hot tub, table and seating area",
  "outdoor dining and sitting area",
];



export const FE_MAIN_DOMAIN = "https://reih2-0-ad.webflow.io";

export const COUNTRY_NAMES = [
  {
    value: "AF",
    text: "Afghanistan",
  },
  {
    value: "AL",
    text: "Albania",
  },
  {
    value: "DZ",
    text: "Algeria",
  },
  {
    value: "AD",
    text: "Andorra",
  },
  {
    value: "AO",
    text: "Angola",
  },
  {
    value: "AR",
    text: "Argentina",
  },
  {
    value: "AM",
    text: "Armenia",
  },
  {
    value: "AW",
    text: "Aruba",
  },
  {
    value: "AU",
    text: "Australia",
  },
  {
    value: "AT",
    text: "Austria",
  },
  {
    value: "AZ",
    text: "Azerbaijan",
  },
  {
    value: "BH",
    text: "Bahrain",
  },
  {
    value: "BD",
    text: "Bangladesh",
  },
  {
    value: "BY",
    text: "Belarus",
  },
  {
    value: "BE",
    text: "Belgium",
  },
  {
    value: "BZ",
    text: "Belize",
  },
  {
    value: "BJ",
    text: "Benin",
  },
  {
    value: "BT",
    text: "Bhutan",
  },
  {
    value: "BO",
    text: "Bolivia",
  },
  {
    value: "BA",
    text: "Bosnia And Herzegovina",
  },
  {
    value: "BW",
    text: "Botswana",
  },
  {
    value: "BR",
    text: "Brazil",
  },
  {
    value: "IO",
    text: "British Indian Ocean Territory",
  },
  {
    value: "BN",
    text: "Brunei Darussalam",
  },
  {
    value: "BG",
    text: "Bulgaria",
  },
  {
    value: "BF",
    text: "Burkina Faso",
  },
  {
    value: "BI",
    text: "Burundi",
  },
  {
    value: "KH",
    group: "C",
    text: "Cambodia",
  },
  {
    value: "CM",
    group: "C",
    text: "Cameroon",
  },
  {
    value: "CV",
    group: "C",
    text: "Cape Verde",
  },
  {
    value: "CF",
    group: "C",
    text: "Central African Republic",
  },
  {
    value: "TD",
    group: "C",
    text: "Chad",
  },
  {
    value: "CL",
    group: "C",
    text: "Chile",
  },
  {
    value: "CN",
    group: "C",
    text: "China",
  },
  {
    value: "CO",
    group: "C",
    text: "Colombia",
  },
  {
    value: "KM",
    group: "C",
    text: "Comoros",
  },
  {
    value: "CG",
    group: "C",
    text: "Congo",
  },
  {
    value: "CD",
    group: "C",
    text: "Congo, Democratic Republic",
  },
  {
    value: "CK",
    group: "C",
    text: "Cook Islands",
  },
  {
    value: "CR",
    group: "C",
    text: "Costa Rica",
  },
  {
    value: "CI",
    group: "C",
    text: "Cote D'Ivoire",
  },
  {
    value: "HR",
    group: "C",
    text: "Croatia",
  },
  {
    value: "CU",
    group: "C",
    text: "Cuba",
  },
  {
    value: "CY",
    group: "C",
    text: "Cyprus",
  },
  {
    value: "CZ",
    group: "C",
    text: "Czech Republic",
  },
  {
    value: "DK",
    group: "D",
    text: "Denmark",
  },
  {
    value: "DJ",
    group: "D",
    text: "Djibouti",
  },
  {
    value: "EC",
    group: "E",
    text: "Ecuador",
  },
  {
    value: "EG",
    group: "E",
    text: "Egypt",
  },
  {
    value: "SV",
    group: "E",
    text: "El Salvador",
  },
  {
    value: "GQ",
    group: "E",
    text: "Equatorial Guinea",
  },
  {
    value: "ER",
    group: "E",
    text: "Eritrea",
  },
  {
    value: "EE",
    group: "E",
    text: "Estonia",
  },
  {
    value: "ET",
    group: "E",
    text: "Ethiopia",
  },
  {
    value: "FK",
    group: "F",
    text: "Falkland Islands (Malvinas)",
  },
  {
    value: "FO",
    group: "F",
    text: "Faroe Islands",
  },
  {
    value: "FJ",
    group: "F",
    text: "Fiji",
  },
  {
    value: "FI",
    group: "F",
    text: "Finland",
  },
  {
    value: "FR",
    group: "F",
    text: "France",
  },
  {
    value: "PF",
    group: "F",
    text: "French Polynesia",
  },
  {
    value: "GA",
    group: "G",
    text: "Gabon",
  },
  {
    value: "GM",
    group: "G",
    text: "Gambia",
  },
  {
    value: "GE",
    group: "G",
    text: "Georgia",
  },
  {
    value: "DE",
    group: "G",
    text: "Germany",
  },
  {
    value: "GH",
    group: "G",
    text: "Ghana",
  },
  {
    value: "GI",
    group: "G",
    text: "Gibraltar",
  },
  {
    value: "GR",
    group: "G",
    text: "Greece",
  },
  {
    value: "GL",
    group: "G",
    text: "Greenland",
  },
  {
    value: "GT",
    group: "G",
    text: "Guatemala",
  },
  {
    value: "GN",
    group: "G",
    text: "Guinea",
  },
  {
    value: "GW",
    group: "G",
    text: "Guinea-Bissau",
  },
  {
    value: "GY",
    group: "G",
    text: "Guyana",
  },
  {
    value: "HT",
    group: "H",
    text: "Haiti",
  },
  {
    value: "HN",
    group: "H",
    text: "Honduras",
  },
  {
    value: "HK",
    group: "H",
    text: "Hong Kong",
  },
  {
    value: "HU",
    group: "H",
    text: "Hungary",
  },
  {
    value: "IS",
    group: "I",
    text: "Iceland",
  },
  {
    value: "IN",
    group: "I",
    text: "India",
  },
  {
    value: "ID",
    group: "I",
    text: "Indonesia",
  },
  {
    value: "IR",
    group: "I",
    text: "Iran, Islamic Republic Of",
  },
  {
    value: "IQ",
    group: "I",
    text: "Iraq",
  },
  {
    value: "IE",
    group: "I",
    text: "Ireland",
  },
  {
    value: "IL",
    group: "I",
    text: "Israel",
  },
  {
    value: "IT",
    group: "I",
    text: "Italy",
  },
  {
    value: "JP",
    group: "J",
    text: "Japan",
  },
  {
    value: "JO",
    group: "J",
    text: "Jordan",
  },
  {
    value: "KP",
    group: "K",
    text: "KP",
  },
  {
    value: "KE",
    group: "K",
    text: "Kenya",
  },
  {
    value: "KI",
    group: "K",
    text: "Kiribati",
  },
  {
    value: "KR",
    group: "K",
    text: "Korea",
  },
  {
    value: "KW",
    group: "K",
    text: "Kuwait",
  },
  {
    value: "KG",
    group: "K",
    text: "Kyrgyzstan",
  },
  {
    value: "LA",
    group: "L",
    text: "Lao People's Democratic Republic",
  },
  {
    value: "LV",
    group: "L",
    text: "Latvia",
  },
  {
    value: "LB",
    group: "L",
    text: "Lebanon",
  },
  {
    value: "LS",
    group: "L",
    text: "Lesotho",
  },
  {
    value: "LR",
    group: "L",
    text: "Liberia",
  },
  {
    value: "LY",
    group: "L",
    text: "Libyan Arab Jamahiriya",
  },
  {
    value: "LI",
    group: "L",
    text: "Liechtenstein",
  },
  {
    value: "LT",
    group: "L",
    text: "Lithuania",
  },
  {
    value: "LU",
    group: "L",
    text: "Luxembourg",
  },
  {
    value: "MO",
    group: "M",
    text: "Macao",
  },
  {
    value: "MK",
    group: "M",
    text: "Macedonia",
  },
  {
    value: "MG",
    group: "M",
    text: "Madagascar",
  },
  {
    value: "MW",
    group: "M",
    text: "Malawi",
  },
  {
    value: "MY",
    group: "M",
    text: "Malaysia",
  },
  {
    value: "MV",
    group: "M",
    text: "Maldives",
  },
  {
    value: "ML",
    group: "M",
    text: "Mali",
  },
  {
    value: "MT",
    group: "M",
    text: "Malta",
  },
  {
    value: "MH",
    group: "M",
    text: "Marshall Islands",
  },
  {
    value: "MR",
    group: "M",
    text: "Mauritania",
  },
  {
    value: "MU",
    group: "M",
    text: "Mauritius",
  },
  {
    value: "MX",
    group: "M",
    text: "Mexico",
  },
  {
    value: "FM",
    group: "M",
    text: "Micronesia, Federated States Of",
  },
  {
    value: "MD",
    group: "M",
    text: "Moldova",
  },
  {
    value: "MC",
    group: "M",
    text: "Monaco",
  },
  {
    value: "MN",
    group: "M",
    text: "Mongolia",
  },
  {
    value: "ME",
    group: "M",
    text: "Montenegro",
  },
  {
    value: "MA",
    group: "M",
    text: "Morocco",
  },
  {
    value: "MZ",
    group: "M",
    text: "Mozambique",
  },
  {
    value: "NA",
    group: "N",
    text: "Namibia",
  },
  {
    value: "NP",
    group: "N",
    text: "Nepal",
  },
  {
    value: "NL",
    group: "N",
    text: "Netherlands",
  },
  {
    value: "NC",
    group: "N",
    text: "New Caledonia",
  },
  {
    value: "NZ",
    group: "N",
    text: "New Zealand",
  },
  {
    value: "NI",
    group: "N",
    text: "Nicaragua",
  },
  {
    value: "NE",
    group: "N",
    text: "Niger",
  },
  {
    value: "NG",
    group: "N",
    text: "Nigeria",
  },
  {
    value: "NU",
    group: "N",
    text: "Niue",
  },
  {
    value: "NF",
    group: "N",
    text: "Norfolk Island",
  },
  {
    value: "NO",
    group: "N",
    text: "Norway",
  },
  {
    value: "OM",
    text: "Oman",
  },
  {
    value: "PK",
    text: "Pakistan",
  },
  {
    value: "PA",
    text: "Panama",
  },
  {
    value: "PG",
    text: "Papua New Guinea",
  },
  {
    value: "PY",
    text: "Paraguay",
  },
  {
    value: "PE",
    text: "Peru",
  },
  {
    value: "PH",
    text: "Philippines",
  },
  {
    value: "PL",
    text: "Poland",
  },
  {
    value: "PT",
    text: "Portugal",
  },
  {
    value: "QA",
    text: "Qatar",
  },
  {
    value: "RO",
    text: "Romania",
  },
  {
    value: "RU",
    text: "Russian Federation",
  },
  {
    value: "RW",
    text: "Rwanda",
  },
  {
    value: "WS",
    text: "Samoa",
  },
  {
    value: "SM",
    text: "San Marino",
  },
  {
    value: "ST",
    text: "Sao Tome And Principe",
  },
  {
    value: "SA",
    text: "Saudi Arabia",
  },
  {
    value: "SN",
    text: "Senegal",
  },
  {
    value: "RS",
    text: "Serbia",
  },
  {
    value: "SC",
    text: "Seychelles",
  },
  {
    value: "SL",
    text: "Sierra Leone",
  },
  {
    value: "SG",
    text: "Singapore",
  },
  {
    value: "SK",
    text: "Slovakia",
  },
  {
    value: "SI",
    text: "Slovenia",
  },
  {
    value: "SB",
    text: "Solomon Islands",
  },
  {
    value: "SO",
    text: "Somalia",
  },
  {
    value: "ZA",
    text: "South Africa",
  },
  {
    value: "ES",
    text: "Spain",
  },
  {
    value: "LK",
    text: "Sri Lanka",
  },
  {
    value: "SD",
    text: "Sudan",
  },
  {
    value: "SR",
    text: "Suriname",
  },
  {
    value: "SZ",
    text: "Swaziland",
  },
  {
    value: "SE",
    text: "Sweden",
  },
  {
    value: "CH",
    text: "Switzerland",
  },
  {
    value: "SY",
    text: "Syrian Arab Republic",
  },
  {
    value: "TW",
    text: "Taiwan",
  },
  {
    value: "TJ",
    text: "Tajikistan",
  },
  {
    value: "TZ",
    text: "Tanzania",
  },
  {
    value: "TH",
    text: "Thailand",
  },
  {
    value: "TL",
    text: "Timor-Leste",
  },
  {
    value: "TG",
    text: "Togo",
  },
  {
    value: "TK",
    text: "Tokelau",
  },
  {
    value: "TO",
    text: "Tonga",
  },
  {
    value: "TN",
    text: "Tunisia",
  },
  {
    value: "TR",
    text: "Turkey",
  },
  {
    value: "TM",
    text: "Turkmenistan",
  },
  {
    value: "TV",
    text: "Tuvalu",
  },
  {
    value: "UG",
    text: "Uganda",
  },
  {
    value: "UA",
    text: "Ukraine",
  },
  {
    value: "AE",
    text: "United Arab Emirates",
  },
  {
    value: "GB",
    text: "United Kingdom",
  },
  {
    value: "US",
    text: "United States",
  },
  {
    value: "UY",
    text: "Uruguay",
  },
  {
    value: "VU",
    group: "V",
    text: "Vanuatu",
  },
  {
    value: "VE",
    group: "V",
    text: "Venezuela",
  },
  {
    value: "VN",
    group: "V",
    text: "Viet Nam",
  },
  {
    value: "WF",
    group: "W",
    text: "Wallis And Futuna",
  },
  {
    value: "YE",
    text: "Yemen",
  },
  {
    value: "ZM",
    group: "Z",
    text: "Zambia",
  },
  {
    value: "ZW",
    group: "Z",
    text: "Zimbabwe",
  },
];

// export const LANDING_URL = 'https://www.reimaginehome.ai/';
export const LANDING_URL = process.env.NEXT_PUBLIC_ENV !== "prod" ? "https://www.spacedesign.ai/" : "https://www.reimaginehome.ai/";
export const PREVIEW_URL = process.env.NEXT_PUBLIC_ENV !== "prod" ? "https://preview.reimaginehome.ai/" : "https://preview.reimaginehome.ai/";

export const PROFESSIONS = [
  {
    label: "Landscape Designer/Architect/Engineer/Artist",
    value: "Landscape Designer/Architect/Engineer/Artist",
  },
  {
    label: "Interior designer/decorator/engineer",
    value: "Interior designer/decorator/engineer",
  },
  { label: "Realtor", value: "Realtor" },
  { label: "Agent", value: "Agent" },
  { label: "Broker", value: "Broker" },
  { label: "Property Manager", value: "Property Manager" },
  { label: "Photographer", value: "Photographer" },
  { label: "Architect", value: "Architect" },
  {
    label: "Marketing: Signs and printing",
    value: "Marketing: Signs and printing",
  },
  { label: "Marketing: Other", value: "Marketing: Other" },
  { label: "Tourism/Travel", value: "Tourism/Travel" },
  { label: "Retail & Merchandising", value: "Retail & Merchandising" },
  { label: "Home Owner", value: "Home Owner" },
  { label: "CRMLS-Member", value: "CRMLS-Member" },
  { label: "Others", value: "Others" },  
];
//null means not applicable and NA means user given input 
export const defaultFurnishingType = {
  SURPRISE_ME: true,
  VIRTUAL_STAGING_EMPTY_ROOM: true,
  FURNISH_EMPTY_ROOM: true,
  VIRTUAL_STAGING: true,
  VIRTUAL_STAGING_FURNISHED_ROOM: true,
  AI_INTERIOR_DESIGN: true,
  REDESIGN_FURNISHED_ROOM: false,
  REDESIGN_FURNISHED_ROOMS: false,
  SURFACE_CHANGE_WALL: "NA",
  SURFACE_CHANGE_CEILING: "NA",
  SURFACE_CHANGE_FLOOR: "NA",
  LAWN_ENHANCEMENT: null,
  SKY_ENHANCEMENT: null,
  POOL_WATER_ENHANCEMENT: null,
  CHANGE_PATHWAYS_WALKWAYS: null,
  CHANGE_MATERIALS_ELEMENT: null,
  LANDSCAPING: null,
  REMOVE_OBJECT: "NA",
  OCCUPIED_TO_VACANT: false,
  EMPTY_THE_SPACE:false,
  EMPTY_YOUR_SPACE:false,
  AI_LANDSCAPING: null,
  BATHROOM_REMODELING: true,
  SURFACE_CHANGE_BATHROOM_WALL_TILE: true,
  SURFACE_CHANGE_BATHROOM_CABINETS: true,
  SURFACE_CHANGE_BATHROOM_COUNTERTOP: true,
  KITCHEN_REMODELING: true,
  SURFACE_CHANGE_KITCHEN_COUNTERTOP: true,
  SURFACE_CHANGE_KITCHEN_CABINETS: true,
  SURFACE_CHANGE_KITCHEN_APPLIANCES: true,
  SURFACE_CHANGE_KITCHEN_BACKSPLASH: true,
  UNDER_CONSTRUCTION_INTERIOR: true,
  UNDER_CONSTRUCTION_EXTERIOR: null,
  RENDER_EXTERIOR_STRUCTURES:null,
};

export const REVERSE_SOLUTION_SCHEMA_MAPPING = {
  "surprise-me": "SURPRISE_ME",

  "virtually-stage-empty-room": "VIRTUAL_STAGING_EMPTY_ROOM",
  "virtually-stage-furnished-room": "VIRTUAL_STAGING_FURNISHED_ROOM",

  "ai-interior-design": "AI_INTERIOR_DESIGN",

  "change-wall-color": "SURFACE_CHANGE_WALL",
  "change-ceiling": "SURFACE_CHANGE_CEILING",
  "change-floor": "SURFACE_CHANGE_FLOOR",

  "lawn-enhancement": "LAWN_ENHANCEMENT",
  "sky-replacement": "SKY_ENHANCEMENT",
  "pool-renovation": "POOL_WATER_ENHANCEMENT",
  "change-pathways": "CHANGE_PATHWAYS_WALKWAYS",

  "change-material-color-of-furnishing-elements": "CHANGE_MATERIALS_ELEMENT",

  "landscaping": "LANDSCAPING",

  "remove-unwanted-objects": "REMOVE_OBJECT",
  "occupied-to-vacant": "OCCUPIED_TO_VACANT",

  "remodel-bathroom": "BATHROOM_REMODELING",
  "upgrade-bathroom-wall-tiles": "SURFACE_CHANGE_BATHROOM_WALL_TILE",
  "upgrade-bathroom-cabinets": "SURFACE_CHANGE_BATHROOM_CABINETS",
  "upgrade-bathroom-countertop": "SURFACE_CHANGE_BATHROOM_COUNTERTOP",

  "remodel-kitchen": "KITCHEN_REMODELING",
  "upgrade-kitchen-countertop": "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
  "upgrade-kitchen-cabinets": "SURFACE_CHANGE_KITCHEN_CABINETS",
  "upgrade-kitchen-appliances": "SURFACE_CHANGE_KITCHEN_APPLIANCES",
  "upgrade-kitchen-backsplash": "SURFACE_CHANGE_KITCHEN_BACKSPLASH",

  "redesign-under-construction-home-interior": "UNDER_CONSTRUCTION_INTERIOR",
  "redesign-under-construction-home-exterior": "UNDER_CONSTRUCTION_EXTERIOR",

  "furnish-empty-room": "FURNISH_EMPTY_ROOM",
  "empty-the-space": "EMPTY_THE_SPACE",
  "redesign-furnished-room": "REDESIGN_FURNISHED_ROOM",

  "virtual-staging":"VIRTUAL_STAGING",
  "redesign-furnished-rooms": "REDESIGN_FURNISHED_ROOMS",
  "empty-your-space": "EMPTY_YOUR_SPACE",
  "ai-landscaping": "AI_LANDSCAPING",
  "render-exterior-structures":"RENDER_EXTERIOR_STRUCTURES",
};

export const STYLE_WISE_COLOR =
{
	"bohemian": [
		"Golden Mustard Harmony, Terracotta Tranquility and Teal Treasure",
		"Magenta Joy, Burnt Orange Bliss and Sapphire Blue Splendor",
		"Turquoise Tranquility, Fuchsia Fun and Golden Glamour",
		"Plum Perfection, Peacock Blue Prestige and Amber Allure",
		"Forest Green Haven, Dusty Pink Delight and Deep Violet Drama",
		"Saffron Glow, Hot Pink Happiness and Electric Blue Energy",
		"Ruby Red Radiance, Indigo Intrigue and Mustard Melody",
		"Emerald Enchantment, Tangerine Tango and Burgundy Bliss",
		"Cerulean Calm, Raspberry Radiance and Lime Green Lift",
		"Olive Green Oasis, Coral Charm and Turquoise Tranquility",
	],
	"coastal": [
		"Navy Blue Nobility, Soft White Whisper and Sandy Serenity",
		"Coral Charm, Turquoise Tranquility and Seafoam Green Soothe",
		"Pale Blue Peace, Warm Beige Welcome and Crisp White Clarity",
		"Soft Gray Serenity, Sky Blue Serenity and Whisper White Wonder",
		"Aqua Bliss, Creamy Calm and Driftwood Brown Depth",
		"Teal Treasure, Sandy Beige Serenity and Ivory Innocence",
		"Ocean Blue Oasis, Foam White Fantasy and Sunbleached Wood Simplicity",
		"Soft Peach Serenity, Pale Aqua Peace and Sandy Taupe Serenity",
		"Seagrass Green Sanctuary, Sandy Beige Serenity and Cloud White Calm",
		"Coastal Blue Calm, Shell Pink Softness and Sea Glass Green Grace",
	],
	"contemporary": [
		"Charcoal Chic, Beige Bliss and Rust Red Radiance",
		"Slate Gray Sophistication, Ice Blue Illusion and Whisper White Wonder",
		"Midnight Black Elegance, Whisper White Wonder and Lipstick Red Lure",
		"Navy Nightfall, Mid-Gray Majesty and Electric Blue Energy",
		"Olive Green Oasis, Dove Gray Delight and Creamy Calm",
		"Burgundy Bliss, Taupe Tranquility and Off-White Oasis",
		"Deep Blue Dream, Pale Gray Poise and Citrus Orange Cheer",
		"Eggplant Elegance, Soft Gray Serenity and Pea Green Pleasure",
		"Chocolate Brown Comfort, Sky Blue Serenity and Sandy Serenity",
		"Teal Treasure, Warm Gray Welcome and Pure White Perfection",
	],
	"farmhouse": [
		"Sage Green Serenity, Creamy Calm and Barn Red Beauty",
		"Soft Blue Dreams, Warm Beige Welcome and Crisp White Clarity",
		"Eucalyptus Elegance, Buttermilk Bliss and Dove Gray Delight",
		"Dusty Lavender Delight, Antique White Aura and Soft Gray Serenity",
		"Sea Glass Green Grace, Linen Luxury and Weathered Wood Wisdom",
		"Navy Nightfall, Oatmeal Oasis and Copper Charm",
		"Forest Green Haven, Alabaster Allure and Stone Gray Stability",
		"Denim Blue Delight, Warm White Whisper and Rustic Orange Radiance",
		"Olive Orchard, Creamy Calm and Rich Brown Reverie",
		"Charcoal Chic, Sandstone Serenity and Whisper White Wishes",
	],
	"french country": [
		"Lavender Luxury, Sage Green Serenity and Soft Cream Serenity",
		"Butter Yellow Bloom, Sky Blue Serenity and Antique White Aura",
		"Rose Pink Romance, Soft Taupe Serenity and Linen White Lightness",
		"Cobalt Blue Charm, Sunflower Yellow Spark and Warm White Whisper",
		"Mint Green Magic, Pale Pink Promise and Dove Gray Delight",
		"Provincial Blue Peace, Rustic Red Richness and Wheat Whisper",
		"Toile Blue Tranquility, Creamy Calm and Terra Cotta Tranquility",
		"Olive Green Oasis, Blush Pink Bloom and Aged White Allure",
		"Peony Pink Pleasure, Sage Serenity and Ivory Innocence",
		"Eggshell Elegance, French Blue Fantasy and Almond Allure",
	],
	"glam": [
		"Midnight Black Elegance, Golden Glamour and Hot Pink Happiness",
		"Silver Sophistication, Whisper White Wonder and Lavender Luxury",
		"Navy Blue Nobility, Metallic Gold Majesty and Ivory Innocence",
		"Deep Purple Passion, Glossy White Grace and Silver Sophistication",
		"Emerald Enchantment, Champagne Chic and Creamy Calm",
		"Ruby Red Radiance, Pearl White Purity and Chrome Chic",
		"Midnight Blue Mystery, Rose Gold Radiance and Soft Gray Serenity",
		"Fuchsia Fun, Midnight Black Elegance and Glittering Gold Grandeur",
		"Plum Perfection, Satin Silver Sheen and Frost White Freedom",
		"Sapphire Splendor, Crystal White Charm and Metallic Bronze Brilliance",
	],
	"industrial": [
		"Gunmetal Gray Glamour, Brick Red Boldness and Beige Bliss",
		"Steel Blue Strength, Rust Orange Radiance and Concrete Gray Coolness",
		"Slate Sophistication, Burnt Sienna Beauty and Warm White Whisper",
		"Charcoal Black Chic, Timber Brown Tranquility and Copper Charm",
		"Denim Blue Delight, Iron Gray Integrity and Raw Wood Rusticity",
		"Smoky Black Sophistication, Alabaster Allure and Ochre Opulence",
		"Cobalt Charm, Galvanized Metal Glimmer and Sandstone Serenity",
		"Jet Black Jazz, Reddish Brown Richness and Frosted Silver Sheen",
		"Olive Drab Oasis, Steel Strength and Warm Taupe Welcome",
		"Midnight Blue Mystery, Tan Leather Luxury and Ash Gray Elegance",
	],
	"japandi": [
		"Moss Green Mystery, Beige Bliss and Midnight Black Elegance",
		"Indigo Intrigue, Soft Gray Serenity and Natural Wood Nurturing",
		"Charcoal Chic, Warm White Whisper and Cherry Blossom Pink Charm",
		"Slate Gray Sophistication, Bamboo Green Bliss and Creamy Calm",
		"Earth Brown Essence, Mist Gray Mystery and Snow White Serenity",
		"Deep Teal Dream, Rice Paper Radiance and Aged Wood Authenticity",
		"Midnight Blue Mystery, Almond Allure and Fog Gray Finesse",
		"Burnt Sienna Beauty, Porcelain Purity and Forest Green Haven",
		"Ochre Opulence, Pale Oak Peace and Anthracite Allure",
		"Terracotta Tranquility, Ivory Innocence and Dark Espresso Depth",
	],
	"mid-century modern": [
		"Mustard Melody, Teal Treasure and Burnt Orange Bliss",
		"Avocado Green Adventure, Tangerine Tango and Walnut Warmth",
		"Aqua Bliss, Poppy Red Passion and Charcoal Gray Chic",
		"Olive Green Oasis, Cerulean Blue Calm and Warm Beige Welcome",
		"Coral Charm, Slate Gray Sophistication and Mint Green Magic",
		"Saffron Yellow Sunshine, Pine Green Peace and Cocoa Brown Coziness",
		"Turquoise Tranquility, Sandy Serenity and Deep Brown Depth",
		"Chartreuse Cheer, Dove Gray Delight and Mahogany Majesty",
		"Eggplant Elegance, Lemon Yellow Light and Creamy Calm",
		"Peacock Blue Prestige, Burnt Sienna Beauty and Ivory Innocence",
	],
	"minimal": [
		"Midnight Black Elegance, Whisper White Wonder and Gray Grace",
		"Navy Nightfall, Whisper White Wonder and Beige Bliss",
		"Charcoal Chic, Ice Blue Illusion and Soft White Whisper",
		"Deep Green Devotion, Light Gray Luxury and Pure White Perfection",
		"Slate Sophistication, Creamy Calm and Alabaster Allure",
		"Midnight Blue Mystery, Foggy Gray Fantasy and Snowy Silence",
		"Espresso Elegance, Dove Gray Delight and Porcelain Purity",
		"Mocha Moment, Sandy Serenity and Eggshell Elegance",
		"Graphite Glamour, Oatmeal Oasis and Milky White Mildness",
		"Steel Gray Stability, Taupe Tranquility and Bone Beige Balance",
	],
	"modern": [
		"Midnight Black Elegance, Silver Sophistication and Crimson Charm",
		"Royal Blue Reverie, Whisper White Wonder and Golden Glamour",
		"Lime Green Lift, Cool Gray Calm and Whisper White Wonder",
		"Chocolate Charm, Cyan Cheer and Creamy Calm",
		"Ruby Red Radiance, Anthracite Allure and Snow White Serenity",
		"Tangerine Tango, Slate Sophistication and Frosty White Freedom",
		"Plum Perfection, Silver Sophistication and Ghost White Glow",
		"Jade Green Joy, Charcoal Chic and Platinum Purity",
		"Fuchsia Fun, Midnight Black Elegance and Metallic Silver Sleekness",
		"Sapphire Splendor, Dove Gray Delight and Champagne Chic",
	],
	"rustic": [
		"Forest Green Haven, Tan Tranquility and Brick Bounty",
		"Navy Nightfall, Rustic Red Richness and Creamy Calm",
		"Moss Magic, Stone Gray Stability and Ivory Innocence",
		"Cinnamon Comfort, Denim Blue Delight and Antique White Aura",
		"Burgundy Bliss, Sage Serenity and Oatmeal Oasis",
		"Olive Orchard, Saddle Brown Serenity and Wheat Whisper",
		"Auburn Aura, Fieldstone Foundation and Linen Luxury",
		"Pine Green Peace, Camel Coziness and Dove Gray Delight",
		"Terracotta Tranquility, Sky Blue Serenity and Sandy Serenity",
		"Mahogany Majesty, Soft Green Sanctuary and Burlap Beauty",
	],
	"scandinavian": [
		"Dusty Blue Dream, Warm Wood Welcome and Creamy Calm",
		"Pale Pink Promise, Charcoal Chic and Whisper White Wonder",
		"Mint Magic, Natural Oak Nobility and Soft Grey Serenity",
		"Lavender Luxury, Birch Beauty and Snowy Silence",
		"Coral Charm, Seafoam Serenity and Ivory Innocence",
		"Sky Blue Serenity, Almond Allure and Porcelain Purity",
		"Sage Green Serenity, Blonde Wood Brightness and Pale Beige Peace",
		"Caramel Comfort, Frosty White Freedom and Slate Sophistication",
		"Denim Delight, Whisper White Wishes and Driftwood Dream",
		"Petal Pink Peace, Ash Gray Elegance and Clean White Canvas",
	],
	"traditional": [
		"Navy Nightfall, Burgundy Bliss and Golden Glamour",
		"Hunter Green Harmony, Mahogany Majesty and Ivory Innocence",
		"Royal Blue Reverie, Camel Coziness and Creamy Calm",
		"Wine Whisper, Forest Green Haven and Beige Bliss",
		"Teal Treasure, Burnished Gold Brilliance and Pearl Perfection",
		"Sapphire Splendor, Rosewood Radiance and Eggshell Elegance",
		"Emerald Elegance, Tan Tranquility and Alabaster Allure",
		"Dark Chocolate Decadence, Mint Green Magic and Peachy Paradise",
		"Maroon Majesty, Dark Oak Depth and Soft White Whisper",
		"Olive Orchard, Bronze Beauty and Sandstone Serenity",
	],
	"transitional": [
		"Charcoal Chic, Taupe Tranquility and Ice Blue Illusion",
		"Indigo Intrigue, Heather Gray Harmony and Silver Sophistication",
		"Merlot Magic, Pumice Peace and Soft Gold Glow",
		"Peacock Pride, Warm Beige Welcome and Platinum Purity",
		"Deep Teal Dream, Stony Serenity and Misty Morning",
		"Espresso Elegance, Dusty Rose Delight and Ivory Innocence",
		"Slate Blue Serenity, Sandy Serenity and Whisper Pink Wonder",
		"Garnet Grace, Greige Grace and Champagne Chic",
		"Moss Magic, Slate Sophistication and Creamy Calm",
		"Cobalt Charm, Dove Delicacy and Warm White Whisper",
	],
  "hampton":[
    "Soft White Whisper, Navy Blue Nobility and Sand Beige Solace",
    "Warm White Whisper, Sky Blue Serenity and Light Grey Luminance",
    "Ivory Innocence, Soft Sage Serenity and Driftwood Grey Dream",
    "Crisp White Clarity, Ocean Blue Oasis and Pebble Grey Peace",
    "Linen White Lightness, Eucalyptus Green Grace and Taupe Tranquility",
    "Seafoam Green Soothe, Coral Charm and Sandstone Serenity",
    "Powder Blue Paradise, Charcoal Chic and Whisper Grey Whisper",
    "Midnight Blue Mystery, Pearl White Purity and Silver Grey Splendor",
    "Buttercream Bliss, Soft Lavender Lullaby and Dove Grey Delight",
    "Antique White Aura, Colonial Blue Calm and Heather Grey Harmony",
  ]
}
export const STYLE_WISE_COLOR_RENDER_EXTERIOR_STRUCTURE = {
  colonial: [
    "White and Black",
    "Navy Blue and White",
    "Red and White",
    "Cream and Burgundy",
    "Olive Green and White",
    "Light Gray and White",
    "Yellow and White",
    "Charcoal and White",
    "Taupe and Black",
    "Brick Red and Cream"
  ],
  contemporary: [
    "White and Gray",
    "White and Black",
    "Charcoal and Silver",
    "Beige and White",
    "Navy and White",
    "Dark Gray and Light Gray",
    "Olive Green and White",
    "Slate Blue and Gray",
    "Brown and Cream",
    "Teal and White"
  ],
  cottage: [
    "Pastel Blue and White",
    "Soft Yellow and White",
    "Light Gray and White",
    "Sage Green and White",
    "Lavender and White",
    "Cream and Light Gray",
    "Coral and White",
    "Seafoam Green and White",
    "Dusty Rose and White",
    "Pale Mint and White"
  ],
  farmhouse: [
    "White and Black",
    "Cream and Sage Green",
    "Beige and White",
    "Navy Blue and White",
    "Light Gray and White",
    "Olive Green and White",
    "Soft Yellow and White",
    "Barn Red and White",
    "Taupe and White",
    "Dusty Blue and White"
  ],
  "french provincial": [
    "Cream and Light Blue",
    "Soft Yellow and White",
    "Pale Gray and White",
    "Lavender and Cream",
    "Beige and Light Gray",
    "Mint Green and White",
    "Soft Pink and White",
    "Sage Green and Cream",
    "Light Taupe and White",
    "Blush and White"
  ],
  "greek revival": [
    "White and Blue",
    "White and Black",
    "White and Gray",
    "White and Beige",
    "Cream and Gold",
    "Light Gray and White",
    "Navy and White",
    "Olive Green and White",
    "Slate Blue and White",
    "Charcoal and White"
  ],
  italianate: [
    "Ochre and Cream",
    "Terra Cotta and Beige",
    "Olive Green and White",
    "Gold and White",
    "Deep Red and Cream",
    "Sage Green and Beige",
    "Light Gray and Cream",
    "Burnt Orange and Beige",
    "Navy and Cream",
    "Maroon and White"
  ],
  mediterranean: [
    "White and Terra Cotta",
    "Beige and Blue",
    "Cream and Olive Green",
    "Light Gray and White",
    "Sand and Turquoise",
    "Warm Brown and White",
    "Coral and White",
    "Mustard Yellow and Blue",
    "Seafoam Green and White",
    "Taupe and Light Blue"
  ],
  "midcentury modern": [
    "Teal and White",
    "Mustard Yellow and White",
    "Olive Green and White",
    "Charcoal and Orange",
    "Light Gray and White",
    "Navy and White",
    "Brown and White",
    "Coral and Gray",
    "Turquoise and Beige",
    "White and Black"
  ],
  modern: [
    "White and Black",
    "Gray and White",
    "Beige and Black",
    "Charcoal and White",
    "Navy and White",
    "Olive Green and White",
    "Taupe and White",
    "Light Gray and Black",
    "Dark Brown and White",
    "Silver and White"
  ],
  neoclassical: [
    "White and Gold",
    "Cream and White",
    "Soft Gray and White",
    "Beige and Gold",
    "Light Blue and White",
    "Pale Pink and White",
    "Light Green and White",
    "Taupe and White",
    "Navy and White",
    "Charcoal and White"
  ],
  asian: [
    "Red and Black",
    "White and Black",
    "Gold and Red",
    "Beige and Black",
    "Olive Green and Brown",
    "Deep Blue and White",
    "Soft Gray and White",
    "Cream and Black",
    "Maroon and Gold",
    "Teal and Brown"
  ],
  ranch: [
    "Beige and Brown",
    "Cream and Green",
    "Light Gray and White",
    "Navy and White",
    "Olive Green and Brown",
    "Taupe and White",
    "Red and White",
    "Yellow and White",
    "Sage Green and White",
    "Burnt Orange and White"
  ],
  spanish: [
    "Terra Cotta and White",
    "Cream and Red",
    "Beige and Brown",
    "Olive Green and White",
    "Mustard Yellow and Red",
    "Warm Brown and White",
    "Coral and White",
    "Sand and Blue",
    "Taupe and White",
    "Soft Green and Cream"
  ],
  victorian: [
    "Deep Red and Cream",
    "Hunter Green and White",
    "Navy and Gold",
    "Purple and White",
    "Burgundy and Gold",
    "Dark Blue and Cream",
    "Olive Green and Beige",
    "Gray and White",
    "Maroon and Cream",
    "White and Black"
  ],
  "beach house": [
    "Light Blue and White",
    "Seafoam Green and White",
    "Soft Gray and White",
    "Coral and White",
    "Sand and Blue",
    "Aqua and White",
    "Navy and White",
    "Pale Yellow and White",
    "Mint Green and White",
    "Light Lavender and White"
  ],
  rustic: [
    "Brown and Beige",
    "Olive Green and Brown",
    "Burnt Orange and Cream",
    "Deep Red and Brown",
    "Taupe and White",
    "Sage Green and Beige",
    "Warm Gray and Brown",
    "Charcoal and Cream",
    "Forest Green and Beige",
    "Mustard Yellow and Brown"
  ]
};

export const PAVEMENT_PREFERENCE_EXTERIOR = [
	"A neutral stone pathway",
	"Brick walkway",
	"Concrete pathways",
	"Contemporary pathway",
	"Dynamic pavers pathway",
	"Flagstone walkway",
	"Herringbone pathway",
	"Pea gravel pathway",
	"Stone pathway"
];

export const PLANT_PREFERENCE_EXTERIOR = [
	"Alliums",
	"Annuals",
	"Asiatic lilies mix color",
	"Begonia",
	"Biennials",
	"Black-eyed susan",
	"Bougainvillea",
	"Colorful zinnia",
	"Coneflower",
	"Coreopsis",
	"Dahlia mix color",
	"Daisies",
	"Delphinium",
	"Echinacea",
	"Echinacea",
	"Flowering perennials",
	"Flowering shrubs",
	"Forsythia",
	"Foxglove",
	"Foxglove",
	"Fragrant plants",
	"Gaillardia",
	"Geranium",
	"Gerbera daisies",
	"Gomphrena",
	"Heirloom roses",
	"Hibiscus",
	"Hydrangea",
	"Iris",
	"Lantana",
	"Lobelia mixed colors",
	"Marigold",
	"Petunia grandiflora mixed color",
	"Roses",
	"Roses ",
	"Shrub with purple flowers",
	"Shrubs with pink flowers",
	"Snapdragon",
	"Succulent",
	"Tropicals"
];


export const STRIPE_BASIC_PLAN_ID = process.env.NEXT_PUBLIC_ENV !== "prod" ? "price_1NmwcXJoH3SERBihXT0qf7Ep" : "price_1OCdTcJoH3SERBih6yu6RpOs"
export const STRIPE_MONTHLY_49_PRO_PLAN_ID = process.env.NEXT_PUBLIC_ENV !== "prod" ? "price_1OPjfkJoH3SERBihEYpNnmQh" : "price_1OUk9WJoH3SERBihglU0Pso2"
export const STRIPE_MONTHLY_99_PRO_PLAN_ID = process.env.NEXT_PUBLIC_ENV !== "prod" ? "price_1Nmb8nJoH3SERBihPbylpIjz" : "price_1OCdTSJoH3SERBiheUMFtogh"
export const STRIPE_BASIC_PLAN_ID_2 = process.env.NEXT_PUBLIC_ENV !== "prod" ? "price_1OtOg4JoH3SERBihfZWLSyN7" : "price_1P8DrfJoH3SERBihal3U8adY"
export const STRIPE_MONTHLY_29_PRO_PLAN_ID = process.env.NEXT_PUBLIC_ENV !== "prod" ? "price_1OZ66GJoH3SERBih4skZTYCA" : "price_1OZ7LHJoH3SERBihN5GZLFMu" 
export const STRIPE_MONTHLY_14_PRO_PLAN_ID = process.env.NEXT_PUBLIC_ENV !== "prod" ? "price_1P247bJoH3SERBihmktBCRue" : "price_1P8DrdJoH3SERBih13ZqMEey" 
export const STRIPE_MONTHLY_14_CRMLS_PLAN_ID = process.env.NEXT_PUBLIC_ENV !== "prod" ? "price_1QFSz5JoH3SERBihEUQHUHzP" : "price_1QFSz5JoH3SERBihEUQHUHzP" 
export const STRIPE_FREEMIUM_PLAN_ID = process.env.NEXT_PUBLIC_ENV !== "prod" ? "price_1OtOQZJoH3SERBihisZm8YNt" : "price_1P8DriJoH3SERBih6qbf8fEm"

export const SUBSCRIPTION_PLANS = [
  {
    packFor:"Starter pack",
    title: "Essential",
    titleID: "ESSENTIAL",
    price: 14,
    outputs: 30,
    planId:STRIPE_MONTHLY_14_PRO_PLAN_ID,
    isBestValue:false,
    description:"Ideal for 5 listing photos"
  },
  {
    packFor:"Starter pack",
    title: "Essential",
    titleID: "ESSENTIAL",
    price: 14,
    outputs: 30,
    planId:STRIPE_MONTHLY_14_CRMLS_PLAN_ID,
    isBestValue:false,
    description:"Ideal for 5 listing photos"
  },
  {
    packFor:"For active agents",
    title: "Optimal",
    titleID: "OPTIMAL",
    price: 29,
    outputs: 200,
    planId: STRIPE_MONTHLY_29_PRO_PLAN_ID,
    isBestValue:true,
    description:"Ideal for 30 listing photos"
  },
  {
    packFor:"For top producers",
    title: "Advanced",
    titleID: "ADVANCED",
    price: 49,
    outputs: 500,
    planId: STRIPE_MONTHLY_49_PRO_PLAN_ID,
    isBestValue: false,
    description: "Ideal for 80 listing photos"
  },
  {
    packFor:"For larger volumes",
    title: "Premium",
    titleID: "PREMIUM",
    price: 99,
    outputs: "1200",
    planId: STRIPE_MONTHLY_99_PRO_PLAN_ID,
    isBestValue: false,
    description: "Ideal for 200 listing photos"
  }
];

export const COLOR_MAPPING = {
  "Golden Mustard Harmony, Terracotta Tranquility and Teal Treasure":"Mustard Yellow, Terracotta and Teal",
"Magenta Joy, Burnt Orange Bliss and Sapphire Blue Splendor":"Magenta, Burnt Orange and Sapphire Blue",
"Turquoise Tranquility, Fuchsia Fun and Golden Glamour":"Turquoise, Fuchsia and Gold",
"Plum Perfection, Peacock Blue Prestige and Amber Allure":"Plum, Peacock Blue and Amber",
"Forest Green Haven, Dusty Pink Delight and Deep Violet Drama":"Forest Green, Dusty Pink and Deep Violet",
"Saffron Glow, Hot Pink Happiness and Electric Blue Energy":"Saffron, Hot Pink and Electric Blue",
"Ruby Red Radiance, Indigo Intrigue and Mustard Melody":"Ruby Red, Indigo and Mustard",
"Emerald Enchantment, Tangerine Tango and Burgundy Bliss":"Emerald Green, Tangerine and Burgundy",
"Cerulean Calm, Raspberry Radiance and Lime Green Lift":"Cerulean, Raspberry and Lime Green",
"Olive Green Oasis, Coral Charm and Turquoise Tranquility":"Olive Green, Coral and Turquoise",
"Navy Blue Nobility, Soft White Whisper and Sandy Serenity":"Navy Blue, Soft White and Sand",
"Coral Charm, Turquoise Tranquility and Seafoam Green Soothe":"Coral, Turquoise and Seafoam Green",
"Pale Blue Peace, Warm Beige Welcome and Crisp White Clarity":"Pale Blue, Warm Beige and Crisp White",
"Soft Gray Serenity, Sky Blue Serenity and Whisper White Wonder":"Soft Gray, Sky Blue and White",
"Aqua Bliss, Creamy Calm and Driftwood Brown Depth":"Aqua, Cream and Driftwood Brown",
"Teal Treasure, Sandy Beige Serenity and Ivory Innocence":"Teal, Sandy Beige and Ivory",
"Ocean Blue Oasis, Foam White Fantasy and Sunbleached Wood Simplicity":"Ocean Blue, Foam White and Sunbleached Wood",
"Soft Peach Serenity, Pale Aqua Peace and Sandy Taupe Serenity":"Soft Peach, Pale Aqua and Sandy Taupe",
"Seagrass Green Sanctuary, Sandy Beige Serenity and Cloud White Calm":"Seagrass Green, Sandy Beige and Cloud White",
"Coastal Blue Calm, Shell Pink Softness and Sea Glass Green Grace":"Coastal Blue, Shell Pink and Sea Glass Green",
"Charcoal Chic, Beige Bliss and Rust Red Radiance":"Charcoal, Beige and Rust Red",
"Slate Gray Sophistication, Ice Blue Illusion and Whisper White Wonder":"Slate Gray, Ice Blue and White",
"Midnight Black Elegance, Whisper White Wonder and Lipstick Red Lure":"Black, White and Lipstick Red",
"Navy Nightfall, Mid-Gray Majesty and Electric Blue Energy":"Navy, Mid-Gray and Electric Blue",
"Olive Green Oasis, Dove Gray Delight and Creamy Calm":"Olive Green, Dove Gray and Cream",
"Burgundy Bliss, Taupe Tranquility and Off-White Oasis":"Burgundy, Taupe and Off-White",
"Deep Blue Dream, Pale Gray Poise and Citrus Orange Cheer":"Deep Blue, Pale Gray and Citrus Orange",
"Eggplant Elegance, Soft Gray Serenity and Pea Green Pleasure":"Eggplant, Soft Gray and Pea Green",
"Chocolate Brown Comfort, Sky Blue Serenity and Sandy Serenity":"Chocolate Brown, Sky Blue and Sand",
"Teal Treasure, Warm Gray Welcome and Pure White Perfection":"Teal, Warm Gray and Pure White",
"Sage Green Serenity, Creamy Calm and Barn Red Beauty":"Sage Green, Cream and Barn Red",
"Soft Blue Dreams, Warm Beige Welcome and Crisp White Clarity":"Soft Blue, Warm Beige and Crisp White",
"Eucalyptus Elegance, Buttermilk Bliss and Dove Gray Delight":"Eucalyptus, Buttermilk and Dove Gray",
"Dusty Lavender Delight, Antique White Aura and Soft Gray Serenity":"Dusty Lavender, Antique White and Soft Gray",
"Sea Glass Green Grace, Linen Luxury and Weathered Wood Wisdom":"Sea Glass Green, Linen and Weathered Wood",
"Navy Nightfall, Oatmeal Oasis and Copper Charm":"Navy, Oatmeal and Copper",
"Forest Green Haven, Alabaster Allure and Stone Gray Stability":"Forest Green, Alabaster and Stone Gray",
"Denim Blue Delight, Warm White Whisper and Rustic Orange Radiance":"Denim Blue, Warm White and Rustic Orange",
"Olive Orchard, Creamy Calm and Rich Brown Reverie":"Olive, Cream and Rich Brown",
"Charcoal Chic, Sandstone Serenity and Whisper White Wishes":"Charcoal, Sandstone and Whisper White",
"Lavender Luxury, Sage Green Serenity and Soft Cream Serenity":"Lavender, Sage Green and Soft Cream",
"Butter Yellow Bloom, Sky Blue Serenity and Antique White Aura":"Butter Yellow, Sky Blue and Antique White",
"Rose Pink Romance, Soft Taupe Serenity and Linen White Lightness":"Rose Pink, Soft Taupe and Linen White",
"Cobalt Blue Charm, Sunflower Yellow Spark and Warm White Whisper":"Cobalt Blue, Sunflower Yellow and Warm White",
"Mint Green Magic, Pale Pink Promise and Dove Gray Delight":"Mint Green, Pale Pink and Dove Gray",
"Provincial Blue Peace, Rustic Red Richness and Wheat Whisper":"Provincial Blue, Rustic Red and Wheat",
"Toile Blue Tranquility, Creamy Calm and Terra Cotta Tranquility":"Toile Blue, Cream and Terra Cotta",
"Olive Green Oasis, Blush Pink Bloom and Aged White Allure":"Olive Green, Blush Pink and Aged White",
"Peony Pink Pleasure, Sage Serenity and Ivory Innocence":"Peony Pink, Sage and Ivory",
"Eggshell Elegance, French Blue Fantasy and Almond Allure":"Eggshell, French Blue and Almond",
"Midnight Black Elegance, Golden Glamour and Hot Pink Happiness":"Black, Gold and Hot Pink",
"Silver Sophistication, Whisper White Wonder and Lavender Luxury":"Silver, White and Lavender",
"Navy Blue Nobility, Metallic Gold Majesty and Ivory Innocence":"Navy Blue, Metallic Gold and Ivory",
"Deep Purple Passion, Glossy White Grace and Silver Sophistication":"Deep Purple, Glossy White and Silver",
"Emerald Enchantment, Champagne Chic and Creamy Calm":"Emerald Green, Champagne and Cream",
"Ruby Red Radiance, Pearl White Purity and Chrome Chic":"Ruby Red, Pearl White and Chrome",
"Midnight Blue Mystery, Rose Gold Radiance and Soft Gray Serenity":"Midnight Blue, Rose Gold and Soft Gray",
"Fuchsia Fun, Midnight Black Elegance and Glittering Gold Grandeur":"Fuchsia, Black and Glittering Gold",
"Plum Perfection, Satin Silver Sheen and Frost White Freedom":"Plum, Satin Silver and Frost White",
"Sapphire Splendor, Crystal White Charm and Metallic Bronze Brilliance":"Sapphire, Crystal White and Metallic Bronze",
"Soft White Whisper, Navy Blue Nobility and Sand Beige Solace":"Soft White, Navy Blue and Sand Beige",
"Warm White Whisper, Sky Blue Serenity and Light Grey Luminance":"Warm White, Sky Blue and Light Grey",
"Ivory Innocence, Soft Sage Serenity and Driftwood Grey Dream":"Ivory, Soft Sage and Driftwood Grey",
"Crisp White Clarity, Ocean Blue Oasis and Pebble Grey Peace":"Crisp White, Ocean Blue and Pebble Grey",
"Linen White Lightness, Eucalyptus Green Grace and Taupe Tranquility":"Linen White, Eucalyptus Green and Taupe",
"Seafoam Green Soothe, Coral Charm and Sandstone Serenity":"Seafoam Green, Coral and Sandstone",
"Powder Blue Paradise, Charcoal Chic and Whisper Grey Whisper":"Powder Blue, Charcoal and Whisper Grey",
"Midnight Blue Mystery, Pearl White Purity and Silver Grey Splendor":"Midnight Blue, Pearl White and Silver Grey",
"Buttercream Bliss, Soft Lavender Lullaby and Dove Grey Delight":"Buttercream, Soft Lavender and Dove Grey",
"Antique White Aura, Colonial Blue Calm and Heather Grey Harmony":"Antique White, Colonial Blue and Heather Grey",
"Gunmetal Gray Glamour, Brick Red Boldness and Beige Bliss":"Gunmetal Gray, Brick Red and Beige",
"Steel Blue Strength, Rust Orange Radiance and Concrete Gray Coolness":"Steel Blue, Rust Orange and Concrete Gray",
"Slate Sophistication, Burnt Sienna Beauty and Warm White Whisper":"Slate, Burnt Sienna and Warm White",
"Charcoal Black Chic, Timber Brown Tranquility and Copper Charm":"Charcoal Black, Timber Brown and Copper",
"Denim Blue Delight, Iron Gray Integrity and Raw Wood Rusticity":"Denim Blue, Iron Gray and Raw Wood",
"Smoky Black Sophistication, Alabaster Allure and Ochre Opulence":"Smoky Black, Alabaster and Ochre",
"Cobalt Charm, Galvanized Metal Glimmer and Sandstone Serenity":"Cobalt, Galvanized Metal and Sandstone",
"Jet Black Jazz, Reddish Brown Richness and Frosted Silver Sheen":"Jet Black, Reddish Brown and Frosted Silver",
"Olive Drab Oasis, Steel Strength and Warm Taupe Welcome":"Olive Drab, Steel and Warm Taupe",
"Midnight Blue Mystery, Tan Leather Luxury and Ash Gray Elegance":"Midnight Blue, Tan Leather and Ash Gray",
"Moss Green Mystery, Beige Bliss and Midnight Black Elegance":"Moss Green, Beige and Black",
"Indigo Intrigue, Soft Gray Serenity and Natural Wood Nurturing":"Indigo, Soft Gray and Natural Wood",
"Charcoal Chic, Warm White Whisper and Cherry Blossom Pink Charm":"Charcoal, Warm White and Cherry Blossom Pink",
"Slate Gray Sophistication, Bamboo Green Bliss and Creamy Calm":"Slate Gray, Bamboo Green and Cream",
"Earth Brown Essence, Mist Gray Mystery and Snow White Serenity":"Earth Brown, Mist Gray and Snow White",
"Deep Teal Dream, Rice Paper Radiance and Aged Wood Authenticity":"Deep Teal, Rice Paper and Aged Wood",
"Midnight Blue Mystery, Almond Allure and Fog Gray Finesse":"Midnight Blue, Almond and Fog Gray",
"Burnt Sienna Beauty, Porcelain Purity and Forest Green Haven":"Burnt Sienna, Porcelain and Forest Green",
"Ochre Opulence, Pale Oak Peace and Anthracite Allure":"Ochre, Pale Oak and Anthracite",
"Terracotta Tranquility, Ivory Innocence and Dark Espresso Depth":"Terracotta, Ivory and Dark Espresso",
"Mustard Melody, Teal Treasure and Burnt Orange Bliss":"Mustard, Teal and Burnt Orange",
"Avocado Green Adventure, Tangerine Tango and Walnut Warmth":"Avocado Green, Tangerine and Walnut",
"Aqua Bliss, Poppy Red Passion and Charcoal Gray Chic":"Aqua, Poppy Red and Charcoal Gray",
"Olive Green Oasis, Cerulean Blue Calm and Warm Beige Welcome":"Olive Green, Cerulean Blue and Warm Beige",
"Coral Charm, Slate Gray Sophistication and Mint Green Magic":"Coral, Slate Gray and Mint Green",
"Saffron Yellow Sunshine, Pine Green Peace and Cocoa Brown Coziness":"Saffron Yellow, Pine Green and Cocoa Brown",
"Turquoise Tranquility, Sandy Serenity and Deep Brown Depth":"Turquoise, Sand and Deep Brown",
"Chartreuse Cheer, Dove Gray Delight and Mahogany Majesty":"Chartreuse, Dove Gray and Mahogany",
"Eggplant Elegance, Lemon Yellow Light and Creamy Calm":"Eggplant, Lemon Yellow and Cream",
"Peacock Blue Prestige, Burnt Sienna Beauty and Ivory Innocence":"Peacock Blue, Burnt Sienna and Ivory",
"Midnight Black Elegance, Whisper White Wonder and Gray Grace":"Black, White and Gray",
"Navy Nightfall, Whisper White Wonder and Beige Bliss":"Navy, White and Beige",
"Charcoal Chic, Ice Blue Illusion and Soft White Whisper":"Charcoal, Ice Blue and Soft White",
"Deep Green Devotion, Light Gray Luxury and Pure White Perfection":"Deep Green, Light Gray and Pure White",
"Slate Sophistication, Creamy Calm and Alabaster Allure":"Slate, Cream and Alabaster",
"Midnight Blue Mystery, Foggy Gray Fantasy and Snowy Silence":"Midnight Blue, Fog and Snow",
"Espresso Elegance, Dove Gray Delight and Porcelain Purity":"Espresso, Dove Gray and Porcelain",
"Mocha Moment, Sandy Serenity and Eggshell Elegance":"Mocha, Sand and Eggshell",
"Graphite Glamour, Oatmeal Oasis and Milky White Mildness":"Graphite, Oatmeal and Milk",
"Steel Gray Stability, Taupe Tranquility and Bone Beige Balance":"Steel Gray, Taupe and Bone",
"Midnight Black Elegance, Silver Sophistication and Crimson Charm":"Black, Silver and Crimson",
"Royal Blue Reverie, Whisper White Wonder and Golden Glamour":"Royal Blue, White and Gold",
"Lime Green Lift, Cool Gray Calm and Whisper White Wonder":"Lime Green, Cool Gray and White",
"Chocolate Charm, Cyan Cheer and Creamy Calm":"Chocolate, Cyan and Cream",
"Ruby Red Radiance, Anthracite Allure and Snow White Serenity":"Ruby Red, Anthracite and Snow White",
"Tangerine Tango, Slate Sophistication and Frosty White Freedom":"Tangerine, Slate and Frost",
"Plum Perfection, Silver Sophistication and Ghost White Glow":"Plum, Silver and Ghost White",
"Jade Green Joy, Charcoal Chic and Platinum Purity":"Jade Green, Charcoal and Platinum",
"Fuchsia Fun, Midnight Black Elegance and Metallic Silver Sleekness":"Fuchsia, Black and Metallic Silver",
"Sapphire Splendor, Dove Gray Delight and Champagne Chic":"Sapphire, Dove Gray and Champagne",
"Forest Green Haven, Tan Tranquility and Brick Bounty":"Forest Green, Tan and Brick",
"Navy Nightfall, Rustic Red Richness and Creamy Calm":"Navy, Rust and Cream",
"Moss Magic, Stone Gray Stability and Ivory Innocence":"Moss, Stone Gray and Ivory",
"Cinnamon Comfort, Denim Blue Delight and Antique White Aura":"Cinnamon, Denim Blue and Antique White",
"Burgundy Bliss, Sage Serenity and Oatmeal Oasis":"Burgundy, Sage and Oatmeal",
"Olive Orchard, Saddle Brown Serenity and Wheat Whisper":"Olive, Saddle Brown and Wheat",
"Auburn Aura, Fieldstone Foundation and Linen Luxury":"Auburn, Fieldstone and Linen",
"Pine Green Peace, Camel Coziness and Dove Gray Delight":"Pine Green, Camel and Dove Gray",
"Terracotta Tranquility, Sky Blue Serenity and Sandy Serenity":"Terracotta, Sky Blue and Sand",
"Mahogany Majesty, Soft Green Sanctuary and Burlap Beauty":"Mahogany, Soft Green and Burlap",
"Dusty Blue Dream, Warm Wood Welcome and Creamy Calm":"Dusty Blue, Warm Wood and Cream",
"Pale Pink Promise, Charcoal Chic and Whisper White Wonder":"Pale Pink, Charcoal and White",
"Mint Magic, Natural Oak Nobility and Soft Grey Serenity":"Mint, Natural Oak and Soft Grey",
"Lavender Luxury, Birch Beauty and Snowy Silence":"Lavender, Birch and Snow",
"Coral Charm, Seafoam Serenity and Ivory Innocence":"Coral, Seafoam and Ivory",
"Sky Blue Serenity, Almond Allure and Porcelain Purity":"Sky Blue, Almond and Porcelain",
"Sage Green Serenity, Blonde Wood Brightness and Pale Beige Peace":"Sage Green, Blonde Wood and Pale Beige",
"Caramel Comfort, Frosty White Freedom and Slate Sophistication":"Caramel, Frost and Slate",
"Denim Delight, Whisper White Wishes and Driftwood Dream":"Denim, Whisper White and Driftwood",
"Petal Pink Peace, Ash Gray Elegance and Clean White Canvas":"Petal Pink, Ash Gray and Clean White",
"Navy Nightfall, Burgundy Bliss and Golden Glamour":"Navy, Burgundy and Gold",
"Hunter Green Harmony, Mahogany Majesty and Ivory Innocence":"Hunter Green, Mahogany and Ivory",
"Royal Blue Reverie, Camel Coziness and Creamy Calm":"Royal Blue, Camel and Cream",
"Wine Whisper, Forest Green Haven and Beige Bliss":"Wine, Forest Green and Beige",
"Teal Treasure, Burnished Gold Brilliance and Pearl Perfection":"Teal, Burnished Gold and Pearl",
"Sapphire Splendor, Rosewood Radiance and Eggshell Elegance":"Sapphire, Rosewood and Eggshell",
"Emerald Elegance, Tan Tranquility and Alabaster Allure":"Emerald, Tan and Alabaster",
"Dark Chocolate Decadence, Mint Green Magic and Peachy Paradise":"Dark Chocolate, Mint Green and Peach",
"Maroon Majesty, Dark Oak Depth and Soft White Whisper":"Maroon, Dark Oak and Soft White",
"Olive Orchard, Bronze Beauty and Sandstone Serenity":"Olive, Bronze and Sandstone",
"Charcoal Chic, Taupe Tranquility and Ice Blue Illusion":"Charcoal, Taupe and Ice Blue",
"Indigo Intrigue, Heather Gray Harmony and Silver Sophistication":"Indigo, Heather Gray and Silver",
"Merlot Magic, Pumice Peace and Soft Gold Glow":"Merlot, Pumice and Soft Gold",
"Peacock Pride, Warm Beige Welcome and Platinum Purity":"Peacock, Warm Beige and Platinum",
"Deep Teal Dream, Stony Serenity and Misty Morning":"Deep Teal, Stone and Mist",
"Espresso Elegance, Dusty Rose Delight and Ivory Innocence":"Espresso, Dusty Rose and Ivory",
"Slate Blue Serenity, Sandy Serenity and Whisper Pink Wonder":"Slate Blue, Sand and Whisper Pink",
"Garnet Grace, Greige Grace and Champagne Chic":"Garnet, Greige and Champagne",
"Moss Magic, Slate Sophistication and Creamy Calm":"Moss, Slate and Cream",
"Cobalt Charm, Dove Delicacy and Warm White Whisper":"Cobalt, Dove and Warm White",
}