import { COLOR_OPTIONS, HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK } from "./constants"
export const LANDSCAPING= {
  initial_screen: "mask_screen", //mask_screen/dp_screen
  solution_name: "landscaping",
  solution_id: "LANDSCAPING",
  project_name: "New project",
  popTitle:"Landscaping",
  space_options: [
    { label: "Back of the house", value: "back of the house" },
    { label: "Front of the house", value: "front of the house" },
    // { label: "Garden", value: "garden" },
    // { label: "Outdoor Living", value: "outdoor living" },
    // { label: "Swimming Pool", value: "swimming pool" },
  ],
  space_type: "",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: false,
  enable_mask_overlay: false,
  pre_fetch_mask: true,
  is_masking_enabled: true,
  solutionIcon:'designPanelIconLandscaping',
  results: [],
  side_panel_enabled: true,
  side_panel_schema: {
    designing_for: {
      type: "dropdown",
      label: "Designing for",
      name: "designing for",
      path: "designing_for",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        { label: "Landscaping/Garden", value: "landscaping" },
        { label: "Outdoor Living", value: "outdoor living" },
        { label: "Backyard", value: "backyard" },
        { label: "Poolside", value: "poolside" },
        { label: "Deck/Patio", value: "deck patio" }
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select designing for",
    },
    design_theme: {
      type: "dropdown",
      labels: {
        designing_for: {
          "landscaping": "Select garden theme",
          "backyard": "Select backyard theme",
          "outdoor living": "Select outdoor living theme",
          "poolside": "Select poolside theme",
          "deck patio": "Select deck / patio theme"
        },
      },
      name: "theme",
      path: "design_theme",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: {
        designing_for: {
            "landscaping": [
              { label: "Simple Green Lawn", value: "simple green lawn" },
              { label: "Xeriscape Garden", value: "xeriscape garden" },
              { label: "Elegant Fountain Garden", value: "elegant fountain garden" },
              { label: "Flowering Paradise", value: "flowering paradise" },
              { label: "Verdant Plant Haven", value: "verdant plant haven" }
            ],
            "backyard": [
              { label: "Family-Friendly Outdoor Space", value: "family-friendly outdoor space" },
              { label: "Backyard Retreat", value: "backyard retreat" },
              { label: "Garden Oasis", value: "garden oasis" },
              { label: "Outdoor Games Area", value: "outdoor games area" },
              { label: "Tranquil Water Feature Space", value: "tranquil water feature space" }
            ],
            "outdoor living": [
              { label: "Outdoor Retreat", value: "outdoor retreat" },
              { label: "Fireside Lounge", value: "fireside lounge" },
              { label: "Grill Corner", value: "grill corner" },
              { label: "Starlight Cinema", value: "starlight cinema" },
              { label: "Sunny Reading Nook", value: "sunny reading nook" }
            ],
            "poolside": [
              { label: "Round Pool Delight", value: "round pool delight" },
              { label: "Rectangle Pool Elegance", value: "rectangle pool elegance" },
              { label: "Pool with Jacuzzi", value: "pool with jacuzzi" },
              { label: "Resort-Style Pool with Spa", value: "resort-style pool with spa" },
              { label: "Family Fun Zone", value: "family fun zone" }
            ],
            "deck patio": [
              { label: "Urban Retreat", value: "urban retreat" },
              { label: "Sunset Viewing Deck", value: "sunset viewing deck" },
              { label: "Outdoor Dining", value: "outdoor dining" },
              { label: "Artistic Lounge", value: "artistic lounge" },
              { label: "Nature-Integrated Patio", value: "nature-integrated patio" }
            ]         
        },
      },
      dependent_on: ["designing_for"],
      required: true,
      error_msg: {
        designing_for: {
          "landscaping": "Please select garden/landscaping theme",
          "backyard": "Please select backyard theme",
          "outdoor living": "Please select outdoor living theme",
          "poolside": "Please select poolside theme",
          "patio": "Please select deck / patio theme",
        },
      },
    },
    multiple_color_and_pattern_preference: {
      type: "dropdown",
      label: "Pathways",
      name: "designing for",
      path: "designing_for",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        { "label": "No Pathway", "value": " " },
  { "label": "Brick Walkway", "value": "brick walkway" },
  { "label": "Concrete Pathway", "value": "concrete pathway" },
  { "label": "Contemporary Pathway", "value": "contemporary pathway" },
  { "label": "Dynamic Pavers Pathway", "value": "dynamic pavers pathway" },
  { "label": "Flagstone Walkway", "value": "flagstone walkway" },
  { "label": "Herringbone Pathway", "value": "herringbone pathway" },
  { "label": "Pea Gravel Pathway", "value": "pea gravel pathway" },
  { "label": "Stone Pathway", "value": "stone pathway" }
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select pathways",
    },
    // user_instructions: {
    //   type: "string",
    //   label: "Instructions to redesign",
    //   name: "Instructions to redesigns",
    //   path: "user_instructions",
    //   placeholder: "Please write your instructions in English.",
    //   value: "",
    //   temp_value: "",
    //   info_enabled: false,
    //   info_txt: "",
    //   dependent_on: "",
    //   required: false,
    //   error_msg: "Please enter instructions to redesign",
    // },
  },
  upload_screen: {
    main_text: "Upload an image of home exterior",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    solution_panel_name: "Landscaping",
    component: "withpanel",
    helper_text:
      "Masked area in the image will be reimagined with new designs.",
    cta_text: "Generate design",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    mask_mode: false,
    mask_helper_text: "Mask the area you want to reimagine",
    heading_text: "Photo-realistic landscape makeovers for your exterior photos",
  },
  mask_screen: {
    component: "sam",
    helper_text: "Masked area in the image will be reimagined.",
  },
  results_screen: {
    solution_panel_name: "Landscaping",
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Generate new design",
    cta_helper_text: "",
    mask_mode: false,
    mask_helper_text: "Mask the area you want to reimagine",
    heading_text: "Photo-realistic landscape makeovers for your exterior photos",
  },
  view_results_screen: {
    generate_variations: true,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: [
      "wall",
      "floor",
      "ceiling",
      "fireplace",
      "windowpane",
      "stairway",
      "step",
      "stairs",
      "door",
      "railing;rail",
      "wardrobe",
      "refrigerator",
      "hood",
      "microwave",
      "countertop",
      "kitchen",
      "stove",
      "dishwasher",
      "sink",
      "oven",
      "curtain",
      "blind",
      "fan",
      "light",
      "chandelier",
    ],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "windowpane",
    ],
  },
  further_design_solutions: {
    "generic": {
      "generic": [
        "REMOVE_OBJECT",
        "LAWN_ENHANCEMENT",
        "SKY_ENHANCEMENT",
        "POOL_WATER_ENHANCEMENT",
        "CHANGE_PATHWAYS_WALKWAYS",
        "LANDSCAPING"
      ]
    }
  },
}