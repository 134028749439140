import { HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK } from "./constants";
// export const OCCUPIED_TO_VACANT = {
//   initial_screen: "dp_screen", //mask_screen/dp_screen
//   solution_name: "occupied-to-vacant",
//   solution_id: "OCCUPIED_TO_VACANT",
//   project_name: "New project",
//   space_options: [
//     { label: "Bedroom", value: "bedroom" },
//     {
//       label: "Living room/family room/lounge",
//       value: "living room/family room/lounge",
//     },
//     { label: "Kids room", value: "kids room" },
//     { label: "Nursery", value: "nursery" },
//     { label: "Kitchen", value: "kitchen" },
//     { label: "Bathroom/ensuite", value: "bathroom/ensuite" },
//     { label: "Dining", value: "dining" },
//     { label: "Foyer", value: "foyer" },
//     { label: "Games area/rumpus room", value: "games area/rumpus room" },
//     { label: "Hobby/craft room", value: "hobby/craft room" },
//     { label: "Bar", value: "bar" },
//     { label: "Laundry", value: "laundry" },
//     { label: "Media room", value: "media room" },
//     { label: "Pantry", value: "pantry" },
//     { label: "Single room studio/unit", value: "single room studio/unit" },
//     { label: "Study", value: "study" },
//     { label: "Sunroom", value: "sunroom" },
//   ],
//   space_type: "",
//   img: "",
//   masked_url: "",
//   mask_arr: [],
//   npy_url: "",
//   get_static_mask: false,
//   enable_mask_overlay: true,
//   pre_fetch_mask: true,
//   is_masking_enabled: false,
//   results: [],
//   side_panel_enabled: true,
//   side_panel_schema: {},
//   upload_screen: {
//     main_text: "Upload an image of furnished room",
//     helper_text:
//       "Drag and drop to upload or browse image PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
//     cta_text: "Upload image",
//     edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
//   },
//   dp_screen: {
//     component: "withoutpanel",
//     helper_text: "Masked objects in the image will be removed.",
//     cta_text: "Vacate the room",
//     edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
//     mask_mode: false,
//   },
//   mask_screen: {
//     component: "",
//   },
//   results_screen: {
//     component: "withoutpanel",
//     helper_text:
//       "Click on the generated images to view, share, download or modify.",
//     cta_text: "Try again",
//     cta_helper_text: "Didn't like the outputs?",
//   },
//   view_results_screen: {
//     generate_variations: false,
//     redesign_further: true,
//   },
//   generations_data: [],
//   generation_id: "",
//   project_id: "",
//   media_id: "",
//   auto_mask_exclusion_filter: {
//     interior: [
//       "wall",
//       "floor",
//       "ceiling",
//       "fireplace",
//       "windowpane",
//       "stairway",
//       "step",
//       "stairs",
//       "door",
//       "railing;rail",
//       "closet",
//       "refrigerator",
//       "hood",
//       "microwave",
//       "countertop",
//       "kitchen",
//       "stove",
//       "dishwasher",
//       "sink",
//       "oven",
//       "curtain",
//       "blind",
//       "fan", // for o2v
//       "light", // for o2v
//       "chandelier", //
//     ],
//     exterior: [
//       "building",
//       "canopy",
//       "column",
//       "door",
//       "escalator",
//       "fence",
//       "house",
//       "hovel",
//       "mountain",
//       "palm",
//       "pole",
//       "railing",
//       "road",
//       "sconce",
//       "sidewalk",
//       "sky",
//       "stairs",
//       "stairway",
//       "step",
//       "streetlight",
//       "swimming",
//       "tree",
//       "wall",
//       "water",
//       "windowpane",
//     ],
//   },
//   further_design_solutions: {
//     generic: {
//       generic: [
//         "VIRTUAL_STAGING_EMPTY_ROOM",
//         "SURFACE_CHANGE_WALL",
//         "SURFACE_CHANGE_CEILING",
//         "SURFACE_CHANGE_FLOOR",
//         "REMOVE_OBJECT",
//       ],
//       "bathroom/ensuite": [
//         "VIRTUAL_STAGING_EMPTY_ROOM",
//         "SURFACE_CHANGE_WALL",
//         "SURFACE_CHANGE_CEILING",
//         "SURFACE_CHANGE_FLOOR",
//         "REMOVE_OBJECT",
//         "BATHROOM_REMODELING",
//         "SURFACE_CHANGE_BATHROOM_CABINETS",
//         "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
//         "SURFACE_CHANGE_BATHROOM_WALL_TILE",
//       ],
//       kitchen: [
//         "VIRTUAL_STAGING_EMPTY_ROOM",
//         "SURFACE_CHANGE_WALL",
//         "SURFACE_CHANGE_CEILING",
//         "SURFACE_CHANGE_FLOOR",
//         "REMOVE_OBJECT",
//         "KITCHEN_REMODELING",
//         "SURFACE_CHANGE_KITCHEN_CABINETS",
//         "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
//         "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
//         "SURFACE_CHANGE_KITCHEN_APPLIANCES",
//       ],
//     },
//   },
// };

export const EMPTY_YOUR_SPACE = {
  initial_screen: "dp_screen", //mask_screen/dp_screen
  solution_name: "empty-your-space",
  solution_id: "EMPTY_YOUR_SPACE",
  project_name: "New project",
  popTitle:"Emptying the space",
  space_options: [
    { label: "Bedroom", value: "bedroom" },
    {
      label: "Living room/family room/lounge",
      value: "living room/family room/lounge",
    },
    { label: "Kids room", value: "kids room" },
    { label: "Nursery", value: "nursery" },
    { label: "Kitchen", value: "kitchen" },
    { label: "Bathroom/ensuite", value: "bathroom/ensuite" },
    { label: "Dining", value: "dining" },
    { label: "Foyer", value: "foyer" },
    { label: "Games area/rumpus room", value: "games area/rumpus room" },
    { label: "Hobby/craft room", value: "hobby/craft room" },
    { label: "Bar", value: "bar" },
    { label: "Laundry", value: "laundry" },
    { label: "Media room", value: "media room" },
    { label: "Pantry", value: "pantry" },
    { label: "Single room studio/unit", value: "single room studio/unit" },
    { label: "Study", value: "study" },
    { label: "Sunroom", value: "sunroom" },
  ],
  upload_space_type_options: {
    is_space: true,
    options: ['Empty', 'Furnished'],
    allowed_types: [true],
    error_msg: 'Not allowed',
    is_redirect: true,
    redirect_solution: 'VS_EMPTY_ROOM',
  },
  space_type: "",
  img: "",
  masked_url: "",
  mask_arr: [],
  npy_url: "",
  get_static_mask: false,
  enable_mask_overlay: false,
  solutionIcon:'designPanelIconO2V',
  pre_fetch_mask: true,
  is_masking_enabled: true,
  results: [],
  side_panel_enabled: true,
  side_panel_schema: {
    designing_for: {
      type: "dropdown",
      label: "Space Type",
      name: "designing for",
      path: "designing_for",
      value: "",
      temp_value: "",
      info_enabled: false,
      info_txt: "",
      options: [
        {
          label: "Living room/family room/lounge",
          value: "living room",
        },
        { label: "Bedroom", value: "bedroom" },
        { label: "Dining", value: "dining" },
        { label: "Kitchen", value: "kitchen" },
        { label: "Bathroom/ensuite", value: "bathroom" },
        { label: "Study", value: "study" },
        { label: "Outdoor Living", value: "outdoor living" },
      ],
      dependent_on: "",
      required: true,
      error_msg: "Please select designing for",
    },
  },
  upload_screen: {
    main_text: "Upload an image of furnished room",
    helper_text:
      "Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.",
    cta_text: "Upload image",
    edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  },
  dp_screen: {
    solution_panel_name: "Empty Your Space",
    component: "withpanel",
    helper_text: "Masked objects in the image will be removed.",
    cta_text: "Empty the space",
    edu_url: "https://www.youtube.com/embed/n5ZVluiDZ84?si=2FMNa3wfd85CTbeq",
    mask_mode: false,
    heading_text: "Remove all furniture and clutter in your room photos"
  },
  mask_screen: {
    component: "manual_mask",
  },
  results_screen: {
    solution_panel_name: "Empty Your Space",
    component: "withpanel",
    helper_text:
      "Click on the generated images to view, share, download or modify.",
    cta_text: "Try again",
    cta_helper_text: "Didn't like the outputs?",
    heading_text: "Remove all furniture and clutter in your room photos",
    mask_mode: false,
  },
  view_results_screen: {
    generate_variations: false,
    redesign_further: true,
  },
  generations_data: [],
  generation_id: "",
  project_id: "",
  media_id: "",
  auto_mask_exclusion_filter: {
    interior: [
      "wall",
      "floor",
      "ceiling",
      "fireplace",
      "windowpane",
      "stairway",
      "step",
      "stairs",
      "door",
      "railing;rail",
      "closet",
      "refrigerator",
      "hood",
      "microwave",
      "countertop",
      "kitchen",
      "stove",
      "dishwasher",
      "sink",
      "oven",
      "curtain",
      "blind",
      "fan", // for o2v
      "light", // for o2v
      "chandelier", //
    ],
    exterior: [
      "building",
      "canopy",
      "column",
      "door",
      "escalator",
      "fence",
      "house",
      "hovel",
      "mountain",
      "palm",
      "pole",
      "railing",
      "road",
      "sconce",
      "sidewalk",
      "sky",
      "stairs",
      "stairway",
      "step",
      "streetlight",
      "swimming",
      "tree",
      "wall",
      "water",
      "windowpane",
    ],
  },
  further_design_solutions: {
    generic: {
      generic: [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
      ],
      "bathroom/ensuite": [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
      ],
      kitchen: [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
      ],
    },
    empty: {
      generic: [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
      ],
      "bathroom/ensuite": [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
      ],
      kitchen: [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
      ],
    },
    furnished: {
      generic: [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
      ],
      "bathroom/ensuite": [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "BATHROOM_REMODELING",
        "SURFACE_CHANGE_BATHROOM_CABINETS",
        "SURFACE_CHANGE_BATHROOM_COUNTERTOP",
        "SURFACE_CHANGE_BATHROOM_WALL_TILE",
      ],
      kitchen: [
        "VIRTUAL_STAGING_EMPTY_ROOM",
        "SURFACE_CHANGE_WALL",
        "SURFACE_CHANGE_CEILING",
        "SURFACE_CHANGE_FLOOR",
        "REMOVE_OBJECT",
        "KITCHEN_REMODELING",
        "SURFACE_CHANGE_KITCHEN_CABINETS",
        "SURFACE_CHANGE_KITCHEN_COUNTERTOP",
        "SURFACE_CHANGE_KITCHEN_BACKSPLASH",
        "SURFACE_CHANGE_KITCHEN_APPLIANCES",
      ],
    },
  },
};
